import React from "react";
import { useSelector } from "react-redux";
import win from "../../assets/images/game/win-bg.png";
import { includeToFixed } from '../../utils/useFunc';

const SmallPlaceHolder = ({
  displayProduct,
  pagetype,
  joinUser,
  position,
  handleBattlePlayEvent,
}) => {
  //console.log('joinUser place holddee==>', joinUser)
  const result = useSelector((state) => state.user);
  const { userDetails } = result || {};
  const { _id: loginUserId } = userDetails || {};
  return (
    <>
      {pagetype === "battle" ? (
        <BattlePlaceHolder
          joinUsers={joinUser}
          positions={position}
          displayProducts={displayProduct}
          loginUserId={loginUserId}
          handleBattlePlayEvent={handleBattlePlayEvent}
        />
      ) : (
        <div className="small-placeholder">
          <img src={displayProduct} alt="" />
        </div>
      )}
    </>
  );
};
export default SmallPlaceHolder;
const BattlePlaceHolder = ({
  joinUsers,
  positions,
  displayProducts,
  loginUserId,
  handleBattlePlayEvent,
}) => {
//console.log('joinUserArray==>', joinUsers)
  const {
    joinUser: joinUserArray,
    createdBy,
    _id: battleId,
    players,
    status,
  } = joinUsers || {};
  const placeholderData = joinUserArray?.find(
    (el) => el.position === parseInt(positions)
  );
  //console.log('placeholderData=>', placeholderData)
  const { position, userId, winPrice, winStatus } = placeholderData || {};
  const { _id: findUserId } = userId || {};
  const ischeckJoin = joinUserArray?.findIndex(
    (el) => el?.userId?._id === loginUserId || el.playerstype  === 'bot'
  );
  //console.log('ischeckJoin=>', ischeckJoin)
  // console.log('placeholderData ================================>', {placeholderData, loginUserId,ischeckJoin, position, positions, findUserId,winPrice,winStatus,status});
  return (
    <>
      {status === "finshied" &&
      winStatus === "los" &&
      position === parseInt(positions) ? (
      
        <div className="small-placeholder lose-battle wow animate__animated animate__fadeInUp" data-wow-duration="0.5s"
        data-wow-delay="0.7s">
          <div className="lose-battle-image">
          {/* <img src={win} alt="win" /> */}
          <div className="battle-text">
            <h6>Lose !</h6>
          </div>
          </div>
          <p>{includeToFixed(winPrice)}</p>
        </div>
        // <div className="small-placeholder winner-battle wow animate__animated animate__fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">
        //   <div className="winner-battle-image">
        //   <img src={win} alt="win" />
        //   <div className="battle-text">
        //     <h6>Winner!</h6>
        //   </div>
        //   </div>
        //   <p>{winPrice?.toFixed(2)}</p>
        // </div>
      ) : status === "finshied" &&
        winStatus === "win" &&
        position === parseInt(positions) ? (
        // <div className="small-placeholder winner-battle">
        //   Winner!
        //   <p>{winPrice?.toFixed(2)}</p>
        // </div>
         <div className="small-placeholder winner-battle wow animate__animated animate__fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">
          <div className="winner-battle-image">
          <img src={win} alt="win" />
          <div className="battle-text">
            <h6>Winner!</h6>
          </div>
          </div>
          <p>{includeToFixed(winPrice)}</p>
        </div>
      ) : createdBy === loginUserId &&
        position === 0 &&
        players !== joinUserArray.length ? (
        <div className="small-placeholder cancle_btn">
          <img src={displayProducts} alt="" />
          <button
            className="cmn-btn btn-style"
            onClick={() =>
              handleBattlePlayEvent("cancle", loginUserId, battleId, positions)
            }
          >
          <span> Cancel Battle{" "}</span>
          </button>
        </div>
      ) : position === 0 ? (
        <div className="small-placeholder join_btn">
          <img src={displayProducts} alt="" />
          {/* <button  className='cmn-btn' onClick={()=>handleBattlePlayEvent('join', loginUserId,battleId,positions)}>Join</button> */}
        </div>
      ) : ischeckJoin !== -1 &&
        loginUserId &&
        position &&
        position !== 0 &&
        findUserId === loginUserId &&
        position === parseInt(positions) ? (
        <div className="small-placeholder quit_btn">
          <img src={displayProducts} alt="" />
          <button
            className="cmn-btn btn-style"
            onClick={() =>
              handleBattlePlayEvent("quit", loginUserId, battleId, positions)
            }
          >
            <span>Quit</span>
          </button>
        </div>
      ) : ischeckJoin === -1 && loginUserId && !findUserId ? (
        <div className="small-placeholder join_btn">
          <img src={displayProducts} alt="" />
          <button
            className="cmn-btn btn-style"
            onClick={() =>
              handleBattlePlayEvent("join", loginUserId, battleId, positions)
            }
          >
            <span>Join</span>
          </button>
        </div>
      ) : ischeckJoin === -1 && !loginUserId && !findUserId ? (
        <div className="small-placeholder login_btn">
          <img src={displayProducts} alt="" />
          <button
            className="cmn-btn btn-style"
            onClick={() =>
              handleBattlePlayEvent("login", loginUserId, battleId, positions)
            }
          >
            <span>Login</span>
          </button>
        </div>
      ) : (
        <div className="small-placeholder">
          <img src={displayProducts} alt="" />
        </div>
      )}
    </>
  );
};
