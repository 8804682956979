import React from "react";
import { useTranslation } from "react-i18next";
import "./battles.css";
import { includeToFixed } from "../../utils/useFunc";

const Players = ({ battleObj, handleActive, handleCreateBattle }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="player-tab">
        <div className="player">
          <span>{t("openBoxes.playersTxt")} ?</span>
        </div>
        <div className={`game-type wow animate__animated animate__slideInUp`}>
          <ul>
            <li className={`${battleObj.players === "2" ? `active` : ``}`}>
              <span onClick={(e) => handleActive("2")}>2</span>
            </li>
            <li className={`${battleObj.players === "3" ? `active` : ``}`}>
              <span onClick={(e) => handleActive("3")}>3</span>
            </li>
            <li className={`${battleObj.players === "4" ? `active` : ``}`}>
              <span onClick={(e) => handleActive("4")}>4</span>
            </li>
            <li className={`${battleObj.players === "5" ? `active` : ``}`}>
              <span onClick={(e) => handleActive("5")}>2 x 2</span>
            </li>
          </ul>
        </div>

        <div class="checkbox">
          <input type="checkbox" id="player" />
          <label class="checkbox-label inbox_label" for="player">
            {t("openBoxes.equalityModeTxt")}
          </label>
        </div>
        <div class="create_battle_btn">
          <button class="cmn-btn btn-style" onClick={handleCreateBattle}>
            <span>
              {t("openBoxes.createBattleBtn")} &nbsp;{" "}
              {includeToFixed(battleObj?.totalbattleCost)} DT
            </span>
          </button>
        </div>
      </div>
    </>
  );
};
export default Players;
