import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab /* Button */, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// import { toast } from 'react-toastify';
import { verifyGameResult } from "../../reducers/userReducer";
import { useTranslation } from "react-i18next";

//import { verifyGameResult } from '../../reducers/userReducer';
const FairnessGuaranteedPopUp = ({
  onCloseModal,
  fairnessOpen,
  setFairnessOpen,
  selected,
  /* handleConfirm */ addBoxes,
}) => {
  const { t } = useTranslation();

  //const dispatch = useDispatch();
  //const result = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // const { pastGames, gamesCount } = result || {};
  // console.log('Result is here---->', pastGames, gamesCount);
  // console.log('Selected data is here-->', selected);
  const [inputObject, setInputObject] = useState({
    nonce: "",
    clientSeed: "",
    serverSeed: "",
    betProductLength: "",
    gameId: "",
  });
  const [fairResult, setFairResult] = useState(0);
  const [showFair, setShowFair] = useState(false);
  const [verifyResult, setVerifyResult] = useState({});
  const [error, setError] = useState("");
  const handleChange = (e) => {
    //setShowFair(false);
    const { value, name } = e.target;
    setInputObject({ ...inputObject, [name]: value });
  };

  useEffect(() => {
    //	setShowFair(false);
    // console.log('selected',selected)
    const { nonce, clientSeed, serverSeed, betProductLength, _id } = selected;
    setInputObject({
      ...inputObject,
      nonce,
      clientSeed,
      serverSeed,
      betProductLength,
      gameId: _id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleVerify = async (e) => {
    e.preventDefault();
    // console.log('inputObject',inputObject)
    const dd = await dispatch(verifyGameResult(inputObject));
    // console.log('dd========>>>',dd)
    const { fairnessResult } = dd.payload;
    const { resultIndex, result, code } = fairnessResult.data;
    if (code === 200) {
      setFairResult(resultIndex);
      setShowFair(true);
      setVerifyResult(result?.result);
      setError("");
    } else {
      // toast.error(msg, {
      // 	toastId: 'verifyError',
      // });
      setVerifyResult({});
      setError("Result Not Found !!!");
    }
  };
  // console.log('selected input object is here-->', inputObject);

  return (
    <Modal
      show={true}
      size="xl"
      centered
      onHide={() => setFairnessOpen(false)}
      className={`modal fade login-popup`}
      id="loginModal"
      tabindex="1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-content modal_contents boxadd-model-page fairness-info-popup">
        <div className="modal-body">
          <div className="modal_header">
            <div className="case_add">
              <h4>{t("form.openBoxesfairnessTxt")}</h4>
            </div>
            <div className="close_pup_btn">
              <button
                onClick={() => setFairnessOpen(false)}
                type="button"
                className="close-btn"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i class="las la-times"></i>
              </button>
            </div>
          </div>
          <div className="account-form-area">
            <div className="form-group text-center">
              <div
                className="row wow fadeInUp"
                data-wow-duration="0.5s"
                data-wow-delay="0.3s"
              ></div>
            </div>

            <Tabs defaultActiveKey="verify" id="uncontrolled-tab-example">
              <Tab eventKey="howitworks" title="How it works">
                <div className="form-group text-center">
                  <div
                    className="row wow fadeInUp"
                    data-wow-duration="0.5s"
                    data-wow-delay="0.3s"
                  >
                    <div className="col-lg-12">
                      <h1 className="section-title">
                        {/* <span className="section-title-arrow-left"></span> */}
                        <span className="section-title-text">
                          {t("header.howitWorks")}
                        </span>
                        {/* <span className="section-title-arrow-right"></span> */}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <h3 className="text-danger">{t("form.proFairTxt")}</h3>
                  <br />
                  <div className="fairmodalbady">
                    We use a new&nbsp;
                    <Link target="_blank">Random.org</Link>
                    &nbsp;system - tickets. Tickets allow us to prove to the
                    user that for each battle we received only one random string
                    (beacon) and only after the battle began. Further this
                    random string (beacon) will be used to generate rolls. It
                    guarantees that BOXI never knows battle rolls before the
                    game start.
                    <br />
                    <br />
                    {/* <b>Random.org</b>&nbsp;- offers true random numbers to
										anyone on the Internet. The randomness comes from
										atmospheric noise, which for many purposes is better than
										the pseudo-random number algorithms typically used in
										computer programs.
										<br /> */}
                    <b>GAME OUTCOME</b>
                    <br /> There are three variables that will ultimately
                    determine which item you receive in your box. And while we
                    do have a very sophisticated algorithm we work with, the
                    easiest way to think about it is like the old school hand
                    game of ‘Rock, Paper, Scissors’ - but with a few more
                    possible outcomes.
                    <br />
                    <br />
                    Firstly, there’s Your Hand. This is the first code
                    generated. What makes it your hand? Well, you can change it
                    if you like. You can even check out other users’ hands to
                    see what their outcome was. It’s totally up to you, and you
                    can change Your Hand at any point.
                    <br />
                    <br />
                    Next, there’s Our Hand. This is a secret code that won’t be
                    revealed until you choose to do so. Finally, there’s the
                    Play Count. This is the number of games you’ve played with
                    us, and is the simplest - it starts at one, and goes up by
                    one with each game you play.
                    <br />
                    <br />
                    <h4>
                      <b>Generation</b>
                    </h4>
                    Each item you could potentially unbox is assigned a roll
                    number - and there are 100,000,000 potential roll numbers to
                    land on. Each roll is determined by a combination of three
                    individual elements:
                    <br />
                    <br />
                    {/* <b>Client seeds</b>&nbsp;- users client seeds concatenated
										with a comma. Used for roll hash.
										<br /> */}
                    <b>Client seeds</b>&nbsp;- This is a passphrase that comes
                    from you and your browser. You’ll be able to see it before
                    you unbox an item, and you can even change it before you
                    play, if you feel like that’s going to help you.. You can
                    also check other players’ seeds to see their outcomes.
                    <br />
                    <br />
                    <b>Server Seed</b>&nbsp;- This is a really, really long
                    number that comes from us. Before you play, we’ll show you
                    an encrypted version, or hash, so that we can guarantee that
                    it’s a predetermined result without actually allowing you to
                    work out the outcome beforehand. It’s about keeping it fair
                    for everyone, right?
                    <br />
                    Once you unbox an item, you’ll reveal the unhashed Server
                    Seed, which you can then check against past games to verify
                    the outcomes using that seed.
                    <br />
                    <br />
                    <b>Ticket ID</b>&nbsp;- an identification for each beacon
                    (random string) generated by Random.org. You can use Ticket
                    ID to verify beacon value and timestamp when this beacon has
                    been generated. Every battle has its own unique Ticket ID.
                    Ticket ID can be used only once.
                    <br />
                    <br />
                    <b>Play Count</b>&nbsp;- This one’s pretty simple - it’s the
                    number of games you’ve played. This number means that even
                    if you had the same Client Seed and Server Seed from a
                    previous game, the outcome in your next game would still be
                    different, since the Play Count would have increased by one.
                    <br />
                    <br />
                    <b>Beacon</b>&nbsp;- a random string generated by
                    Random.org. Beacon is generated right before the first
                    battle round, when the game started and no one can change
                    his client seed.
                    <br />
                    <b>Nonce</b>&nbsp;- a natural number, increments on each
                    roll generation.
                    <br />
                    <b>Roll Hash</b>&nbsp;- a random string generated using
                    client seeds, nonce and a beacon.
                    <br />
                    <br />
                    <div className="fairness-code">
                      hash_hmac(‘sha512’, $clientSeeds.‘-’.$nonce, $beacon);
                    </div>
                    <br />
                    <b>Roll</b>&nbsp;- a roll hash converted into the roll
                    number
                    <br />
                    <div className="fairness-code">
                      $hex = substr($hash, 0, 7);
                      <br />
                      $number = hexdec($hex);
                      <br />
                      $roll = $number % 100000 + 1;
                    </div>
                    <br />
                    <h4>
                      <b>Probability</b>
                    </h4>
                    <br />
                    Since each item, or outcome, is assigned to a roll number,
                    the probability of a particular outcome will never change -
                    even if you played 100,000,000 times. There’s no pattern or
                    method used to determine when the big ticket items will be
                    won - it’s sheer randomness.
                    <br />
                    To see the probability of each individual item within a box,
                    click ‘Toggle Rates’ at the top to see the chance displayed
                    as a percentage.
                  </div>
                </div>
                {/* <div className="text-center">
									<div className="col-lg-12">
										<h1 className="section-title">
											<span className="section-title-arrow-left"></span>
											<span className="section-title-text">VERIFY</span>
											<span className="section-title-arrow-right"></span>
										</h1>
									</div>
								</div>
								<div className="fairmodalbody-verify">
									<ul className="varify-modal">
										<li>
											<div className="dd-list__item-title">
												<span className="fair-title-arrow-left"></span>
												<span className="dd-list__item-text">
													Open the battle you want to join
												</span>
											</div>
										</li>
										<li>
											<div className="dd-list__item-title">
												<span className="fair-title-arrow-left"></span>
												<span className="dd-list__item-text">
													Click on Verify Ticket or open&nbsp;
													<a
														href="/"
														target="_blank"
														rel="nofollow noopener noreferrer"
													>
														https://api.random.org/tickets/form
													</a>
													&nbsp;and enter Ticket ID. You should get message “—
													The randomization will be available after the ticket
													has been used —” That means that ticket ID has not
													been initiated yet.
												</span>
											</div>
										</li>
										<li>
											<div className="dd-list__item-title">
												<span className="fair-title-arrow-left"></span>
												<span className="dd-list__item-text">
													Check all the client seeds. A user can change his
													client seed for the battle only before he joins it.
												</span>
											</div>
										</li>
										<li>
											<div className="dd-list__item-title">
												<span className="fair-title-arrow-left"></span>
												<span className="dd-list__item-text">
													Join the battle or wait for other users to join your
													battle.
												</span>
											</div>
										</li>
										<li>
											<div className="dd-list__item-title">
												<span className="fair-title-arrow-left"></span>
												<span className="dd-list__item-text">
													When the battle started, check start timestamp.
												</span>
											</div>
										</li>
										<li>
											<div className="dd-list__item-title">
												<span className="fair-title-arrow-left"></span>
												<span className="dd-list__item-text">
													Wait until the battle has finished.
												</span>
											</div>
										</li>
										<li>
											<div className="dd-list__item-title">
												<span className="fair-title-arrow-left"></span>
												<span className="dd-list__item-text">
													Open Fairness modal
												</span>
											</div>
										</li>
										<li>
											<div className="dd-list__item-title">
												<span className="fair-title-arrow-left"></span>
												<span className="dd-list__item-text">
													Click "Verify Ticket" or open&nbsp;
													<a href="/" target="_blank">
														https://api.random.org/tickets/form
													</a>
													&nbsp;and enter Ticket ID
													<div>
														<a href="/images/78ee27ab4be8160f3ad9c10ab090aa41.jpg" target="_blank" rel="nofollow noopener noreferrer">
                                    <img src="/images/1f059eb059348f31390f1c20c278d4d4.jpg" class="_2QAey1RvRNXIfcD_cSrx3o" alt="Randomorg verifier">
                                </a>
														<ul>
															<li>
																<div className="dd-list__item-title">
																	<span className="fair-title-arrow-left"></span>
																	<span className="dd-list__item-text">
																		a. Ticket ID should match.
																	</span>
																</div>
															</li>
															<li>
																<div className="dd-list__item-title">
																	<span className="fair-title-arrow-left"></span>
																	<span className="dd-list__item-text">
																		b. "Used" timestamp should be later than
																		battle start timestamp.
																	</span>
																</div>
															</li>
															<li>
																<div className="dd-list__item-title">
																	<span className="fair-title-arrow-left"></span>
																	<span>
																		c. To check the beacon (ticket output) click
																		"View Full Results" button.
																	</span>
																</div>
															</li>
														</ul>
													</div>
													<div>
														<a href="/images/ae547d209dca785cea6fea00c78f0a9b.jpg" target="_blank" rel="nofollow noopener noreferrer">
                                    <img src="/images/34971cb70cd06af2b78f391ff3d2ef19.jpg" class="_2QAey1RvRNXIfcD_cSrx3o" alt="Randomorg verifier">
                                </a>
														<ul>
															<li>
																<div className="dd-list__item-title">
																	<span class="fair-title-arrow-left"></span>
																	<span>
																		d. The value in the "Random Values
																		Generated" field should be equal the beacon.
																	</span>
																</div>
															</li>
														</ul>
													</div>
												</span>
											</div>
										</li>
										<li>
											<div className="dd-list__item-title">
												<span className="fair-title-arrow-left"></span>
												<span className="dd-list__item-text">
													Keep in mind that tickets expire in 1 month, which
													means that ticket validation is available within one
													month.
												</span>
											</div>
										</li>
										<li>
											<div className="dd-list__item-title">
												<span className="fair-title-arrow-left"></span>
												<span className="dd-list__item-text">
													Now you can verify battle rolls
												</span>
											</div>
										</li>
									</ul>
									To verify battle rolls:
									<ul className="varify-modal">
										<li>
											<div className="dd-list__item-title">
												<span className="fair-title-arrow-left"></span>
												<span className="dd-list__item-text">
													Click the “Fairness” button on the battle page
												</span>
											</div>
										</li>
										<li>
											<div className="dd-list__item-title">
												<span className="fair-title-arrow-left"></span>
												<span className="dd-list__item-text">
													Check nonce for each drop
												</span>
											</div>
										</li>
										<li>
											<div className="dd-list__item-title">
												<span className="fair-title-arrow-left"></span>
												<span className="dd-list__item-text">
													Go to Verify tab. You can change nonce and check roll
													number
												</span>
											</div>
										</li>
										<li>
											<div className="dd-list__item-title">
												<span className="fair-title-arrow-left"></span>
												<span className="dd-list__item-text">
													You can also use this&nbsp;
													<a href="/" target="_blank">
														code snippet
													</a>
													&nbsp;to check roll number by yourself.
												</span>
											</div>
										</li>
									</ul>
								</div> */}
              </Tab>

              <Tab eventKey="info" title="Info">
                <table className="table_info table__info">
                  <thead className="table_head">
                    <tr>
                      <th className="table_row">{t("openBoxes.typeTxt")}</th>
                      <th className="table_row">{t("form.valueTxt")}</th>
                    </tr>
                  </thead>
                  <tbody className="table_body table__body">
                    {/* <tr className="info_row">
                      <td className="table_data">Ticket ID</td>
                      <td className="table_data">
                       {selected?._id}
                        <span>
                          &nbsp;[
                          <a
                            href="#0"
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                            title="Click to verify Ticket"
                          >
                            Verify Ticket
                          </a>
                          ]
                        </span>
                      </td>
                    </tr> */}
                    <tr className="info_row">
                      <td className="table_data">{t("form.seedTxt1")}</td>
                      <td className="table_data">{selected?.serverSeed}</td>
                    </tr>
                    {/* <tr className="info_row">
                      <td className="table_data">Started at</td>
                      <td className="table_data">Lorem, ipsum dolor.</td>
                    </tr> */}
                    <tr className="info_row">
                      <td className="table_data">{t("form.seedTxt2")}</td>
                      <td className="table_data"> {selected?.clientSeed}</td>
                    </tr>
                    <tr className="info_row">
                      <td className="table_data">Nonce</td>
                      <td className="table_data">{selected?.nonce}</td>
                    </tr>
                  </tbody>
                </table>
              </Tab>

              <Tab eventKey="verify" title="Verify">
                <div className="verify-form">
                  <ul className="user-info-card__list">
                    {/* ******* fairness-formula *****
                    <li className="fairness-formula">
                      <span>{t("form.seedTxt3")}</span>
                      <pre>
                        {`
													const betProductLength = [];
													items.forEach((el) => {
														for (let i = 0.0; i < el.chance; i = i + 0.001) {
															betProductLength.push(el);
														}
													});
													const getResult = async (serverseed, clientseed,nonce, betProductLength) => {
                             const fullSeed = crypto
                              .createHash("sha256")
                                 .update({serverseed}:{clientseed}:{nounce})
                                  .digest("hex");

                             const seed = fullSeed.substr(0, 8);

                             return parseInt(seed, 16) % betProductLength;
                    }`}
                        ;
                      </pre>
                    </li> */}
                    <li>
                      <span className="info_label">{t("form.seedTxt2")}</span>
                      <span>
                        <input
                          type="text"
                          value={inputObject.clientSeed}
                          name="clientSeed"
                          onChange={(e) => handleChange(e)}
                        />
                      </span>
                    </li>
                    <li>
                      <span className="info_label">{t("form.seedTxt1")}</span>
                      <span>
                        <input
                          type="text"
                          value={inputObject.serverSeed}
                          name="serverSeed"
                          onChange={(e) => handleChange(e)}
                        />
                      </span>
                    </li>
                    <li>
                      <span className="info_label">NONCE</span>
                      <span>
                        <input
                          type="number"
                          value={inputObject.nonce}
                          name="nonce"
                          onChange={(e) => handleChange(e)}
                        />
                      </span>
                    </li>
                    {/* {showFair ? (
											<li>
												<span className="info_label">Result</span>
												<span>
													<input
														type="number"
														value={fairResult}
														name="nonce"
														disabled
														// onChange={(e) => handleChange(e)}
													/>
												</span>
											</li>
										) : (
											''
										)} */}
                  </ul>
                  <div className="fairnessBtn">
                    <Button
                      className="profile-update btn-style"
                      onClick={handleVerify}
                    >
                      <span>{t("form.verifyTxt")}</span>
                    </Button>
                  </div>

                  {showFair && verifyResult?.pid && (
                    <div className="order-details-content">
                      <div className="left-section">
                        <img
                          src={verifyResult?.pid?.bannerImage}
                          alt="orderimage"
                        />
                      </div>
                      <div className="right-section">
                        {/* <p>Order No. : <span>#{orderDetails?.orderInfo?.orderid}</span></p> */}
                        <h6>
                          {t("form.winTxt")}:<span>{fairResult}</span>
                        </h6>
                        <h6>
                          {t("proShipDetails.placeTxt1")}:
                          <span>{verifyResult?.pid?.name}</span>
                        </h6>
                        {/* <p>{verifyResult?.productId?.description}</p> */}
                        <h6>
                          {t("home.boxPriceTitle")}:{" "}
                          <span>{verifyResult?.pid?.price} Dt</span>
                        </h6>
                        <h6>
                          {t("form.outRangeTxt")}:{" "}
                          <span>{verifyResult?.range}</span>
                        </h6>
                        {/* <div class="quantity">
                <h6>Quantity: <span>{verifyResult?.quantity}</span></h6>
            </div> */}
                        {/* <h6>Shipped status : <span>{el?.status|| 'pending'}</span></h6> */}
                      </div>
                    </div>
                  )}

                  {error && <div className="no-result">{error}</div>}
                  {/* <div className="roll-text">
										<p>PLEASE FILL IN ALL FIELDS TO SEE THE ROLL</p>
										<p>
											ROLL FOR NONCE{' '}
											<span className="rand-text">{inputObject.nonce}</span> IS{' '}
											<span className="value">0000</span>
										</p>
									</div> */}
                </div>
              </Tab>

              <Tab eventKey="oddsrange" title="Odds Range">
                <table className="table_info table__info odds_range">
                  <thead className="table_head">
                    <tr>
                      <th className="table_row">{t("boxDetails.sellTxt2")}</th>
                      <th className="table_row">{t("form.rangeTxt")}</th>
                      <th className="table_row">{t("form.oddTxt")}</th>
                    </tr>
                  </thead>
                  <tbody className="table_body table__body">
                    {selected?.betboxItems.map((el) => {
                      return (
                        <tr className="info_row">
                          <td className="table_data">{el?.pid?.name}</td>
                          <td className="table_data">{el?.range}</td>
                          <td className="table_data">{el?.chance} %</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default FairnessGuaranteedPopUp;
