import React, { useEffect, useState } from "react";
// import { Table } from "react-bootstrap";
// import BoxImg from "../../assets/images/home/download.png";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import {
  getInventoryProducts,
  getInventoryCounts,
  inventoryPlaceOrder,
  updateInventory,
  // getOrders,
  removeInventoryItems,
  sellUpdateWallet,
  getAllInventoryProducts,
  getDistinctInventoryProducts,
  getAllShippingData,
} from "../../reducers/userReducer";
import InventoryPopUp from "../PopUp/InventoryPopup";
import { toast } from "react-toastify";
import InventoryFilterComp from "./inventoryFilter";
// import { queryString } from './../../utils/queryString';
// import { setSelectionRange } from '@testing-library/user-event/dist/utils';
import ConfirmationPopUp from "../PopUp/ConfirmationPopUp";
import { Form } from "react-bootstrap";
import { socket } from "../../config/socket";
// import Loader from "./../Loader/loader";
import TabbleLoader from "../Loader/tableloader";
import AddShippingPopup from "../PopUp/addShippingpopup";
import { t } from "i18next";

const Inventory = () => {
  const serchInitialObj = {
    title: "",
    rarity: "",
    skip: 0,
    limit: 6,
    sort: { createdAt: -1 },
    sortType: t("openBoxes.sortTxt1"),
  };

  const [searchPayload, setSeachPayload] = useState(serchInitialObj);
  const [selectedShipping, setSelectedShipping] = useState("");

  const dispatch = useDispatch();
  const result = useSelector((state) => state.user);
  const {
    userInventory,
    inventoryTotal,
    userDetails,
    // allOrders,
    totalInventoryCounts,
    totalInventoryCost,
    adminSetting,
    allInventoryItems,
    allShippingMethods,
    loading,
  } = result || {};
  // console.log(
  //   "adminSetting",
  //   adminSetting,
  //   allInventoryItems,
  //   allShippingMethods
  // );
  // console.log(
  //   "inventoryProducts",
  //   userInventory,
  //   inventoryTotal,
  //   totalInventoryCounts,
  //   totalInventoryCost
  // );
  // const [skip, setSkip] = useState(0);
  const [hide, setHide] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [payload, setPayload] = useState([]);
  const [selected, setSelected] = useState([]);
  // const [multi, setMulti] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [sellPayload, setSellPayload] = useState([]);
  const [accept, setAccept] = useState(false);
  const [show, setShow] = useState(false);
  const [isSelll, setisSelll] = useState(false);

  const { quickSellPercentage } = adminSetting || {};

  // console.log("allOrders", allOrders);

  useEffect(() => {
    dispatch(getInventoryCounts());
    dispatch(getAllShippingData());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inventoryTotal) {
      const newValue = searchPayload.skip + 9;
      // console.log("============>>>", newValue);
      if (newValue >= inventoryTotal) {
        setHide(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryTotal]);

  useEffect(() => {
    if (allShippingMethods?.length > 0) {
      setSelectedShipping(allShippingMethods[0]?.shippingName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allShippingMethods]);

  const handleLoadMore = () => {
    const copy = { ...searchPayload };

    const newValue = copy.skip + 9 + 9;
    // console.log("skip", newValue);
    if (newValue >= inventoryTotal) {
      setHide(true);
    }
    copy.skip += 9;
    copy.newType = "loadMore";

    dispatch(getInventoryProducts(copy));
    setSeachPayload(copy);
    // dispatch(getTransactionDetails())
  };

  const handleRequestToShip = (data, invId) => {
    setOpen(true);
    const payload = {
      totalAmount: data?.price,
      subTotal: data?.price,
      inventoryIds: [invId],
      productDetails: [
        {
          productId: data?._id,
          price: data?.price,
          quantity: 1,
        },
      ],
    };
    setPayload(payload);
    if (userDetails?.shippingAddress.length) {
      const addValue = userDetails?.shippingAddress[0];
      setSelected(addValue);
    }
    // dispatch(placeOrder(payload));
    if (userDetails?.shippingAddress.length === 0) {
      setShow(true);
      setOpen(false);
      return toast.error(t("toast.inventryerror"), {
        toastId: "addressFirst",
      });
    }
  };

  const handleMultiItemsRequest = () => {
    if (selected.length === 0) {
      return toast.error(t("toast.itemerror"));
    }
    const productDetails = [];
    const inventoryIds = [];
    const totalAmount = selected.reduce((pre, next) => {
      const res = (pre || 0) + parseFloat(next.price);
      const obj = {
        productId: next?.productId?._id,
        price: next?.price,
        quantity: 1,
      };
      inventoryIds.push(next?._id);
      productDetails.push(obj);
      return res;
    }, 0);

    const finalPayload = {
      totalAmount,
      inventoryIds,
      subTotal: totalAmount,
      productDetails,
    };
    setPayload(finalPayload);
    if (userDetails?.shippingAddress.length === 0) {
      setShow(true);
      return toast.error(t("toast.addresserror"), {
        toastId: "addressFirst",
      });
    }
    setOpen(true);
  };

  //popup close
  const onCloseModal = () => {
    setOpen(false);
  };

  const handleAddressChange = () => {
    const findAddress = userDetails?.shippingAddress
      ? userDetails?.shippingAddress[0]
      : {};
    setSelectedAddress(findAddress);
  };

  useEffect(() => {
    handleAddressChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const handleConfirm = async () => {
    const copy = { ...payload };
   // console.log("paylaod", payload);
    copy.shippingAddress = selectedAddress;
    copy.shippingMethod = selectedShipping;
    //  copy.inventoryIds = inventoryIds

    // payload.forEach((el) => {
    //   el.shippingAddress = selectedAddress;
    //   inventoryIds.push(el.inventoryId);
    //   if(selectedShipping){
    //   el.shippingMethod = selectedShipping
    //   }
    // });

    // console.log("selectedShippoin", selectedAddress?.address);

    // const newPayload={...payload,shippingAddress:selectedAddress}
    // console.log("newPaylaod", inventoryIds, payload);

    // console.log('inventporyId', newPayload?.inventoryId)
    if (!selectedAddress?.uid) {
      return toast.error(t("toast.selectadd"), {toastId: 'testId1'});
    }
    if (!selectedShipping) {
      return toast.error(t("toast.shippingmethod"), {toastId: 'testId2'});
    }

    if (!accept) {
      return toast.error(t("toast.termcondition") , {toastId: 'testId3'});
    }

    // if(parseFloat(copy?.totalAmount)<10){
    //   return toast.error('Users cant request to ship products less than 10 DT value');
    // }

    // console.log("superFinalPayload", copy);
    dispatch(updateInventory({ inventoryIds: copy?.inventoryIds }));
    const res = await dispatch(inventoryPlaceOrder(copy));
    const { code } = res?.payload?.resultData?.data;
    if (code === 200) {
      toast.success(t("toast.ordersuccess"), {
        toastId: "inventoryPlaceOrder",
      });
      dispatch(getInventoryProducts(searchPayload));
      dispatch(getAllInventoryProducts(searchPayload));
      dispatch(getDistinctInventoryProducts());
    }
    setOpen(false);
    setSelected([]);
    setCheck(false);
    setSelectedIds([]);
  };

  const handleChange = (data) => {
    const parentCopy = JSON.parse(JSON.stringify(data));
    const copy = selected.slice();
    const another = selectedIds.slice();
    const fIndex = copy.findIndex((el) => el._id === parentCopy?._id);
    if (fIndex >= 0) {
      copy.splice(fIndex, 1);
      another.splice(fIndex, 1);
    } else {
      copy.push(parentCopy);
      another.push(parentCopy._id);
    }
    copy.forEach((ele) => {
      const price = ele?.price ? ele?.price : ele.productId?.price;
      // console.log("price0000000", price);

      const earnMoney =
        ele?.productId?.quickSell && ele?.productId?.quickSellPercentage
          ? (
              price -
              (price * parseFloat(ele?.productId?.quickSellPercentage)) / 100
            ).toFixed(2)
          : price.toFixed(2);
      ele.earnMoney = earnMoney;
    });
    setSelected(copy);
    setSelectedIds(another);
  };

  // const handleMultiSelect = () => {
  //   setMulti(!multi);
  // };
  // const calculateSelectedCost = () => {
  //   let res = 0;
  //   if (selected?.length > 0) {
  //     res = selected.reduce((pre, next) => pre + next?.price, 0);
  //   }
  //   return res;
  // };

  const calculateSellSelectedCost = () => {
    let res = 0;
    if (selected?.length > 0) {
      res = selected.reduce(
        (pre, next) => pre + parseFloat(next?.earnMoney || 0),
        0
      );
    }
    return res.toFixed(2);
  };

  // const calculateAllItemsCost = () => {
  //   let res = 0;
  //   if (selected?.length > 0) {
  //     res = selected.reduce(
  //       (pre, next) => pre + parseFloat(next?.price || next?.productId?.price || 0),
  //       0
  //     );
  //   }
  //   return res.toFixed(2);
  // };

  const handleSell = (type, data, invId) => {
    // console.log("type", type, data, invId);
    if (selected.length === 0 && type === "multi") {
      return toast.error(t("toast.selectitem"), {
        toastId: "select",
      });
    }
    let gameId = [];

    if (type === "multi") {
      const amount = selected.reduce(
        (pre, next) =>{ 
          //push the gameId in gameId array
          gameId.push(next?.gameId)
          return(pre + parseFloat(next?.earnMoney)) } ,
        0
      );

      const payload = {
        type: "deposit",
        debitCreditType: "credit",
        paymentType: "wallet",
        description: "Sell Item",
        descriptionMessage: "Add amount in the Wallet",
        inventoryId: selectedIds,
        amount: amount,
        gameId,
      };

      const allAmounts = selected.map((el) => {
        const earnMoney = parseFloat(el?.earnMoney);
        return earnMoney;
      });

      payload.allAmounts = allAmounts;
      setSellPayload(payload);
      // console.log("payload", payload);
    } else {
      gameId.push(data?.gameId)
      const payload = {
        amount: singleSellPrice(data, "heloo"),
        inventoryId: [invId],
        allAmounts: [singleSellPrice(data, "heloo")],
        type: "deposit",
        debitCreditType: "credit",
        paymentType: "wallet",
        description: "Sell Item",
        descriptionMessage: "Add amount in the Wallet",
        gameId,
      };

      // console.log("payload==>>>", payload);

      setSellPayload(payload);
    }
    setOpenConfirm(true);
    setisSelll(false);
  };

  const onCloseConfirmModal = () => {
    setOpenConfirm(false);
    setisSelll(false);
  };

  const handleConfirmPop = async () => {
    setisSelll(true);
    dispatch(removeInventoryItems(sellPayload));
    const res = await dispatch(sellUpdateWallet(sellPayload));
    const { code } = res?.payload?.resultData?.data;
    if (code === 200) {
      dispatch(getInventoryProducts(searchPayload));
      dispatch(getAllInventoryProducts(searchPayload));
      dispatch(getDistinctInventoryProducts());
    }
    setOpenConfirm(false);
    setSelected([]);
    setCheck(false);
    // setMulti(false);
    setSelectedIds([]);
  };

  const singleSellPrice = (prod, type) => {
    if (type) {
      // console.log("prodd==>>", prod);
    }

    const price = prod?.price ? prod?.price : prod?.productId?.price;
    const earnMoney =
      prod?.productId?.quickSell && prod?.productId?.quickSellPercentage
        ? price -
          (price * parseFloat(prod?.productId?.quickSellPercentage)) / 100
        : price;
    // prod?.price - (prod?.price * parseFloat(quickSellPercentage)) / 100;
    return earnMoney?.toString()?.includes(".")
      ? earnMoney?.toFixed(2)
      : earnMoney;
  };

  // const handleCheck = (id) =>{
  //   let valid = false;
  // const fIndex =  selected.findIndex((ele)=>ele._id===id);
  //   if(fIndex>=0){
  //     valid = true
  //   }
  //   return valid;
  // }

  const [check, setCheck] = useState(false);

  const handleSelectAll = () => {
    const copy = JSON.parse(JSON.stringify(allInventoryItems));
    const arr = [];
    copy.forEach((ele) => {
      arr.push(ele._id);
      const price = ele?.price ? ele?.price : ele.productId?.price;
      const earnMoney =
        ele?.productId?.quickSell && ele?.productId?.quickSellPercentage
          ? (price - (price * parseFloat(quickSellPercentage)) / 100).toFixed(2)
          : price.toFixed(2);
      ele.earnMoney = earnMoney;
    });
    setCheck(!check);
    if (!check) {
      setSelectedIds(arr);
      setSelected(copy);
    } else {
      setSelectedIds([]);
      setSelected([]);
    }
  };

  // console.log("selectedFiels", selected, selectedIds);

  const handleSelectShippingMethod = (data) => {
    // console.log("data", data);

    const { value } = data.target;
    // console.log("value", value);
    setSelectedShipping(value);
  };

  const handleAccept = () => {
    // console.log("fddddddddd")
    setAccept(!accept);
  };

  useEffect(() => {
    socket.on("updateRealInventory", (data) => {
      // console.log('heliioioioioiioioi==>>',data)
      dispatch(getInventoryProducts(searchPayload));
      dispatch(getAllInventoryProducts(searchPayload));
      dispatch(getDistinctInventoryProducts());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <div className="inventory-section">
        <div className="all-transaction inventory__page">
          <div className="all-transaction__header">
            <h3 className="title">{t("profile.inventoryTxt")}</h3>
          </div>

          <InventoryFilterComp
            setSeachPayload={setSeachPayload}
            searchPayload={searchPayload}
          />
          {userInventory?.length > 0 && (
            <div className="select-all">
              <Form.Group className="" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label={t("boxDetails.selectAllTxt")}
                  onClick={handleSelectAll}
                  checked={check}
                />
              </Form.Group>
            </div>
          )}



          <div className="top-inventory-list">
          
            <p>
              {t("profile.countTxt1")} :{" "}
              <span>{totalInventoryCounts || 0}</span>{" "}
            </p>
            <p>
              {t("profile.countTxt2")} :{" "}
              <span>{totalInventoryCost || 0} DT</span>{" "}
            </p>
            {/* </>} */}
            <button onClick={() => handleSell("multi")} className="btn-style">
              <span>
                {" "}
                {selected?.length > 0
                  ? `${t("boxDetails.sellTxt1")} ${selected.length} ${
                      selected.length === 1
                        ? t("boxDetails.sellTxt2")
                        : t("boxDetails.sellTxt3")
                    } ${t("boxDetails.sellTxt5")} ${
                      calculateSellSelectedCost() || 0
                    } DT`
                  : `${t("boxDetails.sellTxt1")} ${t("boxDetails.sellTxt3")}`}
              </span>
            </button>
            <button onClick={handleMultiItemsRequest} className="btn-style">
              <span>
                {" "}
                {selected?.length > 0
                  ? `${t("boxDetails.sellTxt4")} ${selected.length} ${
                      selected.length === 1
                        ? t("boxDetails.sellTxt2")
                        : t("boxDetails.sellTxt3")
                    }`
                  : `${t("boxDetails.sellTxt4")} ${t("boxDetails.sellTxt3")}`}
              </span>
            </button>
          </div>
          {loading ? (
            <TabbleLoader />
          ) : (
            <>
              <div className="inventory__card">
                <div className="container">
                  <div className="row">
                    {userInventory?.length > 0 ? (
                      userInventory?.map((el, i) => {
                       

                        return (
                          <>
                            <div className="col-md-4 inv_card">
                              <div
                                // style={{background: `linear-gradient(rgb(8, 43, 61) 35%, ${el.productId.rarityLevel.color} 100%)`}}
                                style={{
                                  background: `linear-gradient(180deg, #041f2d 50%, ${el.productId.rarityLevel.color}40 100%)`,
                                }}
                                className={`battle-card ${
                                  selectedIds?.includes(el._id)
                                    ? "selected-inventroy"
                                    : ""
                                }`}
                              >
                                <label
                                  for={`radio-card-1${i}`}
                                  class="radio-card"
                                >
                                  {/* {el.status === "pending" && ( */}
                                  <input
                                    type="checkbox"
                                    name="radio-card"
                                    checked={selectedIds?.includes(el._id)}
                                    id={`radio-card-1${i}`}
                                    onChange={() => handleChange(el)}
                                  />
                                  {/* )} */}
                                  <div className="contest-card__thumb">
                                    <img
                                      src={el?.productId?.bannerImage}
                                      alt="inventory-img"
                                    />
                                  </div>
                                  <div className="inventory_content">
                                    <p className="brand_name">
                                      {el?.productId?.name}
                                    </p>
                                    <p className="pro_name">
                                      {el?.productId?.brandId?.name}
                                    </p>

                                   
                                  </div>
                                  <div
                                    className={`inv_btn ${
                                      el.status === "requested"
                                        ? "inv-no-sell"
                                        : ""
                                    }`}
                                  >
                                    <button
                                      className={`cmn-btn btn-style ${
                                        selectedIds?.length > 0
                                          ? "disabledbtn"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleRequestToShip(
                                          el?.productId,
                                          el?._id
                                        )
                                      }
                                      disabled={selectedIds?.length > 0}
                                    >
                                      <span>{t("boxDetails.rqstToTxt")}</span>
                                    </button>
                                   
                                    {el.status !== "requested" && (
                                      <button
                                        className={`cmn-btn btn-style btn-style-transparent ${
                                          selectedIds?.length > 0
                                            ? "disabledbtn"
                                            : ""
                                        }`}
                                        disabled={selectedIds?.length > 0}
                                        onClick={() =>
                                          handleSell("single", el, el?._id)
                                        }>
                                        <span>
                                          {" "}
                                          {t("boxDetails.sellTxt1")}
                                          {t("boxDetails.sellTxt5")}
                                          {singleSellPrice(el) || 0}
                                          DT
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                </label>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <div className="no-inventory">
                        <h6>{t("profile.noInvTxt")}</h6>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {!hide && userInventory?.length > 0 && (
                <div className="load-more" onClick={handleLoadMore}>
                  <button type="button">
                    {t("proTransactions.showMore")}{" "}
                    <i className="las la-angle-down ml-2"></i>
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <ConfirmationPopUp
        Confirm
        onCloseModal={onCloseConfirmModal}
        open={openConfirm}
        handleConfirm={handleConfirmPop}
        title={t("model.sellConfirm")}
        isSelll={isSelll}
      />
      <InventoryPopUp
        open={open}
        onCloseModal={onCloseModal}
        userDetails={userDetails}
        // handleAddressChange={handleAddressChange}
        handleConfirm={handleConfirm}
        selectedAddress={selectedAddress}
        allShippingMethods={allShippingMethods}
        handleSelectShippingMethod={handleSelectShippingMethod}
        selectedShipping={selectedShipping}
        handleAccept={handleAccept}
        accept={accept}
      />
      <AddShippingPopup
        show={show}
        handleClose={handleClose}
        setOpen={setOpen}
      />
    </>
  );
};

export default Inventory;
