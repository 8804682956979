import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import FairnessPopUp from "../PopUp/fairnessPopup";
import { includeToFixed } from "../../utils/useFunc";
import { useTranslation } from "react-i18next";
import free from "../../assets/images/free.png";
import rocket from "../../assets/images/newhome/rocket.gif";

const RollButton = ({
  handlespinvalue,
  spinvalue,
  boxId,
  boxPrice,
  active,
  winner2,
  toTalearnMoney,
  handleAllQuickSell,
  handleMute,
  playSound,
  freeBox,
  boxItems,
  clientSeed,
  genarateNewSeed,
  handleNewSeed,
  opacity,
}) => {
  const result = useSelector((state) => state.user);
  const { adminSetting } = result || {};
  const { hideTryForFree } = adminSetting || false;
  const totalprice = includeToFixed(
    parseFloat(boxPrice) * parseInt(active, 10)
  );
  const [fairnessOpen, setFairnessOpen] = useState(false);
  const handleOpenFairness = (e) => {
    e.preventDefault();
    setFairnessOpen(true);
  };

  const handleScroll = () => {
    // const elem = document.getElementById('scroll-item-box');
    // elem.scrollIntoView({
    // 	behavior: 'smooth',
    // 	block: 'end',
    // 	inline: 'nearest',
    // });
    if (typeof window !== "undefined") {
      const elem = document.getElementById("scroll-item-box");
      if (elem) {
        let position = elem.getBoundingClientRect();
        window.scrollTo({
          top: position.top + window.scrollY - 30,
          behavior: "smooth",
        });
      }
    }
  };
  const { t } = useTranslation();
  return (
    <>
      {/* {active !== '1' && winner2 ? (
        <div className='spin-button spin-horiz wow animate__animated animate__slideInUp'>
          <Button
            onClick={() => handlespinvalue(boxId)}
            disabled={spinvalue > 0}>
            TRY AGAIN
          </Button>
          <Button onClick={handleAllQuickSell}>
            SELL ALL FOR {toTalearnMoney?.toFixed(2)} DT
          </Button>
        </div>
      ) : ( */}
      {fairnessOpen ? (
        <FairnessPopUp
          fairnessOpen={fairnessOpen}
          setFairnessOpen={setFairnessOpen}
          boxItems={boxItems}
          clientSeed={clientSeed}
          genarateNewSeed={genarateNewSeed}
          handleNewSeed={handleNewSeed}
        />
      ) : (
        ""
      )}
      <div
        className={`spin-button wow animate__animated animate__slideInUp ${
          opacity ? "" : "hide"
        }`}
      >
        <div className="roll-btns">
          {freeBox && (
            <Button
              className="animated-button"
              onClick={() => handlespinvalue(boxId, "free")}
              disabled={spinvalue > 0}
            >
              {t("boxDetails.rollBtnTxt1")} {t("boxDetails.rollBtnTxt2")}
            </Button>
          )}
          {hideTryForFree === true ? (
            <Button
              className="try-btn animated-button"
              onClick={() => handlespinvalue(boxId, "freeTry")}
              disabled={spinvalue > 0}
            >
              <img src={free} alt="free" /> {t("boxDetails.rollBtnTxt3")}{" "}
              {t("boxDetails.rollBtnTxt2")}!
            </Button>
          ) : (
            ""
          )}

          {!freeBox && (
            <Button
              className="animated-button shake"
              onClick={() => handlespinvalue(boxId, "paid")}
              disabled={spinvalue > 0}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              {t("boxDetails.rollBtnTxt1")} {totalprice} DT{" "}
              <img src={rocket} alt="icon" className="roll-rocket" />
            </Button>
          )}
        </div>
        <div className="fairness-link">
          <Link onClick={(e) => handleOpenFairness(e)} className="check">
            <i className="fas fa-clipboard-check"></i>{" "}
            {t("boxDetails.fairnessTxt1")}
          </Link>
          <Link onClick={handleScroll} className="box">
            <i className="fas fa-box" aria-hidden="true"></i>
            {t("boxDetails.inTheBoxTxt")}
          </Link>
        </div>
      </div>
      {/* )} */}
    </>
  );
};
export default RollButton;
