import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import affiliate from "../../assets/images/user/affiliate.png";
import earning from "../../assets/images/user/earning.png";
import "./profile-styles.css";
import { clientPath } from "../../config/keys";
// import { EmailShareButton, FacebookShareButton } from "react-share";
// import { FacebookIcon, EmailIcon } from "react-share";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAffiliateUserById } from "../../reducers/userReducer";
import { useDispatch, useSelector } from "react-redux";
import { calcTime } from "./../../utils/getTimeZone";


const Affiliate = ({ userData }) => {

  const dispatch = useDispatch();
  const [skip,setSkip] = useState(0);
  const [hide, setHide] = useState(false);
  const result = useSelector((state) => state.user);
  const { refereeUserList,referUsersCount, 
    totalCountThisMonth,
    totalAmountThisMonth,
    totalCountLastMonth,
    totalAmountLastMonth
  } = result || {};
  const { t } = useTranslation();
  const referalUrl = `${clientPath}/?ref=${userData?.referalLink}`;

useEffect(() => {
  dispatch(getAffiliateUserById(skip));
}, [dispatch, skip]);
const handleLoadMore = () =>{
  setSkip(skip+5)
}
useEffect(()=>{
  if((refereeUserList?.length > 0 && referUsersCount<=refereeUserList?.length) || refereeUserList?.length === 0){
    setHide(true)
  }
},[referUsersCount,refereeUserList?.length])

  const thisMonthEarnMsg=`De ${totalCountThisMonth} parrainés ce mois-ci`;
  const lastMonthEarnMsg=`De ${totalCountLastMonth} parrainés le dernier mois `;
 
  return (
    <>
      <div>
        <div className="referral-link-wrapper">
          <h3 className="title">{t("proReferral.refHeading1")}</h3>
          <span className="label label_link">{t("proReferral.lableTxt")}</span>
          <div className="copy-link">
            <span className="copy-link-icon">
              <i className="las la-link"></i>
            </span>
            <div className="copy-link-inner">
              <ClipboardCopy copyText={referalUrl} />
            </div>
          </div>
          {/* <div className="share-buttons">
            <EmailShareButton
              url={referalUrl}
              subject="subject"
              body={
                "hey there, Join Inboxi click on below link" +
                <Link To={referalUrl} />
              }
              className="email-share"
            >
              <EmailIcon size={32} round />
              <span className="social-txt">Refer via email</span>
            </EmailShareButton>

            <FacebookShareButton
              url={referalUrl}
              quote="Join Inboxi, click on below link"
              className="fb-share"
            >
              <FacebookIcon size={32} round />
              <span className="social-txt">Refer via Fackeook</span>
            </FacebookShareButton>
          </div> */}
        </div>

        <div className="referral-overview mt-30">
          <div className="row justify-content-center mb-none-30">
            <div className="col-lg-5 col-sm-6 mb-30">
              <div className="referral-crad">
                <div className="referral-crad__icon">
                  <img src={affiliate} alt="affiliate icon" />
                </div>
                <div className="referral-crad__content">
                  <h3 className="number">{userData?.referalCount}</h3>
                  <span>{t("proReferral.earnTxt")}</span>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-sm-6 mb-30">
              <div className="referral-crad">
                <div className="referral-crad__icon">
                  <img src={affiliate} alt="affiliate icon" />
                </div>
                <div className="referral-crad__content">
                <h3 className="number">
                    {userData?.referalCommission + " "}DT
                  </h3>
                  <span>{t("referencesTran.totalEarned")}</span>
                </div>
              </div>
            </div>

          

           <div className="col-lg-5 col-sm-6 mb-30">
              <div className="referral-crad">
                <div className="referral-crad__icon">
                  <img src={earning} alt="earning icon" />
                </div>
                <div className="referral-crad__content">
                  <h3 className="number">
                    {totalAmountThisMonth} DT
                  </h3>
                  <span>{thisMonthEarnMsg}</span>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-sm-6 mb-30">
              <div className="referral-crad">
                <div className="referral-crad__icon">
                  <img src={earning} alt="earning icon" />
                </div>
                <div className="referral-crad__content">
                  <h3 className="number">
                  {totalAmountLastMonth} DT
                  </h3>
                  <span>{lastMonthEarnMsg}</span>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="referral-transaction">
          <div className="all-transaction__header">
            <h3 className="title">{t("proReferral.refHeading2")}</h3>
            <div className="date-range">
              <input
                type="text"
                data-range="true"
                data-multiple-dates-separator=" - "
                data-language="en"
                className="datepicker-here form-control"
                data-position="top left"
                placeholder="min - max date"
              />
              <i className="las la-calendar-alt"></i>
            </div>
          </div>
          <div className="table-responsive-lg">
            <Table>
              <thead>
                <tr>
                  <th>{t("proReferral.thTxt1")}</th>
                  <th>{t("proReferral.thTxt2")}</th>
                  <th>{t("proReferral.thTxt3")}</th>
                  <th>{t("proReferral.thTxt4")}</th>
                </tr>
              </thead>

              <tbody>
                {refereeUserList?.map((el) => {
                  return (
                    <tr>
                      <td>
                        <div className="date">
                          <span>{calcTime("+1", el?.joined_at)}</span>
                        </div>
                      </td>
                      <td>{el?.referalBy?.affiliateLevel?.levelName}</td>
                      <td>{el?.username}</td>
                      <td>{el?.email}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          {!hide && <div className="load-more">
            <button type="button" onClick={handleLoadMore}>
              {t("proReferral.showMore")}{" "}
              <i className="las la-angle-down ml-2"></i>
            </button>
          </div>}
        </div>
      </div>
    </>
  );
};

export default Affiliate;

const ClipboardCopy = ({ copyText }) => {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <div className="input_button">
      <input type="text" value={copyText} readOnly />
      <button
        type="submit"
        onClick={handleCopyClick}
        className="clipboard-copy"
      >
        <span>{isCopied ? "Copied!" : "Copy"}</span>
      </button>
    </div>
  );
};
