// import React from 'react'
import { Modal } from "react-bootstrap";
import close from "../../assets/images/game/close.png";
import { useTranslation } from "react-i18next";

const ConfirmationPopUp = ({
  onCloseModal,
  open,
  handleConfirm,
  title,
  walletAmount,
  isSelll
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={open}
      centered
      onHide={() => onCloseModal("change")}
      className={`modal fade login-popup confirmation-popup ${
        title ? "balance-popup" : ""
      }`}
      id="loginModal"
      tabindex="1"
      role="dialog"
      size="lg"
      aria-hidden="true"
    >
      <div class="modal-content modal_contents">
        <div class="modal-body">
          <div class="account-form-area">
            <button
              onClick={() => onCloseModal("change")}
              type="button"
              class="close-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="close" />
            </button>

            <div class="form-group text-center mt-5">
              {walletAmount && (
                <p>
                  {t("form.balLftTxt")}:{" "}
                  <span> {walletAmount?.toFixed(2) || 0}</span> DT
                </p>
              )}
              <h3 class="ar-reg-title text-center pop_head">
                {!title ? t("form.sureQuick") : <p>{title}</p>}
              </h3>
              <div className="popup__buttons">
                <button
                  type="submit"
                  class=" btn-style btn-style-transparent"
                  onClick={() => onCloseModal("change")}
                >
                  <span>{t("model.addressTxt6")}</span>
                </button>
                <button
                  type="submit"
                  class="cmn-btn pop_btn bor_btn btn-style"
                  disabled={isSelll}
                  onClick={handleConfirm}  
                >
                   <span>{t("model.addressTxt7")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmationPopUp;
