/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
// import { Dropdown } from "react-bootstrap";
// import logo from "../../assets/images/boxilogof.png";
import logo from "../../assets/images/Boxi-logo-solid1.png";
// import alarm from "../../assets/images/alarm-clock.png";
import user from "../../assets/images/user/user-icon.png";
// import ticket from "../../assets/images/icon/btn/tag.png";
import Login from "../login/login";
import Register from "../register/register";
import "./header.css";
import { queryString, includeToFixed } from "../../utils/useFunc";
import { useTranslation } from "react-i18next";
import { withoutAuth } from "../../config/axios";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { getLoginUser } from "../../reducers/userReducer";
import InventoryPopup from "../unboxing/inventoryPopup";
import wallet from "../../assets/images/wallet.png";
import inventory from "../../assets/images/inventory.png";
import { Button } from "react-bootstrap";
import DepositPopup from "../PopUp/depositPopup";
import { socket } from "../../config/socket";
import ForgetPassword from "../ForgetPassword/forgetPassword";
// import { setLanguages } from "../../reducers/userReducer";
import { userInstance } from "../../config/axios";
import { clientPath } from "../../config/keys";

// import ResetPassword from "../ForgetPassword/resetPassword";
const Header = () => {
  const history = useHistory();
  const { payment_ref } = queryString();
  const dispatch = useDispatch();
  const result = useSelector((state) => state.user);
  const { userDetails, distinctInventProd } = result || {};
  // const { paymentOption } = adminSetting || {};
  let preview = userDetails?.profile || user;
  const { t } = useTranslation();
  const [togglenavbar, settogglenavbar] = useState(true);
  const [deposit, setDeposit] = useState(false);
  const handletogglenavbar = () => settogglenavbar(!togglenavbar);
  const [sticky, setSticky] = useState(false);
  const [login, setLogin] = useState(false);
  const [signup, setSignup] = useState(false);
  const [show, setShow] = useState(false);
  const [isOtpOpen, setIsOtpOpen] = useState(false);
  const [isUser, setisUser] = useState();

  const [loggedinmenu, setloggedinmenu] = useState(true);
  const loggedinmenuhandle = () => setloggedinmenu(!loggedinmenu);
  const [forget, setForget] = useState(false);
  // const[reset,setReset]=useState(false);
  // const changeLanguage = (event) => {
  //   i18n.changeLanguage(event);
  //   dispatch(setLanguages(event));
  // };
  const { modal, ref } = queryString();
  const handledeposit = () => {
    // if(paymentOption){
    setDeposit(true);
    // }
  };
  const handleClose = () => {
    setDeposit(false);
  };

  const [refUserId, setRefUserId] = useState("");

  const checkReferalLink = async () => {
    const response = await withoutAuth().get(
      `/api/auth/checkReferalLink?referalLink=${ref}`
    );
    const { code, msg, referalBy } = response.data;
    if (code === 200) {
      setRefUserId(referalBy);
      // window.location.href = '/?modal=SignUp'
    } else {
      toast(msg);
      // console.log("msg", msg);
    }
  };

  useEffect(() => {
    if (ref) {
      checkReferalLink();
    }
  }, []);

  // const path = window.location.pathname;

  const watchScroll = () => {
    if (window.scrollY > 60) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    if (window !== undefined && window.screen.width >= 0) {
      window.addEventListener("scroll", watchScroll);
    }
    return () => {
      window.removeEventListener("scroll", watchScroll);
    };
  }, []);

  const handleLogin = () => {
    if (login) {
      //window.history.replaceState({}, document.title, "/");
    }
    setLogin(!login);
    setSignup(false);
    if (!login) {
      localStorage.setItem("islogin", "yes");
    } else {
      localStorage.removeItem("islogin");
    }
  };
  const handleSignup = (change, uId) => {
    if (uId) {
      setIsOtpOpen(true);
      setisUser(uId);
    }
    setSignup(!signup);
    setLogin(false);
    if (change) {
      setLogin(!login);
    }
  };

  const handleForget = (change) => {
    // console.log("Chenge in forget==>",change)
    setForget(!forget);
    setLogin(!login);
  };

  // const handleReset=()=>{
  //   setReset(!reset)
  //   setLogin(!login)
  // }
  useEffect(() => {
    if (modal === "Login") {
      handleLogin();
    } else if (modal === "SignUp" || ref) {
      handleSignup();
    }
  }, [modal]);

  const handleLogout = () => {
    localStorage.removeItem("inboxi#@user");
    window.location.href = "/";
    localStorage.removeItem("islogin");
  };
  const handleProfilePage = () => {
    history.push("/profile");
  };

  // for add to cart show
  const handleShow = (e) => {
    e.preventDefault();
    setShow(!show);
  };

  const totalInventoryItems = () => {
    if (distinctInventProd?.length > 0) {
      const result = distinctInventProd.reduce(
        (pre, next) => pre + next?.quantity,
        0
      );
      return result;
    }
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setloggedinmenu(true);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef1 = useRef();

  useOutsideAlerter1(wrapperRef1);

  function useOutsideAlerter1(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        //  setShow(true)
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    socket.on("updateRealTimeUserData", (data) => {
      if (data === "opendInventory") {
        setShow(true);
        return;
      }
      dispatch(getLoginUser());
    });
  }, []);
  /*********  payment start  *****/
  useEffect(() => {
    async function successPayment() {
      if (payment_ref) {
        const response = await userInstance().get(
          `api/user/successPayment/${payment_ref}`
        );
        //console.log('response.data==>', response.data);
        const {
          code,
          status,
          paymentDetails: { payment },
        } = response.data || {};
        const { successUrl } = payment || {};
        let txtUrl = "";
        const cUrl = successUrl.substring(
          0,
          successUrl.indexOf("&payment_ref")
        );
        if (cUrl) {
          txtUrl = cUrl;
        } else {
          txtUrl = successUrl.substring(0, successUrl.indexOf("?payment_ref"));
        }
        if (status !== "success") {
          toast.error(t("toast.headererror"), {
            toastId: "battles",
          });
        }
        if (code === 200 && status === "success") {
          toast.success(t("toast.headersuccess"), {
            toastId: "battles",
          });
          dispatch(getLoginUser());
        }
        const newUrl = txtUrl?.replace(clientPath, "");
        history.push(newUrl);
      }
    }
    successPayment();
  }, [dispatch, payment_ref]);

  return (
    <>
      <div className='header-wrapper'>
        <header className={`header`}>
          <div
            className={`header__top ${
              sticky ? "animate__animated animate__fadeInDown menu-fixed " : ""
            } `}>
            <div className='container'>
              <div className='ar-row row align-items-center'>
                <div className='col-sm-4'>
                  <div className='ar-left left d-flex align-items-center no-display-xl flex-wrap'>
                  <a href="tel:+216 52 11 88 88">
                      <i className='las la-phone-volume'></i>{" "}
                      {/* {t("topHeader.cusSupport")} */}
                      +216 52 11 88 88
                    </a>
                    <div className='work-time'>
                      {/* <i class='fa fa-alarm-clock'></i> */}
                      {/* <img src={alarm} alt='hjb' /> */}
                      <i class='las la-clock'></i>
                      de 10H à 18H
                    </div>
                  </div>
                </div>
                <div className='col-sm-8'>
                  <div className='right'>
                    <div className='product__cart' ref={wrapperRef1}>
                      {localStorage.getItem("inboxi#@user") && (
                        <>
                          <Link onClick={handledeposit}>
                            <span className='total__amount'>
                              <img src={wallet} alt='wallet' />
                              {includeToFixed(userDetails?.walletAmount) ||
                                "0"}{" "}
                              DT
                            </span>
                          </Link>
                          <Link className='amount__btn' onClick={handleShow}>
                            {/* <i className='las la-shopping-basket'></i> */}
                            <img src={inventory} alt='inventory' />
                            <span className='cart__num'>
                              {totalInventoryItems() || 0}
                            </span>
                          </Link>
                        </>
                      )}
                      <div>{show && <InventoryPopup setShow={setShow} />}</div>
                    </div>

                    {!localStorage.getItem("inboxi#@user") ? (
                      <Button
                        // to='/?modal=Login'
                        onClick={() => handleLogin()}
                        className='btn-style'
                        data-toggle='modal'
                        data-target='#loginModal'>
                        <span>
                          {/* <i className='las la-user'></i> */}
                          LOGIN BOXI
                        </span>
                        {/* <span>{`${t("regForm.loginTxt")} / ${t(
                          "topHeader.logoutTxt"
                        )}`}</span> */}
                      </Button>
                    ) : (
                      <div
                        className={`loggedin-menu ${
                          loggedinmenu ? "close-dropdown" : "open"
                        }`}
                        onClick={loggedinmenuhandle}
                        ref={wrapperRef}>
                        {/* <i class="las la-bars loggedin"></i> */}
                        <div className='loggedin'>
                          <img
                            src={preview}
                            width={40}
                            height={40}
                            alt='user icon'
                          />{" "}
                          &nbsp;
                          <span>
                            {userDetails?.username}{" "}
                            <i class='fa fa-caret-down'></i>
                          </span>
                        </div>
                        <ul className='loggedin-list'>
                          <li
                            data-value='Filter option'
                            className='option'
                            onClick={handleProfilePage}>
                            {/* <Link to = '/profile'> */}
                            <i class='las la-user'></i> {t("topHeader.proTxt")}
                            {/* </Link> */}
                          </li>
                          <li
                            data-value='Filter option'
                            className='option'
                            onClick={handleLogout}>
                            {/* <Link to = '/'> */}
                            <i class='fa fa-sign-out'></i>{" "}
                            {t("topHeader.logoutTxt")}
                            {/* </Link> */}
                          </li>
                        </ul>
                      </div>
                    )}
                    <div className='sticky-header-btn'>
                      <Link to='/boxes' className='btn-style'>
                        <span className='mr-2'>
                          {/* <img src={ticket} alt="icon" className="mr-2" /> */}
                          <i class='fa fa-archive' aria-hidden='true'></i>
                        </span>
                        <span>{t("header.openBtn")}</span>
                      </Link>
                    </div>
                    {/* <div className="language">
                      <Dropdown>
                        <Dropdown.Toggle variant="default" id="dropdown-basic">
                          {i18n?.resolvedLanguage?.toUpperCase()}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => changeLanguage("en")}>
                            <span class="flag-icon flag-icon-sun"></span>{" "}
                            English
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => changeLanguage("fr")}>
                            <span class="flag-icon flag-icon-sun"></span> French
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => changeLanguage("ar")}>
                            <span class="flag-icon flag-icon-sun"></span> Arabic
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='header__bottom'>
            <div className='container'>
              <nav className='navbar navbar-expand-xl p-0 align-items-center'>
                <Link className='site-logo site-title' to='/'>
                  <img src={logo} alt='site-logo' />
                  <span className='logo-icon'>
                    <i className='flaticon-fire'></i>
                  </span>
                </Link>
                <button
                  className='navbar-toggler ml-auto'
                  type='button'
                  data-toggle='collapse'
                  data-target='#navbarSupportedContent'
                  aria-controls='navbarSupportedContent'
                  aria-expanded='false'
                  aria-label='Toggle navigation'
                  onClick={handletogglenavbar}>
                  <span className='menu-toggle'></span>
                </button>
                <div
                  className={`${
                    togglenavbar
                      ? "collapse navbar-collapse"
                      : "collapse navbar-collapse show"
                  }`}
                  id='navbarSupportedContent'>
                 
                  <ul className='navbar-nav main-menu ml-auto'>
                    <li>
                      <a href='/'>{t("header.home")}</a>
                    </li>
                    <li>
                      <a href='/boxes'>{t("header.boxes")}</a>
                    </li>
                    <li>
                      <a href='/how-to-play'>{t("header.howitWorks")}</a>
                    </li>
                    {localStorage.getItem("inboxi#@user") &&  <li>
                      <a href='/store'>{t("header.store")}</a>
                    </li>
                    }
                    <li>
                      <a href='/contact-us'>{t("header.contact")}</a>
                    </li>
                  </ul>

                  <div className='nav-right'>
                    <a href='/boxes' className='btn-style'>
                      <span className='mr-2'>
                        <i class='fa fa-archive' aria-hidden='true'></i>
                      </span>
                      <span>{t("header.openBtn")}</span>
                    </a>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </header>

        {!localStorage.getItem("inboxi#@user") && (
          <Login
            login={login}
            handleLogin={handleLogin}
            handleSignup={handleSignup}
            handleForget={handleForget}
          />
        )}

        {!localStorage.getItem("inboxi#@user") && forget && (
          <ForgetPassword forget={forget} handleForget={handleForget} />
        )}

        {!localStorage.getItem("inboxi#@user") && (
          <Register
            signup={signup}
            handleSignup={handleSignup}
            handleLogin={handleLogin}
            refUserId={refUserId}
            isOtpOpen={isOtpOpen}
            isUser={isUser}
          />
        )}
        <DepositPopup show={deposit} handleClose={handleClose} />
      </div>
    </>
  );
};

export default Header;
