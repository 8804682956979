import { useState, useRef } from "react";
import Layout from "../layout/layout";
import { useTranslation } from "react-i18next";
import HowToPlayCard from "./howtoplaycard";
import playBox from "../../assets/images/howtoplay/boxnew.png";
import play from "../../assets/images/howtoplay/playBtn.png";
import video from "../../assets/images/howtoplay/video.mp4";
import "./howtoplay.css";

const HowToPlay = () => {
  const vidRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayVideo = () => {
    vidRef.current.play();
    setIsPlaying(true);
  };
  const { t } = useTranslation();

  const handleVideoPause = () => {
    vidRef.current.pause();
    setIsPlaying(false);
  };
  return (
    <Layout>
      <div className='how-to-play-page'>
        <div className='faq-page'>
          <div className='how-to-play-topsection faq-page-topsection'>
            <div className='container'>
              <div className='how-to-play-title faq-page-title'>
                <h2>{t("home.playHeading3")}</h2>
                <h2>{t("home.playHeading4")}</h2>
                <img src={playBox} alt='' />
              </div>
            </div>
          </div>
          <div className='container'>
            <div className='play-video-wrapper'>
              <div
                className={
                  isPlaying ? "howtoplay-video" : "howtoplay-video-hide"
                }>
                {!isPlaying ? (
                  <div className='howtoplay-video-btn'>
                    <span onClick={handlePlayVideo} role='presentation'>
                      <img src={play} alt='play btn' />
                    </span>
                  </div>
                ) : (
                  ""
                )}
                <video ref={vidRef} onClick={handleVideoPause}>
                  <source src={video} type='video/mp4' />
                  Your browser does not support HTML video.
                </video>
              </div>
            </div>
            <div className='play-content-section'>
              <div className='play-content-title'>
                <h3>{t("home.playHeading2")}</h3>
              </div>
              <div className='howtoplay-card-wrapper'>
                <HowToPlayCard
                  title={t("howWorks.title1")}
                  description={t("howWorks.descTxt1")}
                />
                <HowToPlayCard
                  title={t("howWorks.title2")}
                  description={t("howWorks.descTxt2")}
                  description1={t("howWorks.descTxt3")}
                  description2={t("howWorks.descTxt4")}
                />
                <HowToPlayCard
                  title={t("howWorks.title3")}
                  description={t("howWorks.descTxt5")}
                />
                <HowToPlayCard
                  title={t("howWorks.title4")}
                  description={t("howWorks.descTxt6")}
                />
                <HowToPlayCard
                  title={t("howWorks.title5")}
                  description={t("howWorks.descTxt7")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HowToPlay;
