import React from "react";
import "./battles.css";
import { useTranslation } from "react-i18next";
import { includeToFixed } from "../../utils/useFunc";
const BattleHeader = ({ battleObj }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="battle__header">
        <div className="main_battle_section">
          <div className="totol_cost totol_cost_1 totalcost">
            <div className="battle_cost">
              {t("openBoxes.totalCost")}&nbsp;
              <span className="price-highlight">
                {includeToFixed(battleObj?.totalbattleCost)}&nbsp;DT
              </span>
            </div>
          </div>
          <div className="totol_cost totol_cost_2 totalcosts">
            <div className="battle_cost">
              <span>
                {t("openBoxes.typeTxt")}:&nbsp;<b>{battleObj?.joinType}</b>
              </span>
            </div>
          </div>
          <div className="main_diamond">
            <div className="diamond">
              <span>{battleObj?.round}</span>
            </div>
          </div>
          <div className="battle_round">{t("battleTable.tabletxt1")}</div>
        </div>
      </div>
    </>
  );
};
export default BattleHeader;
