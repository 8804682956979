import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./battles.css";
const BattlesFilter = ({ handleSort }) => {
  const { t } = useTranslation();

  return (
    <div className="row filter-header">
      <div className="col-lg-6 date-price">
        <label className="date">
          {t("openBoxes.sortTxt1")} {t("proTransactions.thTxt1")}
        </label>
        <label class="switch">
          <input type="checkbox" onChange={handleSort} />
          <span class="slider round"></span>
        </label>
        <label className="price">
          {t("openBoxes.sortTxt1")} {t("home.boxPriceTitle")}
        </label>
      </div>
      <div className="col-lg-6 battles-history">
        <Link to="/battleHistory" className="cmn-btn btn-style">
          <span>{t("openBoxes.battleHistoryBtn")}</span>
        </Link>
      </div>
    </div>
  );
};

export default BattlesFilter;
