import React from "react";
import Helmet from "react-helmet";
import Layout from "./layout/layout";

import "./privacy.css";

const Termsconditions = () => {
  return (
    <div className="terms">
      <Helmet>
        <meta property="og:title" content="Terms &amp; Conditions | BOXI" />
        <title>Terms &amp; Conditions | BOXI</title>
      </Helmet>
      <Layout>
        <div className="container">
          <div className="terms-page">
            <h2>Terms &amp; Conditions</h2>{" "}
            <p>
              By downloading or using the game, these terms will automatically
              apply to you – you should make sure therefore that you read them
              carefully before using the game. You’re not allowed to copy, or
              modify the game, any part of the game, or our trademarks in any
              way. You’re not allowed to attempt to extract the source code of
              the game, and you also shouldn’t try to translate the game into
              other languages, or make derivative versions. The game itself, and
              all the trade marks, copyright, database rights and other
              intellectual property rights related to it, still belong to STE
              ATCOM DISTRIBUTION SARL.
            </p>{" "}
            <p>
              The BOXI game stores and processes personal data that you have
              provided to us, in order to provide our Service. It’s your
              responsibility to keep your phone and access to the game secure.
              We therefore recommend that you do not jailbreak or root your
              phone, which is the process of removing software restrictions and
              limitations imposed by the official operating system of your
              device. It could make your phone vulnerable to
              malware/viruses/malicious programs, compromise your phone’s
              security features and it could mean that the BOXI game won’t work
              properly or at all.
            </p>{" "}
            <p>
              You should be aware that there are certain things that STE ATCOM
              DISTRIBUTION SARL will not take responsibility for. Certain
              functions of the game will require the game to have an active
              internet connection. The connection can be Wi-Fi, or provided by
              your mobile network provider, but STE ATCOM DISTRIBUTION SARL
              cannot take responsibility for the game not working at full
              functionality if you don’t have access to Wi-Fi, and you don’t
              have any of your data allowance left.
            </p>{" "}
            <p></p>{" "}
            <p>
              If you’re using the game outside of an area with Wi-Fi, you should
              remember that your terms of the agreement with your mobile network
              provider will still apply. As a result, you may be charged by your
              mobile provider for the cost of data for the duration of the
              connection while accessing the game, or other third party charges.
              In using the game, you’re accepting responsibility for any such
              charges, including roaming data charges if you use the game
              outside of your home territory (i.e. region or country) without
              turning off data roaming. If you are not the bill payer for the
              device on which you’re using the game, please be aware that we
              assume that you have received permission from the bill payer for
              using the game.
            </p>{" "}
            <p>
              Along the same lines, STE ATCOM DISTRIBUTION SARL cannot always
              take responsibility for the way you use the game i.e. You need to
              make sure that your device stays charged – if it runs out of
              battery and you can’t turn it on to avail the Service, STE ATCOM
              DISTRIBUTION SARL cannot accept responsibility.
            </p>{" "}
            <p>
              With respect to STE ATCOM DISTRIBUTION SARL’s responsibility for
              your use of the game, when you’re using the game, it’s important
              to bear in mind that although we endeavour to ensure that it is
              updated and correct at all times, we do rely on third parties to
              provide information to us so that we can make it available to you.
              STE ATCOM DISTRIBUTION SARL accepts no liability for any loss,
              direct or indirect, you experience as a result of relying wholly
              on this functionality of the game.
            </p>{" "}
            <p>
              At some point, we may wish to update the game. The game is
              currently available on Android &amp; iOS – the requirements for
              both systems (and for any additional systems we decide to extend
              the availability of the game to) may change, and you’ll need to
              download the updates if you want to keep playing the game. STE
              ATCOM DISTRIBUTION SARL does not promise that it will always
              update the game so that it is relevant to you and/or works with
              the Android &amp; iOS version that you have installed on your
              device. However, you promise to always accept updates to the game
              when offered to you, We may also wish to stop providing the game,
              and may terminate use of it at any time without giving notice of
              termination to you. Unless we tell you otherwise, upon any
              termination, (a) the rights and licenses granted to you in these
              terms will end; (b) you must stop using the game, and (if needed)
              delete it from your device.
            </p>{" "}
            <p>
              <strong>Changes to This Terms and Conditions</strong>
            </p>{" "}
            <p>
              We may update our Terms and Conditions from time to time. Thus,
              you are advised to review this page periodically for any changes.
              We will notify you of any changes by posting the new Terms and
              Conditions on this page. These changes are effective immediately
              after they are posted on this page.
            </p>{" "}
            <p>
              <strong>Contact Us</strong>
            </p>{" "}
            <p>
              If you have any questions or suggestions about our Terms and
              Conditions, do not hesitate to contact us at contact@boxi.tn.
            </p>
          </div>
        </div>
      </Layout>
    </div>
  );
};
export default Termsconditions;
