import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userInstance, withoutAuth, gameInstance } from "../config/axios";
import { toast } from "react-toastify";
import { getShuffleArray } from "../utils/useFunc";

const initialState = {
  userDetails: {},
  boxesList: [],
  boxDetails: {},
  productCategories: [],
  boxCategories: [],
  lan: localStorage.getItem("i18nextLng") || "fr",
  boxListByCategory: [],
  productBoxes: [],
  topItemsUnboxed: [],
  winItem: {},
  liveDrops: [],
  userInventory: [],
  userTransactions: [],
  totalTransaction: 0,
  inventoryTotal: 0,
  spinCount: {
    count: 0,
  },
  toTalearnMoney: 0,
  boxProductList: [],
  boxprofit: "",
  allBattles: [],
  activBattleData: {},
  prodListByCategory: [],
  productTotal: 0,
  productDetails: {},
  allCartsProducts: [],
  allBrands: [],
  currentBoxItems: [],
  privateBattlesList: [],
  distinctInventProd: [],
  pastGames: [],
  gamesCount: 0,
  allBattleHistory: [],
  adminSetting: {},
  allOrders: [],
  orderDetails: [],
  allRarity: [],
  totalInventoryCounts: 0,
  totalInventoryCost: 0,
  allInventoryItems: [],
  fairnessResult: "",
  verifyResult: {},
  loading: true,
  minVal: 0,
  maxVal: 0,
  allShippingMethods: [],
  alluserCount: 0,
  boxCount: 0,
  unboxCount: 0,
  refereeUserList:[],
  ordersCount:0,
referUsersCount:0,
totalCountThisMonth:0,
totalAmountThisMonth:0,
totalCountLastMonth:0,
totalAmountLastMonth:0
};
export const getLoginUser = createAsyncThunk(
  "user/getUser",
  async (data, { rejectWithValue }) => {
    try {
      const res = await userInstance().get("/api/user/getUser");
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);
export const setLanguages = createAsyncThunk(
  "user/lan",
  async (data, { rejectWithValue }) => {
    try {
      return {
        resultData: data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getSingleBoxData = createAsyncThunk(
  "user/getBoxDetailById",
  async (data, { rejectWithValue }) => {
    try {
      const res = await withoutAuth().get(`api/user/getBoxDetailById/${data}`);
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

// export const uploadProfileImage = createAsyncThunk(
// 	'user/uploadProfileImage',
// 	async (data, { rejectWithValue }) => {
// 		try {
// 			const res = await withoutAuth().post(`api/user/uploadProfileImage`)
// 			return {
// 				resultData: res,
// 			};
// 		} catch (err) {
// 			return rejectWithValue({
// 				msg: err.response.data.msg,
// 				code: err.response.data.code,
// 			});
// 		}
// 	});
export const getAllBoxes = createAsyncThunk(
  "user/getBox",
  async (data, { rejectWithValue }) => {
    try {
      const res = await withoutAuth().get("/api/user/getBox");
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getAllCategorySelectList = createAsyncThunk(
  "user/getCategory",
  async (data, { rejectWithValue }) => {
    try {
      const productCategories = [];
      const boxCategories = [];
      const res = await withoutAuth().get("api/user/getCategory");
      const { code, category } = res.data;
      if (code === 200) {
        category.forEach((el) => {
          if (el.categoryType === "box") {
            boxCategories.push(el);
          } else {
            productCategories.push(el);
          }
        });
      }
      return {
        productCategories,
        boxCategories,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);
export const getAllBoxesByCat = createAsyncThunk(
  "user/getAllBoxesByCat",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      //console.log('hsdhjhdds', data?.searchName)

      if (data?.isload) {
        dispatch(loadingState(true));
      }

      const res = await withoutAuth().post("/api/user/getAllBoxesByCat", data);
      const { code } = res.data;
      //console.log('ghfdgfhdgh', res);
      //dispatch(loadingState(false))
      if (code === 200) {
        if (data?.isload) {
          dispatch(loadingState(false));
        }
      }

      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getBoxesByProductId = createAsyncThunk(
  "user/getBoxesByProductId",
  async (data, { rejectWithValue }) => {
    try {
      const res = await withoutAuth().get(
        `/api/user/getBoxesByProductId/${data}`
      );
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getTopItemsUnboxed = createAsyncThunk(
  "user/getTopItemsUnboxed",
  async (data, { rejectWithValue }) => {
    try {
      const res = await withoutAuth().get(
        `/api/user/getTopItemsUnboxed/${data}`
      );
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

// export const getGameResult = createAsyncThunk(
//   'user/getGameResult',
//   async (data, { rejectWithValue }) => {
//     try {
//       const res = await gameInstance().post('/api/game/hashedServerSeed', data);
//       console.log('game result ====>', res)
//       return {
//         resultData: res,
//       }
//     } catch (err) {
//       return rejectWithValue({
//         msg: err.response.data.msg,
//         code: err.response.data.code,
//       })
//     }
//   }
// )
export const getGameResult = createAsyncThunk(
  "user/getGameResult",
  async (data, { rejectWithValue }) => {
    try {
      const res = await gameInstance().post("/api/game/getGameResult", data);
      //console.log('gameResponse', res)
      const { code, msg } = res.data;
      if (code !== 200) {
        toast.error(msg);
      }

      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getLiveDrops = createAsyncThunk(
  "user/getLiveDrops",
  async (data, { rejectWithValue }) => {
    try {
      const res = await gameInstance().get("/api/user/getLiveDrops");
      //  console.log('check live drop ===>',res)
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const addToInventory = createAsyncThunk(
  "user/addToInventory",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await userInstance().post("/api/user/addToInventory", data);
      const { code, msg } = res?.data;
      if (code === 403) {
        return toast.error(msg, {
        	toastId: 'addToInventerr',
        });
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const forgetPass = createAsyncThunk(
  "user/forgetPass",
  async (data, { rejectWithValue }) => {
    try {
      const res = await withoutAuth().post("/api/auth/forgetPass", data);
      // console.log("RESPONSE OF FROGET DAta", res)
      const { code, msg } = res?.data;
      if (code === 200) {
        toast.success(msg, {
          toastId: "forgetPass",
        });
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const resetPass = createAsyncThunk(
  "user/resetPass",
  async (data, { rejectWithValue }) => {
    try {
      const res = await withoutAuth().post("/api/auth/resetPass", data);
      // console.log("RESPONSE OF reset DAta", res)
      const { code, msg } = res?.data;
      if (code === 200) {
        toast.success(msg, {
          toastId: "resetPass",
        });
      } else {
        toast.error(msg, {
          toastId: "resetPass",
        });
      }

      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getInventoryProducts = createAsyncThunk(
  "user/getInventoryProducts",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      // console.log('dasdsds', data?.searchBy)
      if (data?.searchBy !== "title") {
        dispatch(loadingState(true));
      }
      // console.log('skip', data);
      const res = await userInstance().post(
        `/api/user/getInventoryProducts`,
        data
      );

      // console.log('gdsdfgsfarter', res)

      dispatch(loadingState(false));

      return {
        resultData: res,
        newType: data.newType,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getAllInventoryProducts = createAsyncThunk(
  "user/getAllInventoryProducts",
  async (data, { rejectWithValue }) => {
    try {
      // console.log('skip', data);
      const res = await userInstance().post(
        `/api/user/getAllInventoryProducts`,
        data
      );
      return {
        resultData: res,
        newType: data.newType,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getDistinctInventoryProducts = createAsyncThunk(
  "user/getDistinctInventoryProducts",
  async (data, { rejectWithValue }) => {
    try {
      const res = await userInstance().get(
        "/api/user/getDistinctInventoryProducts"
      );
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const dispatchInventoryProducts = createAsyncThunk(
  "user/dispatchInventoryProducts",
  async (data, { rejectWithValue }) => {
    try {
      const res = await userInstance().put(
        `/api/user/dispatchInventoryProducts?skip=${data}`
      );
      // console.log('inventory==>>>',res);

      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getInventoryCounts = createAsyncThunk(
  "user/getInventoryCounts",
  async (data, { rejectWithValue }) => {
    try {
      const res = await userInstance().get(`/api/user/getInventoryCounts`);
      // console.log('inventory==>>>',res);

      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const updateUserWallet = createAsyncThunk(
  "user/updateUserWallet",
  async (data, { rejectWithValue }) => {
    try {
      const res = await userInstance().put("/api/user/updateUserWallet", data);
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const incrementWalletAmount = createAsyncThunk(
  "user/incrementWalletAmount",
  async (data, { rejectWithValue }) => {
    try {
      const res = await userInstance().put(
        "/api/user/incrementWalletAmount",
        data
      );
      // // console.log(data, '============>>>', res);
      // const { code, msg } = res?.data;
      // if (code === 200) toast.success(msg);
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const withDrawAmount = createAsyncThunk(
  "user/withDrawAmount",
  async (data, { rejectWithValue }) => {
    try {
      // console.log('hgjhdgsjhhj')
      const res = await userInstance().put("/api/user/withDrawAmount", data);
      // console.log(res);
      const { code } = res?.data;
      if (code === 200) toast.success("Withdraw Request Placed Successfully");
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const checkTokenExpiry = createAsyncThunk(
  "auth/checkTokenExpiry",
  async (data, { rejectWithValue }) => {
    try {
      const res = await userInstance().get("/api/auth/checkTokenExpiry");
      const { code } = res?.data;
      if (code === 444) {
        localStorage.removeItem("inboxi#@user");
        window.location.href = "/";
      }
      // if(code!==200){
      //   localStorage.removeItem('inboxi#@user')
      // }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getTransactionDetails = createAsyncThunk(
  "user/getTransactionDetails",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      dispatch(loadingState(true));
      const res = await userInstance().get(
        `/api/user/getTransactionDetails?skip=${data?.skip}&filterDate=${data?.filterValue}`
      );
      dispatch(loadingState(false));
      // console.log('getTransactionDetails==>>>',res);

      return {
        resultData: res,
        type: data?.type,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const placeOrder = createAsyncThunk(
  "user/placeOrder",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await userInstance().post("/api/user/placeOrder", data);
      // console.log('placeOrder==>>>',data?.skip);
      const { code } = res?.data;
      if (code === 200) {
        //toast.success('Order Placed Successfully');
        dispatch(getLoginUser());
        dispatch(getCartProducts());
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const inventoryPlaceOrder = createAsyncThunk(
  "user/inventoryPlaceOrder",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await userInstance().post(
        "/api/user/inventoryPlaceOrder",
        data
      );
      const { code } = res?.data;
      if (code === 200) {
        dispatch(getLoginUser());
        dispatch(getCartProducts());
        dispatch(getAllInventoryProducts());
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const createBattle = createAsyncThunk(
  "user/createBattle",
  async (data, { rejectWithValue }) => {
    try {
      // const history = useHistory();
      const res = await userInstance().post("/api/user/createBattle", data);
      const { code, msg } = res?.data;
      if (code === 200) {
        toast.success(msg);
        // history.push('/battles')
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getBattles = createAsyncThunk(
  "user/getBattles",
  async (data, { rejectWithValue }) => {
    try {
      //console.log('data==>', data);
      const { userId, sortTye } = data || {};
      const res = await userInstance().get(
        `/api/user/getBattles/${userId}/${sortTye}`
      );
      const { code, msg } = res?.data;
      if (code === 200) {
        toast.success(msg);
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getPlayBattleData = createAsyncThunk(
  "user/getPlayBattleData",
  async (data, { rejectWithValue }) => {
    try {
      const res = await userInstance().get(
        `/api/user/getPlayBattleData/${data}`
      );
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

//products
export const getProductsByCategory = createAsyncThunk(
  "user/getProductsByCategory",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const loadingTarget =
        data?.searchName === "title" ||
        data?.searchName === "min" ||
        data?.searchName === "max";
      // console.log('getCateiiiu', data?.searchName)
      if (!loadingTarget) {
        dispatch(loadingState(true));
      }
      const res = await withoutAuth().post(
        "/api/user/getProductsByCategory",
        data
      );

      dispatch(loadingState(false));

      return {
        resultData: res,
        newType: data.newType,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getProductById = createAsyncThunk(
  "user/getProductById",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      // console.log('getCateiiiu',data)
      dispatch(loadingState(true));
      const res = await withoutAuth().get(
        `/api/user/getProductById?id=${data}`
      );
      const { code } = res.data;
      if (code === 200) {
        dispatch(loadingState(false));
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

//cart
export const addToCart = createAsyncThunk(
  "user/addToCart",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      // console.log('getCateiiiu====>>',data);
      const res = await userInstance().post("/api/user/addToCart", data);
      const { code } = res?.data;
      if (code === 200) {
        dispatch(getCartProducts());
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getCartProducts = createAsyncThunk(
  "user/getCartProducts",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      dispatch(loadingState(true));
      const res = await userInstance().get("/api/user/getCartProducts");
      // const { code } = res.data;
      // console.log('testtststs=>', res.data)
      if (res.data) {
        dispatch(loadingState(false));
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const deleteCartItems = createAsyncThunk(
  "user/deleteCartItems",
  async (data, { rejectWithValue }) => {
    try {
      // console.log('hh',data);
      const res = await userInstance().delete(
        `/api/user/deleteCartItems/${data}`
      );
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const removeAllCartItems = createAsyncThunk(
  "user/removeAllCartItems",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      // console.log('hh',data);
      const res = await userInstance().delete("/api/user/removeAllCartItems");
      const { code } = res?.data;
      if (code === 200) {
        dispatch(getCartProducts());
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const updateCartQuantity = createAsyncThunk(
  "user/updateCartQuantity",
  async (data, { rejectWithValue }) => {
    try {
      // console.log('hh',data);
      const res = await userInstance().put(
        `/api/user/updateCartQuantity`,
        data
      );
      return {
        resultData: res,
      };
    } catch (err) {
      // return rejectWithValue({
      //   msg: err.response.data.msg,
      //   code: err.response.data.code,
      // })
    }
  }
);

/************ battle play event managment here *********** */
export const battlePlayEvent = createAsyncThunk(
  "user/battlePlayEvent",
  async (data, { rejectWithValue }) => {
    try {
      const res = await userInstance().post("/api/user/battlePlayEvent", data);
      const { code, msg } = res?.data;
      if (code === 200 && msg) {
        toast.success(msg);
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getBrand = createAsyncThunk(
  "user/getBrand",
  async (data, { rejectWithValue }) => {
    try {
      // console.log('hh',data);
      const res = await withoutAuth().get(`/api/user/getBrand`);
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

//games

export const getPastGames = createAsyncThunk(
  "user/getPastGames",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      // console.log('hh====.>>-',data);
      dispatch(loadingState(true));
      const res = await userInstance().get(
        `/api/user/getPastGames?skip=${data}`
      );

      dispatch(loadingState(false));

      // console.log('resshgthd',res)
      return {
        resultData: res,
        skipValue:data
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getBattlesHistory = createAsyncThunk(
  "user/getBattlesHistory",
  async (data, { rejectWithValue }) => {
    try {
      const res = await userInstance().get(
        `/api/user/getBattlesHistory/${data}`
      );
      const { code, msg } = res?.data;
      if (code === 200) {
        toast.success(msg);
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const selectedAddressUpdate = createAsyncThunk(
  "user/selectedAddressUpdate",
  async (data, { rejectWithValue }) => {
    try {
      const res = await userInstance().put(
        `/api/user/selectedAddressUpdate/`,
        data
      );
      const { code, msg } = res?.data;
      if (code === 200) {
        toast.success(msg);
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getOrders = createAsyncThunk(
  "user/getOrders",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      dispatch(loadingState(true));
      const res = await userInstance().get(`/api/user/getOrders?skip=${data}`);

      dispatch(loadingState(false));

      return {
        resultData: res,
        skipValue:data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getOrderDetailsByOrderId = createAsyncThunk(
  "user/getOrderDetailsByOrderId",
  async (data, { rejectWithValue }) => {
    try {
      const res = await userInstance().get(
        `/api/user/getOrderDetailsByOrderId/${data}`
      );
      // console.log('getOrderDetailsByOrderId', res);
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getRarity = createAsyncThunk(
  "user/getRarity",
  async (data, { rejectWithValue }) => {
    try {
      const res = await userInstance().get(`/api/user/getRarity`);
      // console.log('getRarity', res);
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const sellUpdateWallet = createAsyncThunk(
  "user/sellUpdateWallet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await userInstance().put(`/api/user/sellUpdateWallet`, data);
      const { code, msg } = res?.data;
      if (code === 200) {
        toast.success('Produits vendus avec succès!', {
          toastId: "doneitemsold",
        });
        dispatch(getDistinctInventoryProducts());
        dispatch(getLoginUser());
      }else{
        return toast.error(msg, {toastId:'walleterrorcode'})
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);
export const verifyGameResult = createAsyncThunk(
  "user/verifyGameResult",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await userInstance().post(`/api/game/verifyGameResult`, data);
      // const { code } = res?.data;
      // if (code === 200) {
      // 	dispatch(getDistinctInventoryProducts())
      // 	dispatch(getLoginUser())
      // }
      // console.log('sellUpdateWallet',res);
      return {
        fairnessResult: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getAllShippingData = createAsyncThunk(
  "user/getAllShippingData",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await userInstance().get(
        `/api/user/getAllShippingData`,
        data
      );

      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const createPayment = createAsyncThunk(
  "user/createPayment",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await userInstance().post(`/api/user/createPayment`, data);
      window.location.href = res?.data?.checkOutUrl;
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

  //Affiliate

  export const getAffiliateUserById = createAsyncThunk(
	"user/getAffiliateUserById",
	async (data, { rejectWithValue }) => {
	  try {
		const res = await userInstance().get(
		  `/api/user/getAffiliateUserById?skip=${data}`
		);
		return {
		  resultData: res,
      skipValue:data
		};
	  } catch (err) {
		return rejectWithValue({
		  msg: err.response.data.msg,
		  code: err.response.data.code,
		});
	  }
	}
  );


export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateCartQty: (state, { payload }) => {
      //console.log('state',state.allCartsProducts)
      const pre = [...state.allCartsProducts];
      if (pre[payload.index].productQuantity + payload.increament < 1) {
        return;
      }
      pre[payload.index].productQuantity += payload.increament;
      state.allCartsProducts = [...pre];
    },
    updateBattle: (state, { payload }) => {
      const pre = [...state.allBattles];
      pre.unshift(payload);
      state.allBattles = [...pre];
    },
    updateAllBattle: (state, { payload }) => {
      //const { _id} = state.userDetails || {}
      // const privateBattle = payload?.filter((el)=>el.createdBy?.toString() === _id?.toString() && el.joinType ==='private');
      // const publicBattle = payload?.filter((el)=> el.joinType ==='public');
      state.allBattles = payload;
      //state.privateBattlesList = privateBattle;
    },
    updateInventory: (state, { payload }) => {
      // console.log('payloaf',payload,state.userInventory)
      const pre = [...state.userInventory];
      const filteredComponent = pre.filter(
        (el) => !payload?.inventoryIds.includes(el?._id)
      );
      // pre.forEach((el)=>{
      // 	if(payload?.inventoryIds.includes(el?._id)){
      // 		el.status = 'requested';
      // 	}
      // })
      state.userInventory = [...filteredComponent];
    },

    removeInventoryItems: (state, { payload }) => {
      // console.log('payloaf===>>>', payload);
      const pre = [...state.userInventory];
      const filteredComponent = pre.filter(
        (el) => !payload?.inventoryId.includes(el?._id)
      );
      // console.log('filteredComponent', filteredComponent);
      state.userInventory = [...filteredComponent];
    },

    updateJoinGameData: (state, { payload }) => {
      state.activBattleData = payload;
    },
    currentRollBoxItemData: (state, { payload }) => {
      const newArrayData = getShuffleArray(payload);
      const arr = [];
      let startIndex = 0;
      for (let i = 0; i < 210; i++) {
        if (newArrayData.length === startIndex) {
          startIndex = 0;
        }
        arr.push(newArrayData[startIndex]);
        startIndex++;
      }
      state.currentBoxItems = arr;
    },
    updateUserData: (state, { payload }) => {
      state.userDetails = payload;
    },
    resetInventory: (state, { payload }) => {
      state.userInventory = [];
      state.pastGames = [];
      state.userTransactions = [];
      state.allOrders = [];
    },

    loadingState: (state, { payload }) => {
      state.loading = payload;
    },

    updateAddressStatus: (state, { payload }) => {
      const copy = JSON.parse(
        JSON.stringify(state.userDetails.shippingAddress)
      );
      copy[payload?.index] = { ...payload.addData };
      copy.forEach((el, i) => {
        if (i !== payload?.index) {
          el.status = false;
        }
      });

      // console.log('After ', copy);
      state.userDetails.shippingAddress = [...copy];
    },
    updateWinItem: (state, { payload }) => {
      state.winItem = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLoginUser.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.userDetails = resultData?.data?.data;
    });
    builder.addCase(getLoginUser.rejected, (state, { payload }) => {});

    builder.addCase(setLanguages.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.lan = resultData;
    });

    builder.addCase(getSingleBoxData.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.boxDetails = resultData?.data?.boxData;
      state.boxProductList = resultData?.data?.produtItem;
    });
    builder.addCase(getSingleBoxData.rejected, (state, { payload }) => {});

    builder.addCase(getAllBoxes.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      const { settings } = resultData?.data?.settings || {};
      const { alluserCount, boxCount, unboxCount } = resultData?.data || {};
      state.boxesList = resultData?.data?.box;
      state.boxprofit = settings?.boxProfit;
      state.adminSetting = settings;
      state.alluserCount = alluserCount;
      state.boxCount = boxCount;
      state.unboxCount = unboxCount;
    });

    builder.addCase(getAllBoxes.rejected, (state, { payload }) => {});

    builder.addCase(getInventoryProducts.fulfilled, (state, { payload }) => {
      const { resultData, newType } = payload || {};
      if (newType) {
        state.userInventory = [
          ...state.userInventory,
          ...resultData?.data?.userInventory,
        ];
      } else {
        state.userInventory = resultData?.data?.userInventory;
      }
      state.totalInventoryCost = resultData?.data?.totalInventoryCost;
      state.totalInventoryCounts = resultData?.data?.totalInventoryCounts;
      // state.adminSetting = resultData?.data?.settings;
      // state.productTotal =  resultData?.data?.countProd
    });
    builder.addCase(getInventoryProducts.rejected, (state, { payload }) => {});

    builder.addCase(getAllInventoryProducts.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.allInventoryItems = resultData?.data?.allInventoryItems;
    });
    builder.addCase(
      getAllInventoryProducts.rejected,
      (state, { payload }) => {}
    );

    builder.addCase(
      getDistinctInventoryProducts.fulfilled,
      (state, { payload }) => {
        const { resultData } = payload || {};
        state.distinctInventProd = resultData?.data?.distinctInventProd;
      }
    );
    builder.addCase(
      getDistinctInventoryProducts.rejected,
      (state, { payload }) => {}
    );

    builder.addCase(getInventoryCounts.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.inventoryTotal = resultData?.data?.inventoryTotal;
    });
    builder.addCase(getInventoryCounts.rejected, (state, { payload }) => {});

    builder.addCase(getTransactionDetails.fulfilled, (state, { payload }) => {
      const { resultData, type } = payload || {};
      console.log("here is result data")
      if (!type) {
        state.userTransactions = resultData?.data?.transactions;
      } else {
        state.userTransactions = [
          ...state.userTransactions,
          ...resultData?.data?.transactions,
        ];
      }
      state.totalTransaction = resultData?.data?.totalTransaction;
    });
    builder.addCase(getTransactionDetails.rejected, (state, { payload }) => {});

    builder.addCase(checkTokenExpiry.fulfilled, (state, { payload }) => {});
    builder.addCase(checkTokenExpiry.rejected, (state, { payload }) => {});

    builder.addCase(
      getAllCategorySelectList.fulfilled,
      (state, { payload }) => {
        state.productCategories = payload?.productCategories;
        state.boxCategories = payload?.boxCategories;
      }
    );
    builder.addCase(
      getAllCategorySelectList.rejected,
      (state, { payload }) => {}
    );
    builder.addCase(getAllBoxesByCat.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.boxListByCategory = resultData?.data?.box;
      state.boxSpecialtags = resultData?.data?.specialTagList;
      state.minVal = resultData?.data?.minVal;
      state.maxVal = resultData?.data?.maxVal;
    });
    builder.addCase(getAllBoxesByCat.rejected, (state, { payload }) => {});

    builder.addCase(getBoxesByProductId.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.productBoxes = resultData?.data?.productBoxes;
    });
    builder.addCase(getBoxesByProductId.rejected, (state, { payload }) => {});

    builder.addCase(getTopItemsUnboxed.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.topItemsUnboxed = resultData?.data?.topItemsUnboxed;
    });
    builder.addCase(getTopItemsUnboxed.rejected, (state, { payload }) => {});

    builder.addCase(getGameResult.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};

      state.winItem = resultData?.data?.winResult;
      //console.log("result data in user ", resultData?.data?.winResult)
      state.toTalearnMoney = resultData?.data?.toTalearnMoney;
    });
    builder.addCase(getGameResult.rejected, (state, { payload }) => {});

    builder.addCase(getLiveDrops.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.liveDrops = resultData?.data?.liveDrops;
    });
    builder.addCase(getLiveDrops.rejected, (state, { payload }) => {});
    builder.addCase(addToInventory.fulfilled, (state, { payload }) => {
      //const { resultData } = payload || {}
      //console.log('resultData', resultData)
    });
    builder.addCase(addToInventory.rejected, (state, { payload }) => {});

    builder.addCase(forgetPass.fulfilled, (state, { payload }) => {});
    builder.addCase(forgetPass.rejected, (state, { payload }) => {});

    builder.addCase(resetPass.fulfilled, (state, { payload }) => {});
    builder.addCase(resetPass.rejected, (state, { payload }) => {});

    builder.addCase(updateUserWallet.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      const { userWallet } = resultData?.data;
      state.userDetails.walletAmount = userWallet;
    });
    builder.addCase(updateUserWallet.rejected, (state, { payload }) => {});

    builder.addCase(incrementWalletAmount.fulfilled, (state, { payload }) => {
      // const { resultData } = payload || {}
    });
    builder.addCase(incrementWalletAmount.rejected, (state, { payload }) => {});

    builder.addCase(withDrawAmount.fulfilled, (state, { payload }) => {
      // const { resultData } = payload || {}
    });
    builder.addCase(withDrawAmount.rejected, (state, { payload }) => {});

    builder.addCase(
      dispatchInventoryProducts.fulfilled,
      (state, { payload }) => {
        // const { resultData } = payload || {}
      }
    );
    builder.addCase(
      dispatchInventoryProducts.rejected,
      (state, { payload }) => {}
    );

    builder.addCase(placeOrder.fulfilled, (state, { payload }) => {
      // const { resultData } = payload || {}
    });
    builder.addCase(placeOrder.rejected, (state, { payload }) => {});

    builder.addCase(createBattle.fulfilled, (state, { payload }) => {
      // const { resultData } = payload || {}
    });
    builder.addCase(createBattle.rejected, (state, { payload }) => {});

    builder.addCase(getBattles.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.allBattles = resultData?.data.allBattles;
      state.privateBattlesList = resultData?.data.privateBattles;
    });
    builder.addCase(getBattles.rejected, (state, { payload }) => {});
    builder.addCase(getPlayBattleData.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.activBattleData = resultData?.data.battleData;
    });
    builder.addCase(getPlayBattleData.rejected, (state, { payload }) => {});

    builder.addCase(getProductsByCategory.fulfilled, (state, { payload }) => {
      const { resultData, newType } = payload || {};
      if (newType) {
        state.prodListByCategory = [
          ...state.prodListByCategory,
          ...resultData?.data?.box,
        ];
      } else {
        state.prodListByCategory = resultData?.data?.box;
      }
      state.productTotal = resultData?.data?.countProd;
    });
    builder.addCase(getProductsByCategory.rejected, (state, { payload }) => {});

    builder.addCase(getProductById.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.productDetails = resultData?.data?.productDetails;
    });
    builder.addCase(getProductById.rejected, (state, { payload }) => {});

    builder.addCase(addToCart.fulfilled, (state, { payload }) => {
      // const { resultData } = payload || {}
      // const {code,msg} = payload?.resultData?.data;
      // if(code === 200){
      //   toast.success(msg,{toastId:'cart'})
      // }
      // state.productDetails = resultData?.data?.productDetails
    });
    builder.addCase(addToCart.rejected, (state, { payload }) => {});

    builder.addCase(getCartProducts.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.allCartsProducts = resultData?.data?.allCartsProducts;
    });
    builder.addCase(getCartProducts.rejected, (state, { payload }) => {});

    builder.addCase(deleteCartItems.fulfilled, (state, { payload }) => {});
    builder.addCase(deleteCartItems.rejected, (state, { payload }) => {});

    builder.addCase(getBrand.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.allBrands = resultData?.data?.brand;
    });
    builder.addCase(getBrand.rejected, (state, { payload }) => {});

    builder.addCase(battlePlayEvent.fulfilled, (state, { payload }) => {
      //const { resultData } = payload || {}
      // state.allCartsProducts = resultData?.data?.allCartsProducts
    });
    builder.addCase(battlePlayEvent.rejected, (state, { payload }) => {});

    builder.addCase(getPastGames.fulfilled, (state, { payload }) => {
      const { resultData,skipValue } = payload || {};
      if(skipValue > 0){
      state.pastGames = [...state.pastGames, ...resultData?.data?.pastGames];
      }else{
        state.pastGames = [...resultData?.data?.pastGames];
      }
      state.gamesCount = resultData?.data?.gamesCount;
    });
    builder.addCase(getPastGames.rejected, (state, { payload }) => {});
    builder.addCase(getBattlesHistory.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.allBattleHistory = resultData?.data?.list;
    });
    builder.addCase(getBattlesHistory.rejected, (state, { payload }) => {});

    //orders
    builder.addCase(getOrders.fulfilled, (state, { payload }) => {
      const { resultData, skipValue} = payload || {};
      if(skipValue>0){  
      state.allOrders = [...state?.allOrders, ...resultData?.data?.allOrders];
      }else{
        state.allOrders = [...resultData?.data?.allOrders];
      }
      state.ordersCount = resultData?.data?.ordersCount;
    });
    builder.addCase(getOrders.rejected, (state, { payload }) => {});

    builder.addCase(
      getOrderDetailsByOrderId.fulfilled,
      (state, { payload }) => {
        const { resultData } = payload || {};
        state.orderDetails = resultData?.data?.fullDetails;
      }
    );
    builder.addCase(
      getOrderDetailsByOrderId.rejected,
      (state, { payload }) => {}
    );

    builder.addCase(getRarity.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.allRarity = resultData?.data?.rarity;
    });
    builder.addCase(getRarity.rejected, (state, { payload }) => {});
    builder.addCase(verifyGameResult.fulfilled, (state, { payload }) => {
      const { fairnessResult } = payload || {};
      state.fairnessResult = fairnessResult?.data?.resultIndex;
      state.verifyResult = fairnessResult?.data?.result;
    });
    builder.addCase(verifyGameResult.rejected, (state, { payload }) => {});

    builder.addCase(getAllShippingData.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.allShippingMethods = resultData?.data?.shippingData;
    });
    builder.addCase(getAllShippingData.rejected, (state, { payload }) => {});

	
    builder.addCase(getAffiliateUserById.fulfilled, (state, { payload }) => {
		const { resultData,skipValue } = payload || {};
		state.refereeUserList = skipValue > 0 ? [...state.refereeUserList,...resultData?.data?.refereeUserList]  : resultData?.data?.refereeUserList;  
    state.referUsersCount = resultData?.data?.referUsersCount;
    state.totalCountThisMonth = resultData?.data?.totalCountThisMonth;
    state.totalAmountThisMonth = resultData?.data?.totalAmountThisMonth;
    state.totalCountLastMonth = resultData?.data?.totalCountLastMonth;
    state.totalAmountLastMonth = resultData?.data?.totalAmountLastMonth;
	  });
	  builder.addCase(getAffiliateUserById.rejected, (state, { payload }) => {});


  },
});

export const {
  updateCartQty,
  updateBattle,
  updateAllBattle,
  updateInventory,
  removeInventoryItems,
  updateJoinGameData,
  currentRollBoxItemData,
  updateUserData,
  resetInventory,
  updateAddressStatus,
  loadingState,
  updateWinItem,
} = userSlice.actions;

// Action creators are generated for each case reducer function
export default userSlice.reducer;
