import React, { useState } from "react";
import Layout from "../layout/layout";
import BoxAddedPopUp from "../PopUp/BoxAddedPopUp";
import { handleBoxPrice } from "../../utils/useFunc";
import Players from "./players";
import BattleHeader from "./battleHeader";
import { useDispatch, useSelector } from "react-redux";
import { createBattle, updateUserWallet } from "../../reducers/userReducer";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import BattlePassword from "./battlePassword";
import { validatePassWord } from "../../utils/index";
import { includeToFixed } from "../../utils/useFunc";
import { Helmet } from "react-helmet";
const CreateBattles = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const result = useSelector((state) => state.user);
  const { userDetails } = result || {};
  const { walletAmount } = userDetails || {};
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  //console.log('userr==>', walletAmount)
  const initialObj = {
    // name:'',
    userId: "",
    round: 0,
    selectedBoxes: [],
    players: "2",
    totalbattleCost: 0,
    joinType: "public",
    battlePassword: "",
    battleConfirmPass: "",
    selectedNewBoxes: [],
  };
  const [battleObj, setBattleObj] = useState(initialObj);
  const [show, setShow] = useState(false);
  const [total, setTotal] = useState({
    price: 0,
    round: 0,
  });
  const handleActive = (e) => {
    setBattleObj({ ...battleObj, players: e });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };
  const calculating = (data) => {
    const newObj = data.reduce(
      (prev, next) => {
        prev["price"] = prev["price"] + parseFloat(next["price"]);
        prev["round"] = prev["round"] + next["round"];
        return prev;
      },
      { price: 0, round: 0 }
    );
    // console.log('newObj', newObj)
    setTotal(newObj);
    return newObj;
    // setBattleObj({...battleObj,totalbattleCost:newObj?.price,round:newObj?.round})
  };

  const addBoxes = (data, boxProfit) => {
    // console.log('okkk')
    const ss = battleObj?.selectedBoxes.slice();
    const boxPrice = handleBoxPrice(data?.boxItems, boxProfit);
    const fIndex = ss.findIndex((el) => el.box._id === data._id);
    if (fIndex === -1) {
      const obj = {
        box: "",
        round: "",
        price: "",
      };
      obj.box = data;
      obj.round = 1;
      obj.price = boxPrice;
      ss.push(obj);
    } else {
      let newprice = boxPrice * ss[fIndex].round;
      ss[fIndex].round += 1;
      ss[fIndex].price = includeToFixed(newprice);
    }

    // console.log('fIndex', fIndex, ss)
    const newObj = calculating(ss);

    setBattleObj({
      ...battleObj,
      selectedBoxes: ss,
      totalbattleCost: newObj?.price,
      round: newObj?.round,
    });
  };

  const handleCount = (data, type, profit) => {
    // console.log('couuu')
    const ss = battleObj?.selectedBoxes.slice();
    const boxPrice = handleBoxPrice(data?.boxItems, profit);
    const fIndex = ss.findIndex((el) => el?.box?._id === data?._id);
    if (fIndex >= 0) {
      if (ss[fIndex].round >= 1) {
        type === "minus"
          ? ss[fIndex].round !== 1
            ? (ss[fIndex].round -= 1)
            : (ss[fIndex].round = 1)
          : (ss[fIndex].round += 1);
      }
      let newprices = boxPrice * ss[fIndex].round;
      ss[fIndex].price = includeToFixed(newprices);
    }
    const newObj = calculating(ss);
    setBattleObj({
      ...battleObj,
      selectedBoxes: ss,
      totalbattleCost: newObj?.price,
      round: newObj?.round,
    });
  };
  const handleChange = (e) => {
    const type = battleObj.joinType === "public" ? "private" : "public";
    setBattleObj({ ...battleObj, joinType: type });
  };
  const handleCreateBattle = () => {
    //console.log('battleObj.selectedBoxes=>', battleObj.selectedBoxes)
    const { totalbattleCost, joinType } = battleObj || {};
    const copySelectedBox = battleObj.selectedBoxes.slice();
    const newData = copySelectedBox.map((el) => ({
      boxId: el?.box?._id,
      image: el?.box?.image,
      boxName: el?.box?.name,
      price: el?.price,
      round: el?.round,
    }));
    battleObj.selectedNewBoxes = newData;
    if (newData.length === 0) {
      return toast.success(t("toast.battlesuccess"));
    }
    if (parseFloat(totalbattleCost) > parseFloat(walletAmount)) {
      return toast.error(t("toast.battleerror"), {
        toastId: "battles",
      });
    }
    if (joinType === "private") {
      const payload = {
        password: battleObj?.battlePassword,
        confirmPassword: battleObj?.battleConfirmPass,
      };
      const validData = validatePassWord(payload);
      if (!validData.isValid) {
        toast.error("Please add valid password!", {
          toastId: "battlespass",
        });
        handleBattlePassOpen();
        return;
      }
    }
    //console.log('battleObj=>', battleObj);
    dispatch(createBattle(battleObj));
    const payload = {
      userId: userDetails?._id,
      amount: parseFloat(includeToFixed(totalbattleCost)),
      rollType: "1",
      boxId: "",
      debitCreditType: "debit",
      paymentType: "wallet",
      description: "Create Battle",
      descriptionMessage: "Deduct From Your wallet ",
    };
    dispatch(updateUserWallet(payload));
    history.push("/battles");
  };

  const handleCross = (id) => {
    // console.log('cross')
    const ss = battleObj?.selectedBoxes.slice();
    const fIndex = ss.findIndex((el) => el?.box?._id === id);
    if (fIndex >= 0) {
      ss.splice(fIndex, 1);
    }
    const newObj = calculating(ss);
    setBattleObj({
      ...battleObj,
      selectedBoxes: ss,
      totalbattleCost: newObj?.price,
      round: newObj?.round,
    });
  };
  const handleBattlePassOpen = () => {
    setShow(!show);
  };
  const handleChangePass = (e) => {
    const { name, value } = e.target;
    setBattleObj({ ...battleObj, [name]: value });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const payload = {
      password: battleObj?.battlePassword,
      confirmPassword: battleObj?.battleConfirmPass,
    };
    const validData = validatePassWord(payload);
    setErrors(validData.errors);
    if (!validData.isValid) {
      return;
    }
    setShow(false);
  };
  return (
    <>
      <Helmet>
        <meta property='og:title' content='Create Battle | BOXI' />
        <title>Create Battle | BOXI</title>
      </Helmet>
      <Layout>
        <div className='battles-page all-battles-listing'>
          <div className='container '>
            <section className='position-relative'>
              <div className='battles-section'>
                <div className='row battles-top-header'>
                  <div className='col-lg-12'>
                    <h1 class='section-title'>
                      <span class='section-title-arrow-left'></span>
                      <span class='section-title-text'>
                        {t("openBoxes.createBattleBtn")}
                      </span>
                      <span class='section-title-arrow-right'></span>
                    </h1>
                  </div>
                </div>
                <div className='row py-4 justify-content-between'>
                  <div className='col-lg-6 col-md-12 header-content'>
                    <span>{t("header.battles")}</span>
                    <i class='las la-dice'></i>
                    <span className='fairness'>
                      {t("openBoxes.createBattleBtn")}
                    </span>
                  </div>
                  <div className='col-lg-6 col-md-12 date-price'>
                    <label className='date'>{t("openBoxes.pubBtlTxt")}</label>
                    <label class='switch'>
                      <input type='checkbox' onChange={handleChange} />
                      <span class='slider round'></span>
                    </label>
                    <label className='price'>{t("openBoxes.priBtlTxt")}</label>
                  </div>
                </div>
              </div>

              <div className='battle-listing-section'>
                <BattleHeader total={total} battleObj={battleObj} />
                <div className='row p-5'>
                  {battleObj?.selectedBoxes.map((el) => (
                    <div className='col-lg-2 col-md-3 p-1'>
                      <div className='battle-card'>
                        <div className='contest-card__thumb'>
                          {/* <a href='#0' class='action-icon'>
                          <i class='far fa-heart'></i>
                        </a> */}
                          <i
                            class='las la-times'
                            onClick={() => handleCross(el.box._id)}></i>
                          <img src={el?.box?.image} alt='box' />
                        </div>
                        <div className='increment-price'>
                          <div className='price'>
                            <p>
                              Price <span>{el?.price}Dt</span>
                            </p>
                          </div>
                          <div className='increment'>
                            <span
                              onClick={() =>
                                handleCount(
                                  el?.box,
                                  "minus",
                                  el?.box?.profitMargin
                                )
                              }>
                              <i class='las la-minus-circle'></i>
                            </span>
                            <span className='text-center'>{el.round}</span>
                            <span
                              onClick={() =>
                                handleCount(
                                  el?.box,
                                  "plus",
                                  el?.box?.profitMargin
                                )
                              }>
                              <i class='las la-plus-circle'></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className='col-lg-2 col-md-3 p-1' onClick={handleOpen}>
                    <div className='battle-box'>
                      <div className='add-case'>
                        <div class='cirlce-section'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            version='1.1'
                            class=''
                            viewBox='0 1 448 515'>
                            <path d='M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z'></path>
                          </svg>
                          <span>
                            <i class='las la-plus'></i>
                          </span>
                        </div>
                        <div>
                          <h6>{t("openBoxes.addCaseTxt")}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Players
                handleActive={handleActive}
                battleObj={battleObj}
                handleCreateBattle={handleCreateBattle}
              />
            </section>
          </div>
        </div>
        <BoxAddedPopUp
          open={open}
          onCloseModal={onCloseModal}
          addBoxes={addBoxes}
        />
        <BattlePassword
          show={show}
          handleBattlePassOpen={handleBattlePassOpen}
          handleChangePass={handleChangePass}
          battleObj={battleObj}
          setShow={setShow}
          onSubmitHandler={onSubmitHandler}
          errors={errors}
        />
      </Layout>
    </>
  );
};

export default CreateBattles;
