import React from "react";
import { Button } from "react-bootstrap";
import ConfirmationPopUp from "../PopUp/ConfirmationPopUp";
import closeicon from "../../assets/images/game/close.png";
import item2 from "../../assets/images/home/2.jpg";
import { includeToFixed } from "../../utils/useFunc";
import img2 from "../../assets/images/ttttt.png";
import img3 from "../../assets/images/ttttt-right.png";
import { useTranslation } from "react-i18next";

const Winner = ({
  handleClose,
  winItem,
  handleAddInventory,
  handleQuickSellPercentage,
  openModal,
  handleConfirm,
  onCloseModal,
  handleProduct,
  toggleImg,
  isSelll,
}) => {
  const { bannerImage, name, price, quickSellPercentage, quickSell } =
    winItem[0]?.pid || {};
  const newearnMoney = quickSell
    ? price - (price * parseFloat(quickSellPercentage)) / 100
    : price;
  const earnMoney = includeToFixed(newearnMoney);
  const { t } = useTranslation();

  return (
    <div className="winner-popup">
      <div className="animted-images">
        <img src={img3} className="left-img" alt="img1" />
        <img src={img2} className="right-img" alt="img2" />
      </div>
      <div className="win-grid-container wow animate__animated animate__fadeIn">
        <div className="win-close-icon" onClick={() => handleClose("close")}>
          <img src={closeicon} alt="" />
        </div>
        <div className="win-grid wow animate__animated animate__zoomIn">
          <div className="win-grid-bix">
            <img
              src={bannerImage || item2}
              alt=""
              onClick={() => handleProduct(winItem[0])}
            />
            <div class="middle">
              <i class="las la-search"></i>
            </div>
          </div>
          <div className="win-btn">
            {/* <h3>{price?.toFixed(2)} DT</h3> */}
            <h3>{name || ""}</h3>
            <Button
              className="quick-sell btn-style"
              onClick={() =>
                handleQuickSellPercentage(earnMoney, winItem[0]?.gameId)
              }
            >
              <span>
                {" "}
                {t("boxDetails.winnerBtnTxt1")} {earnMoney} DT
              </span>
            </Button>
            {/* <Button className="roll-btn" onClick={() => handleClose("close")}>
              NEW ROLL
            </Button> */}
            <Button className="roll-btn" onClick={() => handleClose("close")}>
              {t("boxDetails.winnerBtnTxt2")}
            </Button>
          </div>
        </div>
      </div>
      <ConfirmationPopUp
        open={openModal}
        handleConfirm={handleConfirm}
        onCloseModal={onCloseModal}
        isSelll={isSelll}
      />
    </div>
  );
};
export default Winner;
