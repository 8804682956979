/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import box1 from "../../assets/images/home/1.jpg";
// import { Link } from "react-router-dom";
import "./unboxing.css";
import { useTranslation } from "react-i18next";
import { handleBoxPrice } from "../../utils/useFunc";

const Boxes = ({ boxes }) => {
  //console.log('boxes',boxes)

  const { t } = useTranslation();
  // const history = useHistory();
  // const handleLinkClick = (id) => {
  //   history.push(`/boxdetails?id=${id}`)
  // }
  return (
    <>
      <div className='row wow fadeInUp' data-wow-duration='0.5s' data-wow-delay='0.3s'>
        <div className='col-lg-12'>
          <div className='row mb-none-30'>
            {boxes &&
              boxes.map((box, i) => {
                const { boxItems, image, profitMargin } = box || {};
                const prices = handleBoxPrice(boxItems, profitMargin);
                return (
                  <>
                    {parseFloat(prices) > 0 ? (
                      <div key={i} className='col-xl-4 col-md-6 mb-30'>
                        <div className='contest-card'>
                          <div className='contest-card__thumb'>
                            <a href={`/boxdetails?id=${box._id}`}>
                              <img src={image || box1} alt='box' />
                            </a>

                            {/* <a href='#0' className='action-icon'>
                          <i className='far fa-heart'></i>
                        </a> */}
                            <div
                              className='contest-num'
                              style={{
                                background: `${box?.color}`,
                              }}>
                              {/* <span>{t('openBoxes.boxNoTitle')}</span> */}
                              <h4 className='number'>{box?.specialTag || ""}</h4>
                            </div>
                          </div>
                          <div className='contest-card__content'>
                            <div className='left'>
                              {/* <h5 className="contest-card__name">{t("openBoxes.boxName")}</h5> */}
                              <h5 className='contest-card__name'>{box.name || ""}</h5>
                            </div>
                            <div className='right'>
                              <span className='contest-card__price'>{prices} DT</span>
                              {/* <p>{t('openBoxes.boxPriceTitle')}</p> */}
                              <p>{t("openBoxes.boxPriceTitle")}</p>
                            </div>
                          </div>
                          <div className='contest-card__footer'>
                            {/* <ul className="contest-card__meta">
                    <li>
                      <i className="las la-clock"></i>
                      <span>5d</span>
                    </li>
                    <li>
                      <i className="las la-ticket-alt"></i>
                      <span>9805</span>
                      <p>Remaining</p>
                    </li>
                  </ul> */}
                            {/* <Link to="/boxdetails" className="cmn-btn details-btn style--three btn--sm">
                  <i class="fas fa-box-open"></i> {t("openBoxes.openboxBtn")}
                  </Link> */}
                            <a
                              href={`/boxdetails?id=${box._id}`}
                              className='cmn-btn details-btn style--three btn--sm btn-style'>
                              <span>
                                <i class='fas fa-box-open'></i>
                                {t("openBoxes.openboxBtn")}
                              </span>
                            </a>
                          </div>
                        </div>

                        {/* <div className="col-xl-4 col-md-6 mb-30">
              <div className="contest-card">
                <Link to="/boxdetails" className="item-link"></Link>
                <div className="contest-card__thumb">
                  <img src={box2} alt="box" />
                  <a href="#0" className="action-icon">
                    <i className="far fa-heart"></i>
                  </a>
                  <div className="contest-num">
                    <span>contest no:</span>
                    <h4 className="number">x9u</h4>
                  </div>
                </div>
                <div className="contest-card__content">
                  <div className="left">
                    <h5 className="contest-card__name">
                      The Del Sol Trailblazer
                    </h5>
                  </div>
                  <div className="right">
                    <span className="contest-card__price">$3.99</span>
                    <p>ticket price</p>
                  </div>
                </div>
                <div className="contest-card__footer">
                  {/* <ul className="contest-card__meta">
                    <li>
                      <i className="las la-clock"></i>
                      <span>5d</span>
                    </li>
                    <li>
                      <i className="las la-ticket-alt"></i>
                      <span>9805</span>
                      <p>Remaining</p>
                    </li>
                  </ul> */}
                        {/*<Link to="/boxdetails" className="cmn-btn details-btn style--three btn--sm">
                  <i class="fas fa-box-open"></i> Open Box
                  </Link>
                </div>
              </div>
            </div> */}
                        {/* <div className="col-xl-4 col-md-6 mb-30">
              <div className="contest-card">
                <Link to="/boxdetails" className="item-link"></Link>
                <div className="contest-card__thumb">
                  <img src={box8} alt="box" />
                  <a href="#0" className="action-icon">
                    <i className="far fa-heart"></i>
                  </a>
                  <div className="contest-num">
                    <span>contest no:</span>
                    <h4 className="number">8y3</h4>
                  </div>
                </div>
                <div className="contest-card__content">
                  <div className="left">
                    <h5 className="contest-card__name">The Miata Dart IV</h5>
                  </div>
                  <div className="right">
                    <span className="contest-card__price">$3.99</span>
                    <p>ticket price</p>
                  </div>
                </div>
                <div className="contest-card__footer">
                  {/* <ul className="contest-card__meta">
                    <li>
                      <i className="las la-clock"></i>
                      <span>5d</span>
                    </li>
                    <li>
                      <i className="las la-ticket-alt"></i>
                      <span>9805</span>
                      <p>Remaining</p>
                    </li>
                  </ul> */}
                        {/* <Link to="/boxdetails" className="cmn-btn details-btn style--three btn--sm">
                  <i class="fas fa-box-open"></i> Open Box
                  </Link>
                </div>
              </div>
            </div> */}
                        {/* <div className="col-xl-4 col-md-6 mb-30">
              <div className="contest-card">
                <Link to="/" className="item-link"></Link>
                <div className="contest-card__thumb">
                  <img src={box4} alt="box" />
                  <a href="#0" className="action-icon">
                    <i className="far fa-heart"></i>
                  </a>
                  <div className="contest-num">
                    <span>contest no:</span>
                    <h4 className="number">r9d</h4>
                  </div>
                </div>
                <div className="contest-card__content">
                  <div className="left">
                    <h5 className="contest-card__name">The Fabia Magnum</h5>
                  </div>
                  <div className="right">
                    <span className="contest-card__price">$3.99</span>
                    <p>ticket price</p>
                  </div>
                </div>
                <div className="contest-card__footer">
                  {/* <ul className="contest-card__meta">
                    <li>
                      <i className="las la-clock"></i>
                      <span>5d</span>
                    </li>
                    <li>
                      <i className="las la-ticket-alt"></i>
                      <span>9805</span>
                      <p>Remaining</p>
                    </li>
                  </ul> */}
                        {/* <Link to="/boxdetails" className="cmn-btn details-btn style--three btn--sm">
                  <i class="fas fa-box-open"></i> Open Box
                  </Link>
                </div>
              </div>
            </div> */}
                        {/* <div className="col-xl-4 col-md-6 mb-30">
              <div className="contest-card">
                <Link to="/boxdetails" className="item-link"></Link>
                <div className="contest-card__thumb">
                  <img src={box5} alt="box" />
                  <a href="#0" className="action-icon">
                    <i className="far fa-heart"></i>
                  </a>
                  <div className="contest-num">
                    <span>contest no:</span>
                    <h4 className="number">pr2</h4>
                  </div>
                </div>
                <div className="contest-card__content">
                  <div className="left">
                    <h5 className="contest-card__name">The Omega Navigator</h5>
                  </div>
                  <div className="right">
                    <span className="contest-card__price">$3.99</span>
                    <p>ticket price</p>
                  </div>
                </div>
                <div className="contest-card__footer">
                  {/* <ul className="contest-card__meta">
                    <li>
                      <i className="las la-clock"></i>
                      <span>5d</span>
                    </li>
                    <li>
                      <i className="las la-ticket-alt"></i>
                      <span>9805</span>
                      <p>Remaining</p>
                    </li>
                  </ul> */}
                        {/*<Link to="/boxdetails" className="cmn-btn details-btn style--three btn--sm">
                  <i class="fas fa-box-open"></i> Open Box
                  </Link>
                </div>
              </div>
            </div> */}
                        {/* <div className="col-xl-4 col-md-6 mb-30">
              <div className="contest-card">
                <Link to="/boxdetails" className="item-link"></Link>
                <div className="contest-card__thumb">
                  <img src={box6} alt="box" />
                  <a href="#0" className="action-icon">
                    <i className="far fa-heart"></i>
                  </a>
                  <div className="contest-num">
                    <span>contest no:</span>
                    <h4 className="number">w03</h4>
                  </div>
                </div>
                <div className="contest-card__content">
                  <div className="left">
                    <h5 className="contest-card__name">Shelby Cobra</h5>
                  </div>
                  <div className="right">
                    <span className="contest-card__price">$3.99</span>
                    <p>ticket price</p>
                  </div>
                </div>
                <div className="contest-card__footer">
                  {/* <ul className="contest-card__meta">
                    <li>
                      <i className="las la-clock"></i>
                      <span>5d</span>
                    </li>
                    <li>
                      <i className="las la-ticket-alt"></i>
                      <span>9805</span>
                      <p>Remaining</p>
                    </li>
                  </ul> */}
                        {/* <Link to="/boxdetails" className="cmn-btn details-btn style--three btn--sm">
                  <i class="fas fa-box-open"></i> Open Box
                  </Link>
                </div>
              </div>
            </div> */}
                        {/* <div className="col-xl-4 col-md-6 mb-30">
              <div className="contest-card">
                <Link to="/boxdetails" className="item-link"></Link>
                <div className="contest-card__thumb">
                  <img src={box1} alt="box" />
                  <a href="#0" className="action-icon">
                    <i className="far fa-heart"></i>
                  </a>
                  <div className="contest-num">
                    <span>contest no:</span>
                    <h4 className="number">b2t</h4>
                  </div>
                </div>
                <div className="contest-card__content">
                  <div className="left">
                    <h5 className="contest-card__name">The Breeze Zodiac IX</h5>
                  </div>
                  <div className="right">
                    <span className="contest-card__price">$3.99</span>
                    <p>ticket price</p>
                  </div>
                </div>
                <div className="contest-card__footer">
                  {/* <ul className="contest-card__meta">
                    <li>
                      <i className="las la-clock"></i>
                      <span>5d</span>
                    </li>
                    <li>
                      <i className="las la-ticket-alt"></i>
                      <span>9805</span>
                      <p>Remaining</p>
                    </li>
                  </ul> */}
                        {/* <Link to="/boxdetails" className="cmn-btn details-btn style--three btn--sm">
                  <i class="fas fa-box-open"></i> Open Box
                  </Link>
                </div>
              </div>
            </div> */}
                        {/* <div className="col-xl-4 col-md-6 mb-30">
              <div className="contest-card">
                <Link to="/boxdetails" className="item-link"></Link>
                <div className="contest-card__thumb">
                  <img src={box2} alt="box" />
                  <a href="#0" className="action-icon">
                    <i className="far fa-heart"></i>
                  </a>
                  <div className="contest-num">
                    <span>contest no:</span>
                    <h4 className="number">x9u</h4>
                  </div>
                </div>
                <div className="contest-card__content">
                  <div className="left">
                    <h5 className="contest-card__name">
                      The Del Sol Trailblazer
                    </h5>
                  </div>
                  <div className="right">
                    <span className="contest-card__price">$3.99</span>
                    <p>ticket price</p>
                  </div>
                </div>
                <div className="contest-card__footer">
                  {/* <ul className="contest-card__meta">
                    <li>
                      <i className="las la-clock"></i>
                      <span>5d</span>
                    </li>
                    <li>
                      <i className="las la-ticket-alt"></i>
                      <span>9805</span>
                      <p>Remaining</p>
                    </li>
                  </ul> */}
                        {/* <Link to="/boxdetails" className="cmn-btn details-btn style--three btn--sm">
                  <i class="fas fa-box-open"></i> Open Box
                  </Link>
                </div>
              </div>
            </div> */}
                        {/* <div className="col-xl-4 col-md-6 mb-30">
              <div className="contest-card">
                <Link to="/boxdetails" className="item-link"></Link>
                <div className="contest-card__thumb">
                  <img src={box8} alt="box" />
                  <a href="#0" className="action-icon">
                    <i className="far fa-heart"></i>
                  </a>
                  <div className="contest-num">
                    <span>contest no:</span>
                    <h4 className="number">8y3</h4>
                  </div>
                </div>
                <div className="contest-card__content">
                  <div className="left">
                    <h5 className="contest-card__name">The Miata Dart IV</h5>
                  </div>
                  <div className="right">
                    <span className="contest-card__price">$3.99</span>
                    <p>ticket price</p>
                  </div>
                </div>
                <div className="contest-card__footer">
                  {/* <ul className="contest-card__meta">
                    <li>
                      <i className="las la-clock"></i>
                      <span>5d</span>
                    </li>
                    <li>
                      <i className="las la-ticket-alt"></i>
                      <span>9805</span>
                      <p>Remaining</p>
                    </li>
                  </ul> */}
                        {/*<Link to="/boxdetails" className="cmn-btn details-btn style--three btn--sm">
                  <i class="fas fa-box-open"></i> Open Box
                  </Link>
                </div>
              </div>
            </div> */}
                        {/* <div className="col-xl-4 col-md-6 mb-30">
              <div className="contest-card">
                <Link to="/boxdetails" className="item-link"></Link>
                <div className="contest-card__thumb">
                  <img src={box4} alt="box" />
                  <a href="#0" className="action-icon">
                    <i className="far fa-heart"></i>
                  </a>
                  <div className="contest-num">
                    <span>contest no:</span>
                    <h4 className="number">r9d</h4>
                  </div>
                </div>
                <div className="contest-card__content">
                  <div className="left">
                    <h5 className="contest-card__name">The Fabia Magnum</h5>
                  </div>
                  <div className="right">
                    <span className="contest-card__price">$3.99</span>
                    <p>ticket price</p>
                  </div>
                </div>
                <div className="contest-card__footer">
                  {/* <ul className="contest-card__meta">
                    <li>
                      <i className="las la-clock"></i>
                      <span>5d</span>
                    </li>
                    <li>
                      <i className="las la-ticket-alt"></i>
                      <span>9805</span>
                      <p>Remaining</p>
                    </li>
                  </ul> */}
                        {/*<Link to="/boxdetails" className="cmn-btn details-btn style--three btn--sm">
                  <i class="fas fa-box-open"></i> Open Box
                  </Link>
                </div>
              </div>
            </div> */}
                        {/* <div className="col-xl-4 col-md-6 mb-30">
              <div className="contest-card">
                <Link to="/boxdetails" className="item-link"></Link>
                <div className="contest-card__thumb">
                  <img src={box5} alt="box" />
                  <a href="#0" className="action-icon">
                    <i className="far fa-heart"></i>
                  </a>
                  <div className="contest-num">
                    <span>contest no:</span>
                    <h4 className="number">pr2</h4>
                  </div>
                </div>
                <div className="contest-card__content">
                  <div className="left">
                    <h5 className="contest-card__name">The Omega Navigator</h5>
                  </div>
                  <div className="right">
                    <span className="contest-card__price">$3.99</span>
                    <p>ticket price</p>
                  </div>
                </div>
                <div className="contest-card__footer">
                  {/* <ul className="contest-card__meta">
                    <li>
                      <i className="las la-clock"></i>
                      <span>5d</span>
                    </li>
                    <li>
                      <i className="las la-ticket-alt"></i>
                      <span>9805</span>
                      <p>Remaining</p>
                    </li>
                  </ul> */}
                        {/* <Link to="/boxdetails" className="cmn-btn details-btn style--three btn--sm">
                  <i class="fas fa-box-open"></i> Open Box
                  </Link>
                </div>
              </div>
            </div> */}
                        {/* <div className="col-xl-4 col-md-6 mb-30">
              <div className="contest-card">
                <Link to="/boxdetails" className="item-link"></Link>
                <div className="contest-card__thumb">
                  <img src={box6} alt="box" />
                  <a href="#0" className="action-icon">
                    <i className="far fa-heart"></i>
                  </a>
                  <div className="contest-num">
                    <span>contest no:</span>
                    <h4 className="number">w03</h4>
                  </div>
                </div>
                <div className="contest-card__content">
                  <div className="left">
                    <h5 className="contest-card__name">Shelby Cobra</h5>
                  </div>
                  <div className="right">
                    <span className="contest-card__price">$3.99</span>
                    <p>ticket price</p>
                  </div>
                </div>
                <div className="contest-card__footer">
                  {/* <ul className="contest-card__meta">
                    <li>
                      <i className="las la-clock"></i>
                      <span>5d</span>
                    </li>
                    <li>
                      <i className="las la-ticket-alt"></i>
                      <span>9805</span>
                      <p>Remaining</p>
                    </li>
                  </ul> */}
                        {/*<Link to="/boxdetails" className="cmn-btn details-btn style--three btn--sm">
                  <i class="fas fa-box-open"></i> Open Box
                  </Link>
                </div>
              </div>
            </div> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
          </div>
        </div>
      </div>
      {/* <div className='row mt-30'>
        <div className='col-lg-12'>
          <div className='btn-grp'>
            <a className='btn-border'>
              {t('openBoxes.loadBtn')}
            </a>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Boxes;
