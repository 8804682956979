import React from "react";
import { Button } from "react-bootstrap";
import ConfirmationPopUp from "../PopUp/ConfirmationPopUp";
import closeicon from "../../assets/images/game/close.png";
import item2 from "../../assets/images/home/2.jpg";
import { includeToFixed } from "../../utils/useFunc";
import img2 from "../../assets/images/ttttt.png";
import img3 from "../../assets/images/ttttt-right.png";
import { useTranslation } from "react-i18next";

const MultiWinner = ({
  handleClose,
  winItem,
  handleAddInventory,
  handleQuickSellPercentage,
  openModal,
  handleConfirm,
  onCloseModal,
  active,
  toTalearnMoney,
  forDisablingButtons,
  handleProduct,
  isSelll,
}) => {
  const { t } = useTranslation();

  return (
    <div className="winner-popup">
      <div className="animted-images">
        <img src={img3} className="left-img" alt="img1" />
        <img src={img2} className="right-img" alt="img2" />
      </div>
      <div className="win-grid-container win_grid_container   wow animate__animated animate__fadeIn">
        <div className="win-close-icon" onClick={() => handleClose("invent")}>
          <img src={closeicon} alt="" />
        </div>
        <h2>{t("boxDetails.congratTxt")}</h2>
        {active === "2" ? (
          <div className="win-grid win-grid-container-two wow animate__animated animate__zoomIn">
            <div className="box__containers">
              {winItem &&
                winItem.length &&
                winItem.map((el, index) => {
                  const { bannerImage, name } = el?.pid;
                  return (
                    <>
                      {/* {console.log('gameId',el.gameId)} */}
                      <div className="img_name_btn">
                        <div className="win-grid-bix">
                          <img
                            src={bannerImage || item2}
                            alt=""
                            onClick={() => handleProduct(el)}
                          />
                          <div class="middle">
                            <i class="las la-search"></i>
                          </div>
                        </div>
                        <div className="win-btn">
                          <h3>{name || ""}</h3>
                          <Button
                            className="quick-sell quick___sell btn-style btn-style-transparent"
                            disabled={forDisablingButtons.includes(index)}
                            onClick={() =>
                              handleQuickSellPercentage(
                                el?.itemSellPrice,
                                el?.gameId,
                                index
                              )
                            }
                          >
                            <span>
                              {forDisablingButtons.includes(index)
                                ? "SOLD!"
                                : `SELL FOR ${includeToFixed(
                                    el?.itemSellPrice
                                  )} DT`}
                            </span>
                          </Button>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            <div className="win-btn win__btn">
              <Button
                className="quick-sell btn-style btn btn-primary"
                onClick={() => handleQuickSellPercentage(toTalearnMoney, "")}
              >
                <span>
                  {" "}
                  {t("boxDetails.winnerBtnTxt1")} {toTalearnMoney} DT
                </span>
              </Button>
              {/* <Button onClick={() => handleClose("invent")} className="roll-btn btn btn-primary"><span>NEW ROLL</span></Button> */}
              <Button
                className="roll-btn"
                onClick={() => handleClose("invent")}
              >
                {t("boxDetails.winnerBtnTxt2")}
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className="win-grid win-grid-container-three wow animate__animated animate__zoomIn">
              {winItem &&
                winItem.length &&
                winItem.map((el, index) => {
                  const { bannerImage, name } = el?.pid;
                  return (
                    <>
                      {/* {console.log('forDisablingButtons',forDisablingButtons)} */}
                      <div className="img_name_btn">
                        <div
                          className="win-grid-bix"
                          onClick={() => handleProduct(el)}
                        >
                          <img src={bannerImage || item2} alt="" />
                          <div class="middle sm_search">
                            <i class="las la-search"></i>
                          </div>
                        </div>
                        <div className="box__name">
                          <h3>{name || ""}</h3>
                        </div>
                        <div className="win-btn">
                          <Button
                            className="quick-sell quick_sells btn-style btn-style-transparent"
                            disabled={forDisablingButtons.includes(index)}
                            onClick={() =>
                              handleQuickSellPercentage(
                                el?.itemSellPrice,
                                el?.gameId,
                                index
                              )
                            }
                          >
                            <span>
                              {forDisablingButtons.includes(index)
                                ? "SOLD!"
                                : `SELL FOR ${includeToFixed(
                                    el?.itemSellPrice
                                  )} DT`}
                            </span>
                          </Button>
                        </div>
                      </div>
                    </>
                  );
                })}
              <div className="win-btn win__btn">
                <Button
                  className="quick-sell btn-style btn btn-primary"
                  onClick={() => handleQuickSellPercentage(toTalearnMoney)}
                >
                  <span>
                    {" "}
                    {t("boxDetails.winnerBtnTxt1")} {toTalearnMoney} DT
                  </span>
                </Button>
                <Button
                  onClick={() => handleClose("invent")}
                  className="roll-btn btn btn-primary"
                >
                  <span>NEW ROLL</span>
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      <ConfirmationPopUp
        open={openModal}
        handleConfirm={handleConfirm}
        onCloseModal={onCloseModal}
        isSelll={isSelll}
      />
    </div>
  );
};
export default MultiWinner;
