import React, { useState, useEffect } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import { Helmet } from "react-helmet";
import "../../../src/assets/css/arabic.css";
import { useSelector } from 'react-redux'


const Layout = ({ children }) => {
  const result = useSelector((state) => state.user);
  const { lan } = result || {}
  const [isRTL, setISRTL] = useState(lan)

  useEffect(() => {
    setISRTL(lan)
  }, [lan])

  const handleScroll = () => {
    window.scrollTo({ top: 0, behavoiur: "smooth" })
  }

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" type="image/x-icon" href={"/assets/images/favicon/FAVICON.png"} />
       
        <html lang="en" />
      </Helmet>
      <div className={isRTL === 'ar' ? ' wrapper rtl' : 'wrapper bg-new-all'}>
        <Header />
        <div className="content">
          {children}
        </div>
        <Footer />
      </div>
      <div className="scroll-btn" onClick={handleScroll}>
        <i class="las la-arrow-up"></i>
      </div>
    </>
  );
};

export default Layout;
