/* eslint-disable no-lone-blocks */
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
// import BoxImg from "../../assets/images/home/download.png";
import FairnessGuaranteedPopUp from "../PopUp/fairnessGuaranteedPopup";
import { useDispatch, useSelector } from "react-redux";
import { getPastGames } from "../../reducers/userReducer";
import TabbleLoader from "../Loader/tableloader";
import { calcTime } from "../../utils/getTimeZone";
import { t } from "i18next";
// import Loader from '../Loader/loader';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

const PastGame = () => {
  const [fairnessOpen, setFairnessOpen] = useState(false);
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const [hide, setHide] = useState(false);
  const result = useSelector((state) => state.user);
  const { pastGames, gamesCount, loading } = result || {};
  //console.log('pastGames',pastGames);
  const [selected, setSelected] = useState({});

  const handleFairness = (e, data) => {
    e.preventDefault();
    // console.log('check');
    setFairnessOpen(true);
    setSelected(data);
  };

  const handleLoadMore = () => {
    setSkip(skip + 5);
  };

  useEffect(()=>{
    if((pastGames.length > 0 && gamesCount<=pastGames.length) || pastGames.length === 0){
      setHide(true)
    }else{
      setHide(false)
    }
  },[gamesCount,pastGames?.length])

  useEffect(() => {
    dispatch(getPastGames(skip));
    console.log('hit==>>>',skip)
  }, [dispatch, skip]);

  // console.log(fairnessOpen);s

  return (
    <>
      {/* {loading && <Loader/>} */}
      <div>
        {fairnessOpen ? (
          <FairnessGuaranteedPopUp
            fairnessOpen={fairnessOpen}
            setFairnessOpen={setFairnessOpen}
            selected={selected}
          />
        ) : (
          ""
        )}
        <div className="all-transaction inventory__page past_game">
          <div className="all-transaction__header">
            <h3 className="title">{t("profile.pastGameTxt")}</h3>
            {/* <div className="date-range global-date-picker">
              <input type="date" className="datepicker-here form-control" />
              <i className="las la-calendar-alt"></i>
              <DatePicker
                selected={dateValue}
                onChange={handleDateSearch}
                maxDate={new Date()}
                placeholderText="JJ/MM/YYYY"
                dateFormat="d/MM/yyyy"
                showYearDropdown={true}
                dropdownMode="select"
              />
            </div> */}
          </div>
          {loading ? (
            <TabbleLoader />
          ) : (
            <>
              <div className="table-responsive-xl">
                <Table>
                  <thead>
                    <tr>
                      <th>{t("proTransactions.thTxt1")}</th>
                      <th>{t("proTransactions.thTxt6")}</th>
                      <th>{t("proTransactions.thTxt7")}</th>
                      <th>{t("proTransactions.thTxt8")}</th>
                      {/* <th>Ticket Won</th> */}
                      {/* <th>Profit</th>
									<th>Loss</th> */}
                      <th>{t("openBoxes.fairnessTxt")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pastGames.map((el) => {
                      // const date = new Date(el?.createdAt);
                      // const curdata = date.getDate();
                      // const monthName = date.toLocaleString('default', {
                      //   month: 'short',
                      // });
                      const { range } = el?.result || {};
                      {
                        /* const boxPrice = el?.boxPrice ? el.boxPrice : 0;
									const { price } = el?.result?.pid || {}; */
                      }
                      {
                        /* const loss =
										boxPrice && parseFloat(boxPrice) > parseFloat(price || 0)
											? `${(boxPrice - price).toFixed(2)} DT`
											: '-';
									const profit =
										boxPrice && parseFloat(boxPrice) < parseFloat(price || 0)
											? `${(price - boxPrice).toFixed(2)} DT`
											: '-'; */
                      }
                      return (
                        <tr>
                          <td>
                            <div className="date">
                              <span>{calcTime("+1", el?.createdAt)}</span>
                              {/* <span>{curdata}</span>
                              <span>{monthName}</span> */}
                            </div>
                          </td>
                          <td>
                            <div className="box__img">
                              <img src={el?.boxId?.image} alt="inventory-img" />
                            </div>
                          </td>
                          <td>
                            <p>{el?.result?.pid?.name}</p>
                          </td>
                          <td>
                            <p>{range} </p>
                          </td>
                          {/* <td>
                    <span className="quantity">3</span>
                  </td> */}
                          {/* <td>
												<div className="total_price profit">{profit}</div>
											</td>
											<td>
												<div className="total_price loss">{loss}</div>
											</td> */}
                          <td>
                            <button
                              className="cmn-btn btn-style btn-style-transparent"
                              onClick={(e) => handleFairness(e, el)}
                            >
                              <span>{t("boxDetails.fairnessTxt")}</span>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                    {/* <tr>
                  <td>
                    <div className="date">
                      <span>16</span>
                      <span>APR</span>
                    </div>
                  </td>
                  <td>
                    <div className="box__img">
                      <img src={BoxImg} alt="inventory-img" />
                    </div>
                  </td>
                  <td>
                    <p>xyz, lorem</p>
                  </td>
                  <td>
                    <p>626</p>
                  </td>
                  <td>
                    <span className="quantity">3</span>
                  </td>
                  <td>
                    <div className="total_price profit">$1615</div>
                  </td>
                  <td>
                    <div className="total_price loss">$115</div>
                  </td>
                  <td>
                    <button className="cmn-btn" onClick={handleFairness}>Fairness Guaranteed</button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="date">
                      <span>16</span>
                      <span>APR</span>
                    </div>
                  </td>
                  <td>
                    <div className="box__img">
                      <img src={BoxImg} alt="inventory-img" />
                    </div>
                  </td>
                  <td>
                    <p>xyz, lorem</p>
                  </td>
                  <td>
                    <p>626</p>
                  </td>
                  <td>
                    <span className="quantity">3</span>
                  </td>
                  <td>
                    <div className="total_price profit">$1615</div>
                  </td>
                  <td>
                    <div className="total_price loss">$115</div>
                  </td>
                  <td>
                    <button className="cmn-btn" onClick={handleFairness}>Fairness Guaranteed</button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="date">
                      <span>16</span>
                      <span>APR</span>
                    </div>
                  </td>
                  <td>
                    <div className="box__img">
                      <img src={BoxImg} alt="inventory-img" />
                    </div>
                  </td>
                  <td>
                    <p>xyz, lorem</p>
                  </td>
                  <td>
                    <p>626</p>
                  </td>
                  <td>
                    <span className="quantity">3</span>
                  </td>
                  <td>
                    <div className="total_price profit">$1615</div>
                  </td>
                  <td>
                    <div className="total_price loss">$115</div>
                  </td>
                  <td>
                    <button className="cmn-btn" onClick={handleFairness}>Fairness Guaranteed</button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="date">
                      <span>16</span>
                      <span>APR</span>
                    </div>
                  </td>
                  <td>
                    <div className="box__img">
                      <img src={BoxImg} alt="inventory-img" />
                    </div>
                  </td>
                  <td>
                    <p>xyz, lorem</p>
                  </td>
                  <td>
                    <p>626</p>
                  </td>
                  <td>
                    <span className="quantity">3</span>
                  </td>
                  <td>
                    <div className="total_price profit">$1615</div>
                  </td>
                  <td>
                    <div className="total_price loss">$115</div>
                  </td>
                  <td>
                    <button className="cmn-btn" onClick={handleFairness}>
                      Fairness Guaranteed
                    </button>
                  </td>
                </tr> */}
                  </tbody>
                </Table>
              </div>

              {!hide && (
                <div className="load-more">
                  <button type="button" onClick={handleLoadMore}>
                    {t("proTransactions.showMore")}{" "}
                    <i className="las la-angle-down ml-2"></i>
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PastGame;
