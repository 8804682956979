import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withoutAuth } from "../../config/axios";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { queryString } from "../../utils/useFunc";
// import style from "../../components/headers/common/header.module.css";

const VerifyEmail = () => {
  const { emailVerificationHash } = queryString();
  const { t } = useTranslation();
  const history = useHistory();

  const handleVerifyEmail = async () => {
    // console.log('emailViewer', emailVerificationHash)
    await withoutAuth().patch(`/api/auth/verify/${emailVerificationHash}`);
    // console.log('response', response)
  };

  const handleClick = () => {
    history.push("/?modal=Login");
  };

  useEffect(() => {
    handleVerifyEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content="Verify Email | BOXI" />
        <title>Verify Email | BOXI</title>
      </Helmet>
      <div className="verify-email">
        <div className="verify-message-inner">
          <div className="verify-message">
            <h2>{t("verifyEmail.verifyTitle")}</h2>
            <h4>{t("verifyEmail.verifySubTitle")}.</h4>
          </div>
          <div class="now-login-btn">
            <button class="cmn-btn btn-style" onClick={handleClick}>
              <span>{t("verifyEmail.verifyBtn")}</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
