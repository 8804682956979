import React, { useEffect, useState } from "react";
import Layout from "./../layout/layout";
import "./checkout.css";
// import { SubscribeForm } from "./../cart/SubscribeForm";
import { useDispatch, useSelector } from "react-redux";
import {
  createPayment,
  placeOrder,
  selectedAddressUpdate,
  updateAddressStatus,
} from "../../reducers/userReducer";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AddressDetails from "./addressDetails";
// import { getLoginUser } from './../../reducers/userReducer';
import ConfirmationPopUp from "../PopUp/ConfirmationPopUp";
import Login from "../login/login";
import Register from "../register/register";
import { toast } from "react-toastify";
import Loader from "../Loader/loader";
import { Helmet } from "react-helmet";
import DepositPopup from "../PopUp/depositPopup";
import { useTranslation } from "react-i18next";

export const CheckOut = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const result = useSelector((state) => state.user);
  const { allCartsProducts, userDetails } = result || {};

  const { shippingAddress, walletAmount } = userDetails;
  const [selectedAddress, setSelectedAddress] = useState({});

  const [openModal, setOpenModal] = useState(false);

  const [cartState, setCartState] = useState([]);
  const [address, setAddress] = useState(false);

  const [login, setLogin] = useState(false);
  const [signup, setSignup] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [show, setShow] = useState(false);
  const [deposit, setDeposit] = useState(false);
  const { t } = useTranslation();

  const handleLogin = (change) => {
    setLogin(!login);
    setSignup(false);
    if (change) {
      // history.goBack()
    }
  };
  const handleSignup = (change) => {
    setSignup(!signup);
    setLogin(false);
    if (change) {
      setLogin(!login);
    }
  };

  // console.log(JSON.parse(localStorage.getItem('addtoCarts')));
  const token = localStorage.getItem("inboxi#@user");
  const cartData = token
    ? allCartsProducts
    : JSON.parse(localStorage.getItem("addtoCarts"));

  useEffect(() => {
    // dispatch(createPayment());
    if (cartData?.length) {
      setCartState(cartData);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCartsProducts]);

  const calculatePrice = (quant, price) => {
    let result = parseFloat(quant || 0) * parseFloat(price || 0);
    return result;
  };

  const [copyShippingAddress, setCopyShippingAddress] = useState();

  useEffect(() => {
    if (userDetails?.shippingAddress?.length > 0) {
      const normalAddress = userDetails?.shippingAddress?.find(
        (el) => el?.status
      );
      const findAddress = normalAddress || userDetails?.shippingAddress[0];

      const newObj = {
        flatPlot: findAddress?.flatPlot,
        address: findAddress?.address,
        city: findAddress?.city,
        state: findAddress?.state,
        country: findAddress?.country,
        zipCode: findAddress?.zipCode,
        uid: findAddress?.uid,
      };

      setSelectedAddress(newObj);
      setCopyShippingAddress(shippingAddress);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  ///for Refererebdedc

  // const handleRequestToShip = (data,invId) => {
  //   setOpen(true);
  //   console.log("I m clicked", data);
  //   const payload = {
  //     productId: data?._id,
  //     totalAmount: data?.price,
  //     subTotal: data?.price,
  //     inventoryId:invId,
  //     productDetails:{
  //       productId:data?._id,
  //       price:data?.price,
  //       quantity:1,
  //     }
  //   };
  //   console.log("kkk---=-==->>", payload);
  //   setPayload(payload)
  //   // dispatch(placeOrder(payload));
  // };

  // //popup close
  // const onCloseModal=()=>{
  //   setOpen(false)
  // }

  // const handleAddressChange = (e,id) =>{
  //   console.log('value',id);
  //   const findAddress =  userDetails?.shippingAddress?.find((el)=>el?.uid === id)
  //   console.log('findAddress',findAddress)
  //   setSelectedAddress(findAddress)
  // }

  const totalCartPrice = (data) => {
    if (data?.length > 0) {
      const result = data.reduce((prev, next) => {
        // console.log('next===',next)
        return (
          prev +
          parseFloat(next?.productQuantity || 0) *
            parseFloat(next?.productId?.price || 0)
        );
      }, 0);
      // console.log('result',result)
      return result;
    }
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const createOrderPayload = () => {
    const newContainer = cartState.map((el) => {
      const obj = {};
      //  let gst = 0
      const prod = el.productId;
      console.log("prod==>", prod, "el===>", el);
      obj.productDetails = {
        productId: prod?._id,
        price: prod?.price,
        quantity: el.productQuantity,
      };
      console.log("Obj====>", obj);
      return obj;
    });

    const payload = {
      orderContainer: newContainer,
    };
    console.log("payload orderContainer===>", payload);
    const userData = {
      firstname: userDetails?.firstname,
      lastname: userDetails?.lastname,
      email: userDetails?.email,
      phoneNumber: userDetails?.mobile,
    };

    payload.shippingAddress = selectedAddress;
    payload.totalAmount = totalCartPrice(cartState);
    payload.subTotal = totalCartPrice(cartState);
    payload.userData = userData;
    payload.type = "order";
    return payload;
  };

  const handleConfirm = async () => {
    const payload = createOrderPayload();
    const res = await dispatch(placeOrder(payload));
    //console.log("res====>placeOrder", res);
    const { code } = res?.payload?.resultData?.data;
    if (code === 200) {
      toast.success(t("toast.ordersuccess"), {
        toastId: "inventoryPlaceOrder",
      });
      setOpenModal(false);
      // setShow(true);
      history.push("/thanks");
      // console.log("show",show)
      //
      // history.push('/product')
    }
  };

  const handleBalancePayment = () => {
    if (!token) {
      return setLogin(true);
    }

    if (!selectedAddress?.address || copyShippingAddress.length === 0) {
      return toast.error("Please Add Delivery Address", {
        toastId: "deliveryAddress",
      });
    }

    const totalItemAmount = totalCartPrice(cartState);
    if (!walletAmount || walletAmount < totalItemAmount) {
      setDeposit(true);
      return toast.error( t("toast.walleterror"), {
        toastId: "oasssss",
      });
    }

    setOpenModal(true);
  };

  const createAddress = (data) => {
    let address = "";
    Object.keys(data).forEach((el) => {
      if (data[el]) {
        address = `${address ? `${address},` : address} ${data[el]}`;
      }
    });
    return address;
  };

  // const sendToLogin = () =>{
  //   history.push('/?modal=Login')
  // }

  const handleAddress = () => {
    setAddress(!address);
  };

  const handleSelectAddress = async (data, indx) => {
    const copyAddress = JSON.parse(JSON.stringify(copyShippingAddress));
    copyAddress.forEach((el, index) => {
      if (index === indx) {
        el.status = true;
      } else {
        el.status = false;
      }
    });
    // console.log("copyAddress", copyAddress);
    let copy = { ...data };
    copy.status = true;
    // console.log("copy", copy);
    dispatch(updateAddressStatus({ index: indx, addData: copy }));
    const payload = { shippingAddress: copyAddress };
    dispatch(selectedAddressUpdate(payload));
  };

  const handleCloseDeposit = () => {
    setDeposit(false);
  };

  const handlePayWithOther = () => {
    const payload = createOrderPayload();
    // console.log('payload handlePayWithOther===>',payload);
    dispatch(createPayment(payload));
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Checkout | BOXI" />
        <title>Checkout | BOXI</title>
      </Helmet>
      <Layout>
        <section className="checkout-page">
          {loading && <Loader />}
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="checkout-area">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="checkout-form-area">
                        {!token && (
                          <div className="top">
                            <div className="left">
                              <h3 className="mb-2">Already a Boxi Member?</h3>
                              <p>Sign in to buy items more easier!</p>
                            </div>
                            <div className="right">
                              <Link onClick={() => setLogin(!login)}>
                                <i className="las la-user"></i>
                                <span>Sign in</span>
                              </Link>
                            </div>
                          </div>
                        )}

                        {/* <div className='top'>
                        <div className='left'>
                          <h3 className='mb-2'>Already a Rifa Member?</h3>
                          <p>Sign in to buy lottery more easier!</p>
                        </div>
                        <div className='right'>
                          <a href='#0'>
                            <i className='las la-user'></i>
                            <span>Sign in</span>
                          </a>
                        </div>
                      </div>
                      <div className='personal-details mt-30'>
                        <h3 className='title'>Share your Contact Details </h3>
                        <form className='personal-details-form'>
                          <div className='form-row'>
                            <div className='form-group col-lg-6'>
                              <input type='text' placeholder='First Name' />
                            </div>
                            <div className='form-group col-lg-6'>
                              <input type='text' placeholder='Last Name' />
                            </div>
                            <div className='form-group col-lg-6'>
                              <input
                                type='email'
                                placeholder='Enter your Mail'
                              />
                            </div>
                            <div className='form-group col-lg-6'>
                              <input
                                type='text'
                                placeholder='Enter your Phone Number'
                              />
                            </div>
                            <div className='form-group col-lg-6'>
                              <button type='submit' className='cmn-btn'>
                                Continue
                              </button>
                            </div>
                          </div>
                        </form>
                      </div> */}
                        {token && shippingAddress?.length > 0 && (
                          <div className="payment-details address">
                            <h3 className="title">{t("cart.deliverTxt")}</h3>
                            <div className="delivery-address">
                              <h3>{t("proShipDetails.placeTxt4")}</h3>
                              <Link
                                // to={`/profile?tab=shippingAddress`}
                                onClick={handleAddress}
                                type="button"
                                class="shipping-btn1 btn btn-primary"
                              >
                                <i class="fa fa-pencil"></i>{" "}
                                {t("cart.chngeTxt")}
                              </Link>
                            </div>
                            <div className="address-details">
                              <i class="las la-home"></i>
                              {/* <p>
                            C-85, Sector B, Aliganj, Lucknow, Uttar Pradesh
                            226024
                          </p> */}
                              <p>{`${createAddress(selectedAddress)}`}</p>
                            </div>
                          </div>
                        )}

                        {token && shippingAddress?.length === 0 && (
                          <div className="payment-details address add-address">
                            <Link
                              to={`/profile`}
                              // to={`/profile?tab=shippingAddress&from=checkout`}
                              onClick={handleAddress}
                              type="button"
                              class="shipping-btn1 btn btn-primary"
                            >
                              <i class="fa fa-plus"></i>{" "}
                              {t("proShipDetails.addBtn")}{" "}
                              {t("cart.deliverTxt")}
                            </Link>
                            <p>{t("cart.addressTxt2")}</p>
                          </div>
                        )}

                        <div className="payment-details">
                          <h3 className="title">{t("cart.payTxt")}</h3>
                          <form className="payment-form">
                            <div className="payment-methods">
                              {/* <button type="button" className="checked">
                              <i className="las la-credit-card"></i>
                              <span>Credit Card</span>
                            </button> */}
                              {/* <button type="button">
                              <i className="las la-credit-card"></i>
                              <span>Debit Card</span>
                            </button> */}
                              {/* <button type="button">
                              <i className="lab la-paypal"></i>
                              <span>Credit Card</span>
                            </button> */}
                              <button
                                type="button"
                                onClick={handleBalancePayment}
                                className=""
                              >
                                <span>
                                  <i class="las la-dollar-sign"></i>
                                  {t("proTransactions.balTxt")}
                                </span>
                              </button>
                              <button
                                type="button"
                                onClick={handlePayWithOther}
                                className=""
                              >
                                <span>
                                  <i class="las la-dollar-sign"></i>
                                  {t("cart.payTXt3")}
                                </span>
                              </button>
                            </div>
                            {/* <h5 className="payment-form__title">
                              Enter Your Card Details{" "}
                            </h5>
                            <div className="form-row">
                              <div className="form-group col-lg-12">
                                <label>Card Details</label>
                                <input type="text" />
                              </div>
                              <div className="form-group col-lg-12">
                                <label>Name on the Card</label>
                                <input type="text" />
                              </div>
                              <div className="form-group col-lg-6">
                                <label>Expiration</label>
                                <input type="text" placeholder="MM/YY" />
                              </div>
                              <div className="form-group col-lg-6">
                                <label>CVV</label>
                                <input type="password" placeholder="CVV" />
                              </div>
                              <div className="form-group col-lg-12">
                                <button type="submit" className="cmn-btn">
                                  Make payment
                                </button>
                              </div>
                            </div> */}
                          </form>
                          <p className="info-text">
                            {t("cart.privacyTxt1")}{" "}
                            <a href="#0">{t("cart.privacyTxt2")}</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 mt-lg-0 mt-4">
                      <div className="checkout-wrapper">
                        <div className="checkout-wrapper__header">
                          <h3>{t("cart.checkoutTxt")}</h3>
                        </div>

                        <div className="checkout-wrapper__body">
                          <ul className="price">
                            {cartState?.length > 0 &&
                              cartState.map((el) => {
                                const prod = el?.productId;
                                return (
                                  <li>
                                    <div className="left">
                                      <h4 className="caption">Product Price</h4>
                                      <span>{`(${el?.productQuantity} product X ${prod?.price} DT)`}</span>
                                    </div>
                                    <div className="right">
                                      <span className="price">
                                        {calculatePrice(
                                          el?.productQuantity,
                                          prod?.price
                                        )}{" "}
                                        DT
                                      </span>
                                    </div>
                                  </li>
                                );
                              })}
                            <li>
                              <div className="left">
                                <h4 className="caption">
                                  {t("cart.totalTxt")}
                                </h4>
                              </div>
                              <div className="right">
                                <span className="price">
                                  {totalCartPrice(cartState) || 0} DT
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <SubscribeForm /> */}
            </div>
          </div>
        </section>
        {copyShippingAddress?.length > 0 ? (
          <AddressDetails
            show={address}
            handleClose={handleAddress}
            shippingAddress={copyShippingAddress}
            handleSelectAddress={handleSelectAddress}
            selectedAddress={selectedAddress}
          />
        ) : null}
        <ConfirmationPopUp
          open={openModal}
          handleConfirm={handleConfirm}
          onCloseModal={onCloseModal}
          title="Are you sure to place Order ?"
          walletAmount={walletAmount}
        />

        {!localStorage.getItem("inboxi#@user") && (
          <Login
            login={login}
            handleLogin={handleLogin}
            handleSignup={handleSignup}
          />
        )}
        {!localStorage.getItem("inboxi#@user") && (
          <Register
            signup={signup}
            handleSignup={handleSignup}
            handleLogin={handleLogin}
            refUserId=""
          />
        )}

        <DepositPopup show={deposit} handleClose={handleCloseDeposit} />
      </Layout>
    </>
  );
};
