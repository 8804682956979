/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Layout from "../layout/layout";
import userimg from "../../assets/images/user/user-icon.png";
import "./profile-styles.css";
import Profile from "./profile";
import UserTransaction from "./UserTransaction";
import Affiliate from "./affiliate";
// import Favourites from "./Favourites";
// import ShippingDetails from "./ShippingDetails";
import Inventory from "./Inventory";
import PastGame from "./PastGame";
import { userInstance } from "../../config/axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getLoginUser, resetInventory } from "./../../reducers/userReducer";
import { useTranslation } from "react-i18next";
import { queryString } from "./../../utils/useFunc";
import Orders from "./order";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import {
  registrationValidation,
  submitAddressValidation,
  submitEmailValidation,
  submitMobileValidation,
  submitPassWordValidation,
  updatePassWordValidation,
} from "../../utils/validation";

const ProfilePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tab } = queryString();
  const history = useHistory();

  const sideMenu = [
    {
      id: 0,
      value: undefined,
    },
    //   {
    //     id:1,value:'shippingAddress'
    // },
    {
      id: 1,
      value: "transactions",
    },
    // {
    //   id:2,value:'referral'
    // },
    {
      id: 2,
      value: "inventory",
    },
    {
      id: 3,
      value: "pastGames",
    },
    {
      id: 4,
      value: "orders",
    },
    {
      id: 5,
      value: "affiliates",
    },
  ];
  // console.log("idpsidopsdiopuiobj");
  const result = useSelector((state) => state.user);
  const { userDetails } = result || {};
  const { walletAmount } = userDetails || 0; // const initialObj = {
  //   username: "",
  //   email: "",
  //   password: "",
  //   isVerified: "",
  //   firstname: "",
  //   lastname: "",
  //   mobile: "",
  //   profile: "",
  //   dateOfBirth: "",
  //   walletAmount: "",
  //   forgetPassHash: "",
  //   userType: "",
  //   useeReferalId: "",
  //   shippingAddress: "",
  //   referalBy: "",
  //   referalLink: "",
  //   referalCount: "",
  //   isDeleted: "",
  //   isBlock: "",
  //   referalBonus: "",
  //   language: "",
  // };

  // console.log('userDetails',userDetails)

  const initialShippingAddress = {
    address: "",
    zipCode: "",
    country: "Tunisia",
    city: "",
    state: "",
    uid: "",
    status: false,
  };

  const initialUpdate = {
    username: "",
    email: "",
    currentPassword: "",
    newPassword: "",
    firstname: "",
    lastname: "",
    mobile: "",
    governorate: "",
    dateOfBirth: "",
    shippingAddress: [],
    editIndex: "",
  };

  const [addData] = useState(initialShippingAddress);
  const [userAddress] = useState([]);

  const [addAddress, setAddAddress] = useState(false);
  const [userData, setUserData] = useState(initialUpdate);
  const [edit, setEdit] = useState(false);
  // const [image, setImage] = useState({})
  const [preview, setPreview] = useState(userDetails?.profile);
  const [errors, setErrors] = useState({});

  const handleEdit = (index) => {
    setEdit(index);
    setErrors({});
  };

  const handleChange = (e, type) => {
    //console.log('eeeee=>', e, type);
    if (type === "dateOfBirth") {
      setUserData({ ...userData, dateOfBirth: e });
    } else {
      const { name, value } = e.target;
      setUserData({ ...userData, [name]: value });
    }
  };

  const handleEditChange = (e, index, item) => {
    const { name, value } = e.target;
    let copyState = { ...item };
    copyState[name] = value;
    let copyUserAddress = [...userData?.shippingAddress];
    copyUserAddress[index] = { ...copyState };
    setUserData({ ...userData, shippingAddress: copyUserAddress });
  };

  const handleShipAddressValues = async (e) => {
    const { name, value } = e.target;
    const validateData = await registrationValidation(value, name);
    const validateError = validateData;
    // console.log('validateError',validateError)
    const copy = { ...errors };
    if (validateData[name]) {
      copy[name] = validateError[name];
    } else {
      delete copy[name];
    }
    if (name === "mobile" || name === "email") {
      if (userDetails[name] === userData[name]) {
        delete copy[name];
      }
    }
    setErrors(copy);

    // if(edIndex === 4){
    // const validateData = checkValidateField(userData?.shippingAddress[0],t);
    // console.log('validateData==>>>',validateData)
    // if(!validateData?.isValid){
    //  setErrors(validateData?.errors)
    // }else{
    //   setErrors({})
    // }
    // }
  };

  const handlePasswordFocus = (e) => {
    const { name, value } = e.target;
    const validData = updatePassWordValidation(name, value);
    const validateError = validData?.errors;
    // console.log('validateError',validateError)
    const copy = { ...errors };
    if (!validData?.isValid) {
      copy[name] = validateError[name];
    } else {
      delete copy[name];
    }
    setErrors(copy);
  };

  const handlePicUpload = async (e) => {
    // let url = URL.createObjectURL(e.target.files[0])
    // setImage(e.target.files[0])
    // console.log('e.target.files[0]',e.target.files[0]);
    const formData = new FormData();
    if (e.target.files.length > 0) {
      formData.append("file", e.target.files[0]);
      //console.log('formdatavalkue',formData.get('file'))
      const response = await userInstance().post(
        "/api/user/uploadProfileImage",
        formData
      );
      const { code } = response.data;
      //  console.log("IMAGE CHECK===>44",response.data)
      if (code === 200) {
        dispatch(getLoginUser());
        toast.success(t("toast.profilesuccess"), { toastId: "profilesuccess" });
      }
    }
  };
  const handleUpdate = async (edIndex) => {
    // console.log('errors==>>',errors)
    userData.editIndex = edIndex;

    try {
      let randomStr = (
        Math.floor(Math.random() * 9 + 1) +
        Date.now() +
        (Math.random() + 1).toString(36).substring(7)
      ).toString();
      if (!userData.shippingAddress[0].uid) {
        userData.shippingAddress[0].uid = randomStr;
      }
      // console.log('userrData==>>',userData)

      // const nMan =  Object.keys(errors);

      if (edIndex === 0) {
        const { firstname, lastname, dateOfBirth } = userData;
        const validateAllFields = submitAddressValidation({
          firstname,
          lastname,
          dateOfBirth,
        },t);
        // console.log('validationfields',validateAllFields)
        if (!validateAllFields.isValid) {
          return setErrors(validateAllFields?.errors);
        }
        if (
          userDetails.firstname === firstname &&
          userDetails.lastname === lastname &&
          userDetails?.dateOfBirth === dateOfBirth
        ) {
          return "success";
        }
      }

      if (edIndex === 1) {
        if (userDetails?.mobile === userData?.mobile) {
          return "success";
        }

        const validateAllFields = await submitMobileValidation(
          userData?.mobile,
          "mobile",t
        );
        // console.log('validationfields',validateAllFields)
        if (!validateAllFields.isValid) {
          return setErrors(validateAllFields?.errors);
        }
      }

      if (edIndex === 2) {
        if (userDetails?.email === userData?.email) {
          return "success";
        }
        const validateAllFields = await submitEmailValidation(
          userData?.email,
          "email",t
        );
        // console.log('validationfields',validateAllFields)
        if (!validateAllFields.isValid) {
          return setErrors(validateAllFields?.errors);
        }
      }

      if (
        edIndex === 3 &&
        !userData?.currentPassword &&
        !userData?.newPassword
      ) {
        setErrors({});
        return "success";
      }

      if (edIndex === 3) {
        // console.log('comingbvbbb')
        const { currentPassword, newPassword } = userData;
        const validateData = submitPassWordValidation({
          currentPassword,
          newPassword,
        },t);
        // console.log('validateData===>>',validateData)

        if (!validateData.isValid) {
          return setErrors(validateData?.errors);
        }
      }

      if (edIndex === 4) {
        const validateAllFields = submitAddressValidation(
          userData?.shippingAddress[0],t
        );
        // console.log('ValidateAllFields',validateAllFields)
        if (!validateAllFields.isValid) {
          return setErrors(validateAllFields?.errors);
        }
        if (
          JSON.stringify(userDetails?.shippingAddress[0]) ===
          JSON.stringify(userData?.shippingAddress[0])
        ) {
          return "success";
        }
      }

      // if(nMan?.length !== 0){
      //   return;
      // }

      // const formData = new FormData()
      // if (image) {
      //   console.log('image--------',image)
      //   formData.append('file', image)
      // }
      // Object.keys(userData).forEach((el) => {
      //   if (userData[el]) {
      //     formData.append(el, userData[el])
      //   }
      // })
      // console.log('FormDtaa-1====',formData.get('file'))

      const response = await userInstance().put(
        "/api/user/updateUser",
        userData
        // formData
      );
      const { code, msg } = response.data;
      //console.log("IMAGE CHECK====>12",response)
      if (code === 200) {
        dispatch(getLoginUser());
        toast.success(t("toast.userupdatesuccess"), {
          toastId: "userupdatesuccess",
        });
        return "success";
      } else {
        toast.error(msg);
      }
    } catch (error) {
      // console.log('error', error)
    }
  };
  // const handleProfileImage=async()=>{
  //   try {
  //     const formData = new FormData();
  //     console.log("image-----",image)
  //     if (image) {
  //       console.log('here-----')
  //       formData.append('file', image)
  //       console.log('FormDtaa-2====',formData.get('file'))
  //       alert('file')
  //       const response = await userInstance().post(
  //         '/api/user/uploadProfileImage',
  //         formData
  //       )
  //       console.log("IMAGE CHECK====>12",response)
  //       const { code, msg } = response.data
  //       if (code === 200) {
  //         dispatch(getLoginUser())
  //         //setEdit(false)
  //       }
  //     }

  //   } catch (error) {
  //     console.log('error', error)
  //   }
  // }

  // const handleAddForm = () => {
  //   setAddAddress(!addAddress)
  //   handleEdit()
  // }

  useEffect(() => {
    if (userDetails?._id) {
      let copy = { ...userData, ...JSON.parse(JSON.stringify(userDetails)) };
      if (copy?.shippingAddress?.length === 0) {
        copy.shippingAddress = [addData];
      }
      setUserData(copy);
      setPreview(userDetails?.profile);
    }
  }, [userDetails]);

  const [key, setKey] = useState(0);

  // console.log('dsagdjyhsatd',userData)

  useEffect(() => {
    const findIndexx = sideMenu.findIndex((el) => el.value === tab);
    setKey(findIndexx);
  }, [tab]);

  const handleKeys = (kk) => {
    const value = sideMenu[kk].value;
    const url = kk === 0 ? "/profile" : `/profile?tab=${value}`;
    history.push(url);
    dispatch(resetInventory());
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Profile | BOXI" />
        <title>Profile | BOXI</title>
      </Helmet>
      <Layout>
        <div className="user-profile-section pt-120">
          <div className="pt-120 pb-120">
            <div className="container">
              <Tabs
                activekey={key}
                selectedIndex={key}
                onSelect={(k) => handleKeys(k)}
              >
                <div className="ar-profile-row row">
                  <div className="col-lg-4">
                    <div className="myprofile-card user-card">
                      <div className="avatar-upload">
                        <div className="obj-el">
                          <img src={preview || userimg} alt="user profile" />
                        </div>
                        <div
                          className="avatar-edit"
                          style={{ bottom: "-10px" }}
                        >
                          <input
                            type="file"
                            id="imageUpload"
                            name="file"
                            accept=".png, .jpg, .jpeg"
                            //onChange={handleProfileImage}
                            onChange={handlePicUpload}
                          />
                          <label htmlFor="imageUpload"></label>
                        </div>
                        <div className="avatar-preview">
                          <div
                            id="imagePreview"
                            style={{
                              backgroundImage: "url(assets/images/user/pp.png)",
                            }}
                          ></div>
                        </div>
                      </div>
                      <h3 className="user-card__name">
                        {`${userData?.firstname} ${userData?.lastname}`}{" "}
                      </h3>
                      <span>{`${t("profile.username")}  ${
                        userData?.username
                      } `}</span>
                    </div>
                    <div
                      className="user-action-card myprofile-card"
                      style={{ padding: "10px" }}
                    >
                      <TabList className="user-action-list">
                        <Tab>{t("profile.infoTxt")}</Tab>
                        {/* <Tab>{t("profile.shipTxt")}</Tab> */}
                        <Tab>{t("profile.transTxt")}</Tab>
                      
                        {/* <Tab>{t("profile.favTxt")}</Tab> */}
                        <Tab>{t("profile.inventoryTxt")}</Tab>
                        <Tab>{t("profile.pastGameTxt")}</Tab>
                        <Tab>{t("profile.orderTxt")}</Tab>
                        {!userData.disableStatus&&<Tab>{t("referencesTran.references")}</Tab>}
                      </TabList>
                    </div>
                  </div>
                  <div className="col-lg-8 mt-lg-0 mt-5">
                    <TabPanel>
                      {userDetails?._id && (
                        <Profile
                          userData={userData}
                          edit={edit}
                          handleEdit={handleEdit}
                          handleChange={handleChange}
                          handleUpdate={handleUpdate}
                          setAddAddress={setAddAddress}
                          addAddress={addAddress}
                          userAddress={userAddress}
                          handleEditChange={handleEditChange}
                          errors={errors}
                          handleShipAddressValues={handleShipAddressValues}
                          handlePasswordFocus={handlePasswordFocus}
                        />
                      )}
                    </TabPanel>
                    {/* <TabPanel>
                    <ShippingDetails
                      handleEdit={handleEdit}
                      edit={edit}
                      handleAddForm={handleAddForm}
                      userDataAddress={userData?.shippingAddress}
                      setAddAddress={setAddAddress}
                      addAddress={addAddress}
                    />
                  </TabPanel> */}
                    <TabPanel>
                      <UserTransaction walletAmount={walletAmount} />
                    </TabPanel>
                   
                    {/* <TabPanel>
                    <Favourites />
                  </TabPanel> */}
                    <TabPanel>
                      <Inventory userDataAddress={userData?.shippingAddress} />
                    </TabPanel>

                    <TabPanel>
                      <PastGame />
                    </TabPanel>

                    <TabPanel>
                      <Orders userDataAddress={userData?.shippingAddress} />
                    </TabPanel>
                    <TabPanel>
                   { !userData?.disableStatus && <Affiliate  userData={userData} /> }
                  </TabPanel>
                  </div>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ProfilePage;
