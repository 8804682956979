import React from "react";
import Layout from "../layout/layout";
import "./unboxing.css";
import Filtercomponent from "./Filtercomponent";
import icon01 from "../../assets/images/icon/contest-feature/1.png";
import icon02 from "../../assets/images/icon/contest-feature/2.png";
import icon03 from "../../assets/images/icon/contest-feature/3.png";
// import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Unboxing = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>
          BOXI ! و خلّي الربح علينا | Les produits de vos rêves, pour des prix
          de folies!
        </title>
        <meta
          property="og:title"
          content="BOXI ! و خلّي الربح علينا | Les produits de vos rêves, pour des prix  de folies!"
        />
      </Helmet>
      <Layout>
        <div className="unboxing-hero-section inner-hero-section style--three">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <ul className='page-list'>
                <li>
                  <Link className='' to='/'>
                    {t('openBoxes.backTxt1')}
                  </Link>
                </li>
                <li className='active'>{t('openBoxes.backTxt2')}</li>
              </ul> */}
              </div>
            </div>
          </div>
        </div>

        <div className="unboxing-page unboxing-page-content">
          <section className="position-relative pb-120">
            <div className="container">
              <div className="row justify-content-center">
                <div
                  className="col-lg-12 wow fadeInUp"
                  data-wow-duration="0.5s"
                  data-wow-delay="0.3s"
                >
                  <div className="unboxing-header unboxing_header mt-minus-100">
                    <div className="section-header text-center pt-50">
                      {/* <span className="section-sub-title">
                        {t("openBoxes.title")}
                      </span> */}
                      <h2 className="section-title">
                        {t("openBoxes.heading")}
                      </h2>
                      <p>{t("openBoxes.paragraph")}</p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-12 wow fadeInUp"
                  data-wow-duration="0.5s"
                  data-wow-delay="0.3s"
                >
                  <Filtercomponent />
                </div>
              </div>
            </div>
          </section>

          <section className="pb-120">
            <div className="container">
              <div className="row mb-none-30 justify-content-center">
                <div className="col-lg-4 col-sm-6 mb-30">
                  <div className="icon-item2">
                    <div className="icon-item2__icon">
                      <img src={icon01} alt="icon" />
                    </div>
                    <div className="icon-item2__content">
                      <h3 className="title ar-heading">
                        {t("openBoxes.serviceHeading1")}
                      </h3>
                      <p>{t("openBoxes.serviceTxt1")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 mb-30">
                  <div className="icon-item2">
                    <div className="icon-item2__icon">
                      <img src={icon02} alt="icon" />
                    </div>
                    <div className="icon-item2__content">
                      <h3 className="title ar-heading">
                        {t("openBoxes.serviceHeading2")}
                      </h3>
                      <p>{t("openBoxes.serviceTxt2")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 mb-30">
                  <div className="icon-item2">
                    <div className="icon-item2__icon">
                      <img src={icon03} alt="icon" />
                    </div>
                    <div className="icon-item2__content">
                      <h3 className="title ar-heading">
                        {t("openBoxes.serviceHeading3")}
                      </h3>
                      <p>{t("openBoxes.serviceTxt3")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default Unboxing;
