import React, { useState, useEffect } from "react";
import Layout from "../layout/layout";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProductFilter from "../product/productfilter";
import "./product.css";
import icon01 from "../../assets/images/icon/contest-feature/1.png";
import icon02 from "../../assets/images/icon/contest-feature/2.png";
import icon03 from "../../assets/images/icon/contest-feature/3.png";
import cart from "../../assets/images/cart.png";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

// import FilterComponent from '../unboxing/Filtercomponent'

const Unboxing = () => {
  let history = useHistory();
  const { t } = useTranslation();
  const result = useSelector((state) => state.user);
  const [activeDrags, setActiveDrags] = useState(0);
  const { allCartsProducts } = result || {};

  const token = localStorage.getItem("inboxi#@user");
  const cartData = token
    ? allCartsProducts
    : JSON.parse(localStorage.getItem("addtoCarts"));
  const [cartState, setcartState] = useState([]);

  useEffect(() => {
    setcartState(cartData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCartsProducts]);

  const totalCartItems = () => {
    if (cartState?.length > 0) {
      const result = cartState.reduce(
        (pre, next) => pre + next?.productQuantity,
        0
      );
      // console.log("result", result);
      return result;
    }
  };

  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  };

  const onStop = () => {
    setActiveDrags(activeDrags - 1);
  };

  const handleCart = () => {
    history.push("/cart");
  };
  return (
    <>
      <Helmet>
        <meta property="og:title" content="Product | BOXI" />
        <title>Product | BOXI</title>
      </Helmet>
      <Layout>
        <div className="unboxing-hero-section inner-hero-section style--three">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <ul className="page-list">
                <li>
                  <Link className="" to="/">
                    {t("openBoxes.backTxt1")}
                  </Link>
                </li>
                <li className="active">{t("openBoxes.backTxt2")}</li>
              </ul> */}
              </div>
            </div>
          </div>
        </div>

        <div className="unboxing-page  unboxing-page-content">
          <section className="position-relative pb-120">
            <div className="container">
              <div className="row justify-content-center">
                <div
                  className="col-lg-12 wow fadeInUp"
                  data-wow-duration="0.5s"
                  data-wow-delay="0.3s"
                >
                  <div className="unboxing-header unboxing_header mt-minus-100">
                    <div className="section-header text-center pt-50">
                      <h2 className="section-title">{t("store.mainTitle")}</h2>
                      <span className="section-sub-title">
                        {t("store.title")}
                      </span>
                      {/* <p>{t("openBoxes.paragraph")}</p> */}
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-12 wow fadeInUp"
                  data-wow-duration="0.5s"
                  data-wow-delay="0.3s"
                >
                  {/* <FilterComponent /> */}
                  <ProductFilter />
                </div>
              </div>
            </div>
          </section>

          <section className="pb-120">
            <div className="container">
              <div className="row mb-none-30 justify-content-center">
                <div className="col-lg-4 col-sm-6 mb-30">
                  <div className="icon-item2">
                    <div className="icon-item2__icon">
                      <img src={icon01} alt="icon" />
                    </div>
                    <div className="icon-item2__content">
                      <h3 className="title ar-heading">
                        {t("openBoxes.serviceHeading1")}
                      </h3>
                      {/* <p>{t("openBoxes.serviceTxt1")}</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 mb-30">
                  <div className="icon-item2">
                    <div className="icon-item2__icon">
                      <img src={icon02} alt="icon" />
                    </div>
                    <div className="icon-item2__content">
                      <h3 className="title ar-heading">
                        {t("openBoxes.serviceHeading2")}
                      </h3>
                      {/* <p>{t("openBoxes.serviceTxt2")}</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 mb-30">
                  <div className="icon-item2">
                    <div className="icon-item2__icon">
                      <img src={icon03} alt="icon" />
                    </div>
                    <div className="icon-item2__content">
                      <h3 className="title ar-heading">
                        {t("openBoxes.serviceHeading3")}
                      </h3>
                      {/* <p>{t("openBoxes.serviceTxt3")}</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Draggable onStart={onStart} onStop={onStop}>
          <div className="cart-box">
            <div className="wow animate__animated animate__fadeInDownBig animate__delay-0.5s">
              <div className="cart">
                <img src={cart} alt="cart" />
                <span>{totalCartItems() || 0}</span>
              </div>
              <Button onClick={handleCart}>{t("cart.goToCartTxt")}</Button>
            </div>
          </div>
        </Draggable>
      </Layout>
    </>
  );
};

export default Unboxing;
