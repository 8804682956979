import React, { useState, useRef, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./product.css";
// import Boxes from '../unboxing/Boxes'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getBrand, getProductsByCategory } from "../../reducers/userReducer";
import ProdList from "./prodList";
import Loader from "../Loader/loader";

const ProductFilter = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const serchInitialObj = {
    title: "",
    min: "",
    max: "",
    category: "",
    rarity: "",
    skip: 0,
    limit: 9,
    brandId: "",
    brandName: "Brand",
    sort: { price: -1 },
    sortType: t("openBoxes.sortTxt1"),
  };
  const result = useSelector((state) => state.user);
  const {
    productCategories,
    prodListByCategory,
    allBrands,
    productTotal,
    loading,
  } = result;
  // console.log('prodListByCategory=>', prodListByCategory)
  const [niceselect, setniceselect] = useState(true);
  const niceselecthandle = () => setniceselect(!niceselect);
  const [orderdropdown, setorderdropdown] = useState(true);
  const orderdropdownhandle = () => setorderdropdown(!orderdropdown);
  const wrapperRef = useRef(null);
  const wrapperRef1 = useRef(null);
  const [searchPayload, setSeachPayload] = useState(serchInitialObj);
  // const [skip, setSkip] = useState(0);
  const [hide, setHide] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  // const [loading,setLoading] = useState(false);

  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setniceselect(true);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter1(wrapperRef1);
  function useOutsideAlerter1(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setorderdropdown(true);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const handleSearchBox = async (name, value, nextValue) => {
    // console.log()
    // console.log('name, value=>', name, value)

    const copySearchPayLoad = { ...searchPayload };
    if (name === "brandId") {
      // if(value){
      const findName = allBrands.find((el) => el._id === value);
      copySearchPayLoad["brandName"] = findName?.name || "Brands";
      // }
    }
    if (name === "sort" && nextValue) {
      copySearchPayLoad["sortType"] = nextValue;
    }
    copySearchPayLoad[name] = value;
    copySearchPayLoad.skip = 0;
    copySearchPayLoad.limit = 9;
    delete copySearchPayLoad.newType;

    copySearchPayLoad.searchName = name;
    // console.log('copySearchPayload', copySearchPayLoad)
    setSeachPayload(copySearchPayLoad);
    dispatch(getProductsByCategory(copySearchPayLoad));
  };

  useEffect(() => {
    dispatch(getProductsByCategory(searchPayload));
    dispatch(getBrand());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (productTotal) {
      setHide(false);
      const newValue = searchPayload?.skip + 9;
      if (productTotal >= newValue) {
        setHide(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productTotal]);

  useEffect(() => {
    // setLoading(true)
    if (prodListByCategory?.length) {
      const copy = JSON.parse(JSON.stringify(prodListByCategory));
      const newCopy = copy.reduce((pre, next) => {
        const fIndex = pre?.findIndex((el) => el?._id === next?._id);
        if (fIndex === -1) {
          pre = [...pre, ...[next]];
        }
        return pre;
      }, []);
      setAllProducts(newCopy);
      // setLoading(false)
      if (prodListByCategory.length === productTotal) {
        setHide(false);
      }
    }
  }, [prodListByCategory, productTotal]);

  const handleLoadMore = (type) => {
    // console.log('productTotal',productTotal, prodListByCategory)
    const copySearchPayLoad = { ...searchPayload, newType: type };
    copySearchPayLoad.skip = prodListByCategory.length;
    copySearchPayLoad.limit = 3;
    // const newValue = copySearchPayLoad.skip + 9 + 24;
    // console.log("skip", newValue);
    // if (newValue >= productTotal) {
    //   setHide(true);
    // }
    // copySearchPayLoad.skip +=24;
    // console.log('copySearchPayLoad',copySearchPayLoad  )
    dispatch(getProductsByCategory(copySearchPayLoad));
    setSeachPayload(copySearchPayLoad);
  };

  //console.log('prodListByCategory',prodListByCategory)
  return (
    <div className="filter-tabs-section">
      <Tabs className="filter-tabs mt-minus-70 products-ctaegory">
        <div className="unboxing-header unboxing__header">
          <TabList className="filter-tablist">
            <Tab
              className="filter-tablist-links"
              onClick={() => handleSearchBox("category", "")}
            >
              <div className="category">All</div>
            </Tab>

            {productCategories &&
              productCategories.length > 0 &&
              productCategories.map((el) => (
                <Tab
                  className="filter-tablist-links"
                  onClick={() => handleSearchBox("category", el._id)}
                >
                  <div className="category">{el.name}</div>
                </Tab>
              ))}
          </TabList>
        </div>

        <div className="row contest-filter-wrapper mt-30 mb-none-30">
          <div className="col-lg-3 col-sm-6 mb-30">
            <div
              className={`${
                orderdropdown
                  ? "order-dropdown-list"
                  : "order-dropdown-list open"
              }`}
              onClick={orderdropdownhandle}
              ref={wrapperRef1}
            >
              <span className="current">{searchPayload?.sortType}</span>
              <ul className="list">
                <li
                  data-value="Filter option"
                  className="option"
                  onClick={() =>
                    handleSearchBox("sort", { createdAt: -1 }, "Newest")
                  }
                >
                  {t("openBoxes.sortTxt6")}
                </li>
                <li
                  data-value="Filter option"
                  className="option"
                  onClick={() =>
                    handleSearchBox("sort", { createdAt: 1 }, "Oldest")
                  }
                >
                  {t("openBoxes.sortTxt7")}
                </li>
                <li
                  data-value="Filter option"
                  className="option"
                  onClick={() =>
                    handleSearchBox("sort", { price: 1 }, "Price (Low to high)")
                  }
                >
                  {t("openBoxes.sortTxt4")}
                </li>
                <li
                  data-value="Filter option"
                  className="option"
                  onClick={() =>
                    handleSearchBox(
                      "sort",
                      { price: -1 },
                      "Price (High to Low)"
                    )
                  }
                >
                  {t("openBoxes.sortTxt5")}
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 mb-30">
            <div
              className={`${niceselect ? "nice-select" : "nice-select open"}`}
              onClick={niceselecthandle}
              ref={wrapperRef}
            >
              <span className="current">{searchPayload?.brandName}</span>
              <ul className="list">
                <li
                  data-value="Filter option"
                  className={`option ${
                    !searchPayload?.brandId ? "selected focus" : ""
                  }`}
                  onClick={() => handleSearchBox("brandId", "")}
                >
                  All
                </li>
                {allBrands?.map((el) => (
                  <li
                    data-value="Filter option"
                    className={`option ${
                      el._id === searchPayload?.brandId ? "selected focus" : ""
                    }`}
                    onClick={() => handleSearchBox("brandId", el._id)}
                  >
                    {el.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="max-option col-lg-3 col-sm-6 lg:mb-30 row">
            <div className="col-lg-6 col-sm-6">
              <input
                type="number"
                placeholder={t("boxDetails.minTxt")}
                name="min"
                value={searchPayload?.min}
                onChange={(e) => handleSearchBox(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-lg-6 col-sm-6">
              <input
                type="number"
                placeholder={t("boxDetails.maxTxt")}
                name="max"
                value={searchPayload?.max}
                onChange={(e) => handleSearchBox(e.target.name, e.target.value)}
              />
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 mb-30">
            <form
              className="contest-search-form"
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                type="text"
                name="title"
                placeholder={t("openBoxes.searchTxt")}
                onChange={(e) => handleSearchBox(e.target.name, e.target.value)}
              />
              <button>
                <i className="fa fa-search"></i>
              </button>
            </form>
          </div>
        </div>
        <TabPanel>
          <ProdList
            products={allProducts}
            handleLoadMore={handleLoadMore}
            hide={hide}
          />
        </TabPanel>
        {productCategories &&
          productCategories.length > 0 &&
          productCategories.map((el) => (
            <TabPanel>
              <ProdList
                products={allProducts}
                handleLoadMore={handleLoadMore}
                hide={hide}
              />
            </TabPanel>
          ))}
      </Tabs>
      {loading && <Loader />}
    </div>
  );
};
export default ProductFilter;
