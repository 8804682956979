import React from 'react'
import player1 from '../../assets/images/user/user-icon.png'
const FourRouletteResult = ({ joinUser,round }) => {
  let addResultBoxCount = round && round >10?round : 10;
  let addCount = Math.round(addResultBoxCount/2);
  return (
    <>
        <div className='players-length three-palyers'>
          {[...Array(parseInt(4))].map(
            (elementInArray, index) => {
           const userData = joinUser?.find((el) => el?.position === index);
           const { userId, winStatus } = userData || {};
           const classname =
            index === 0 ? "first-player" : index === 3 ? "last-player" : "";
          const resultclass =
            winStatus === "los"
              ? "lose-player"
              : winStatus === "win"
              ? "win-player"
              : "";
           return( 
           <>
           {
             userData &&  userId?
             <div  className={`players player-one ${classname} ${resultclass}`}>
              <img src={userId?.profile || player1} alt='player1' />
              {userId?.firstname}
            </div>
             :
             <div className={`players player-one ${classname}`}>
              Waiting for player
              </div>
           }
          </>
           )
           }
          )}
      </div>
      <div className='players-battle-details'>
      <div className="row">
        <div className='col-sm-3'>
        {[...Array(parseInt(addCount))].map(
          (elementInArray, index) => {
           const userData = joinUser?.find((el) => el?.position === 0);
           const { result} = userData || {}
           const chunkArray = result?.slice(index * 2, index * 2 + 2)
          return(   
        <div className='all-details three-player-details'>
         <div className='details-section details-section-one wow animate__animated animate__zoomIn'>
         {[...Array(parseInt(2))].map(
            (elementInArray, index2) => {
              const findResult = chunkArray && chunkArray[index2];
              let classname = index2 === 1?'right-details-section':'left-details-section';
              return(
                <>
                {
                  findResult ?<div className={classname}>
                  <img src={findResult?.pid?.bannerImage|| ''} alt='box' />
                  <h5>{findResult?.pid?.name|| ''}</h5>
                  <p>{findResult?.pid?.brandId?.name|| ''}</p>
                  <h6>{findResult?.pid?.price|| ''} DT</h6>
                </div>:
                <div className={classname}>
              </div>
                }
                </>
                
              )
            })}
          </div>
        </div>
         )
          })}
        </div>
        <div className='col-sm-3'>
        {[...Array(parseInt(addCount))].map(
          (elementInArray, index) => {
           const userData = joinUser?.find((el) => el?.position === 1);
           const { result} = userData || {}
           const chunkArray = result?.slice(index * 2, index * 2 + 2)
          return(   
        <div className='all-details three-player-details'>
         <div className='details-section details-section-one wow animate__animated animate__zoomIn'>
         {[...Array(parseInt(2))].map(
            (elementInArray, index2) => {
              const findResult = chunkArray && chunkArray[index2];
              let classname = index2 === 1?'right-details-section':'left-details-section';
              return(
                <>
                {
                  findResult ?<div className={classname}>
                  <img src={findResult?.pid?.bannerImage|| ''} alt='box' />
                  <h5>{findResult?.pid?.name|| ''}</h5>
                  <p>{findResult?.pid?.brandId?.name|| ''}</p>
                  <h6>{findResult?.pid?.price|| ''} DT</h6>
                </div>:
                <div className={classname}>
              </div>
                }
                </>
                
              )
            })}
          </div>
        </div>
         )
          })}
        </div>
        <div className='col-sm-3'>
        {[...Array(parseInt(addCount))].map(
          (elementInArray, index) => {
           const userData = joinUser?.find((el) => el?.position === 2);
           const { result} = userData || {}
           const chunkArray = result?.slice(index * 2, index * 2 + 2)
          return(   
        <div className='all-details three-player-details'>
         <div className='details-section details-section-one wow animate__animated animate__zoomIn'>
         {[...Array(parseInt(2))].map(
            (elementInArray, index2) => {
              const findResult = chunkArray && chunkArray[index2];
              let classname = index2 === 1?'right-details-section':'left-details-section';
              return(
                <>
                {
                  findResult ?<div className={classname}>
                  <img src={findResult?.pid?.bannerImage|| ''} alt='box' />
                  <h5>{findResult?.pid?.name|| ''}</h5>
                  <p>{findResult?.pid?.brandId?.name|| ''}</p>
                  <h6>{findResult?.pid?.price|| ''} DT</h6>
                </div>:
                <div className={classname}>
              </div>
                }
                </>
                
              )
            })}
          </div>
        </div>
         )
          })}
        </div>

        <div className='col-sm-3'>
        {[...Array(parseInt(addCount))].map(
          (elementInArray, index) => {
           const userData = joinUser?.find((el) => el?.position === 3);
           const { result} = userData || {}
           const chunkArray = result?.slice(index * 2, index * 2 + 2)
          return(   
        <div className='all-details three-player-details'>
         <div className='details-section details-section-one wow animate__animated animate__zoomIn'>
         {[...Array(parseInt(2))].map(
            (elementInArray, index2) => {
              const findResult = chunkArray && chunkArray[index2];
              let classname = index2 === 1?'right-details-section':'left-details-section';
              return(
                <>
                {
                  findResult ?<div className={classname}>
                  <img src={findResult?.pid?.bannerImage|| ''} alt='box' />
                  <h5>{findResult?.pid?.name|| ''}</h5>
                  <p>{findResult?.pid?.brandId?.name|| ''}</p>
                  <h6>{findResult?.pid?.price|| ''} DT</h6>
                </div>:
                <div className={classname}>
              </div>
                }
                </>
                
              )
            })}
          </div>
        </div>
         )
          })}
        </div>
        </div>
      </div>
    </>
  )
}

export default FourRouletteResult
