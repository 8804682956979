import React from 'react';
const RollItems = ({ itemsArr, winItem, active }) => {
	return (
		<React.Fragment>
			{itemsArr &&
				itemsArr.length &&
				itemsArr.map((el, index) => (
					<div
						id={index}
						className="game-bet-box dark-box"
						style={{
							background: `linear-gradient(180deg, #041f2d 50%, ${(((index === 108 || index === 106) && active === '1') ||
									(index === 106 && active !== '1')) &&
								winItem?.pid?.rarityLevel?.color
									? winItem?.pid?.rarityLevel?.color:el?.pid?.rarityLevel?.color}40 100%)`,
							borderBottomColor: `${(((index === 108 || index === 106) && active === '1') ||
									(index === 106 && active !== '1')) &&
								winItem?.pid?.rarityLevel?.color
									? winItem?.pid?.rarityLevel?.color:el?.pid?.rarityLevel?.color}90`,
						}}
					>
						{}
						<img
							src={
								(((index === 108 || index === 106) && active === '1') || 
									(index === 106 && active !== '1')) &&
								winItem?.pid?.bannerImage
									? winItem?.pid?.bannerImage
									: el?.pid?.bannerImage
							}
							alt=""
						/>
						<div className="item-info">
							<h5>
								{(((index === 108 || index === 106) && active === '1') ||
									(index === 106 && active !== '1')) &&
								winItem?.pid?.name
									? `${winItem?.pid?.name}`
									: `${el?.pid?.name}`}
							</h5>
							<span className="role_price">
								{' '}
								{(((index === 108 || index === 106 ) && active === '1') ||
									(index === 106 && active !== '1')) &&
								winItem?.pid?.name
									? `${winItem.pid?.brandId?.name || ''}`
									: `${el?.pid?.brandId?.name || ''}`}
							</span>
						</div>
					</div>
				))}
		</React.Fragment>
	);
};
export default RollItems;
