import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import player1 from "../../assets/images/user/user-icon.png";
import { getBattles, updateAllBattle } from "../../reducers/userReducer";
import "./battles.css";
import { socket } from "../../config/socket";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BattlePassword from "./battlePassword";
import { includeToFixed } from "../../utils/useFunc";

const BattlesTable = ({ sortTye }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const result = useSelector((state) => state.user);
  const { allBattles, userDetails } = result || {};
  const userId = userDetails?._id;
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState("");
  const { t } = useTranslation();
  const [checkNewOne, setNewOne] = useState(false);
  //console.log('my battle list==>', sortTye, privateBattlesList)
  useEffect(() => {
    dispatch(getBattles({ userId, sortTye }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, sortTye]);

  useEffect(() => {
    socket.on("NewBattle", (data) => {
      setNewOne(true);
      // console.log('helo test')
      //dispatch(updateBattle(data))
      dispatch(getBattles({ userId, sortTye }));
      setTimeout(() => {
        setNewOne(false);
      }, 800);
    });
    socket.on("activeBattleList", (data) => {
      setNewOne(true);
      setTimeout(() => {
        setNewOne(false);
      }, 800);
      dispatch(updateAllBattle(data));
    });

    return () => {
      socket.off("NewBattle");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  //console.log('privateBattlesList=>', privateBattlesList)
  const handleWatchBattle = (type, joinLink) => {
    if (type === "private") {
      setShow(true);
    } else {
      history.push(`/watch-battle/?id=${joinLink}`);
    }
  };
  const handleBattlePassOpen = () => {
    setShow(!show);
  };
  const handleChangePass = (e) => {
    const { value } = e.target;
    setPassword(value);
  };
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      if (password !== "") {
        setErrors({});
        //const res = await userInstance().post('/api/user/verifyBattlePassword' )
      } else {
        const errormsg = {
          password: "This field is required",
        };
        setErrors(errormsg);
      }
    } catch (err) {
      // console.log('error', err);
    }
  };
  return (
    <>
      <Table className="table battle-table" responsive>
        <thead>
          <tr>
            <th>{t("battleTable.tabletxt1")}</th>
            <th>{t("battleTable.tabletxt2")}</th>
            <th>{t("battleTable.tabletxt3")}</th>
            <th>{t("battleTable.tabletxt4")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {/* {privateBattlesList.map((el)=> 
        {
        const testArr = el?.joinUser.slice().sort((a, b) => a.position - b.position)
        return (<tr className="wow animate__animated animate__fadeInUp">
          <td className={el?.status === 'running'?'circle-active':''}>
            <div className="cirlce-section">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class=""
                viewBox="0 1 448 515"
              >
                <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
              </svg>
              <span>{el.round}</span>
              <p>{el?.status === 'running'?'Running':''}</p>
            </div>
          </td>

          <td>
            <div className = 'battle-slider-section'>
            <div className="battle-slider">
            {el.selectedBoxes.map((ele,index)=>
              <div className="battle-img">
              <img src={ele.image} alt="battle" />
              <span>{ele.boxName}</span>
              </div>)}
            </div>
            </div>
          </td>

          <td>{el.totalbattleCost?.toFixed(2)} DT</td>
          <td>
            <div className="players-section">
              <h6 className="no-of-players">1/{el?.players}</h6>
              <div className="players-img">
              <>
                   {
                    testArr && testArr.map(el2=>(
                      <img src={el2?.userId?.profile || player1} alt="player1" />
                    ))
                   }
                  </>
                  <>
                  {
                  [...Array(parseInt(el?.players - el?.joinUser.length))].map((elementInArray, index) => ( 
                    <img src={player1} alt="player1" />))
                  }
                   </> 
                 
              </div>
            </div>
          </td>
          <td>
            <Link to={`/watch-battle/?id=${el.joinLink}`} className="cmn-btn">
              Watch Battle
            </Link>
          </td>
        </tr>
        )})} */}
          {allBattles.map((el, index) => {
            const testArr = el?.joinUser
              .slice()
              .sort((a, b) => a.position - b.position);
            const totalbattleCost = includeToFixed(el?.totalbattleCost);

            return (
              <tr
                className={`${
                  index === 0 && checkNewOne
                    ? "wow animate__animated animate__fadeInUp"
                    : ""
                }`}
              >
                <td className={el?.status === "running" ? "circle-active" : ""}>
                  <div className="cirlce-section">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      class=""
                      viewBox="0 1 448 515"
                    >
                      <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
                    </svg>
                    <span>{el.round}</span>
                  </div>
                  <p>{el?.status === "running" ? "Running" : ""}</p>
                </td>

                <td>
                  <div className="battle-slider-section">
                    <div className="battle-slider">
                      {el.selectedBoxes.map((ele, index) => (
                        <div className="battle-img">
                          <img src={ele.image} alt="battle" />
                          <span>{ele.boxName}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </td>

                <td>{totalbattleCost} DT</td>
                <td>
                  <div className="players-section">
                    <h6 className="no-of-players">1/{el?.players}</h6>
                    <div className="players-img">
                      <>
                        {testArr &&
                          testArr?.map((el2) => (
                            <img
                              src={el2?.userId?.profile || player1}
                              alt="player1"
                            />
                          ))}
                      </>
                      <>
                        {[
                          ...Array(parseInt(el?.players - el?.joinUser.length)),
                        ].map((elementInArray, index) => (
                          <img src={player1} alt="player1" />
                        ))}
                      </>
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    onClick={() => handleWatchBattle(el.joinType, el.joinLink)}
                    className="cmn-btn watch-battle-btn"
                  >
                    Watch Battle
                  </div>
                </td>
              </tr>
            );
          })}
          {/* <tr>
          <td>
            <div className="cirlce-section">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class=""
                viewBox="0 1 448 515"
              >
                <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
              </svg>
              <span>2</span>
            </div>
          </td>
          <td>
            <div className="battle-slider">
              <div className="battle-img">
                <img src={battle} alt="battle" />
                <span>Milspec</span>
              </div>
              <div className="battle-img">
                <img src={battle} alt="battle" />
                <span>Milspec</span>
              </div>
            </div>
          </td>
          <td>10.09 $</td>
          <td>
            <div className="players-section">
              <h6 className="no-of-players">1/4</h6>
              <div className="players-img">
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
              </div>
            </div>
          </td>
          <td>
            <Link to="/watch-battle" className="cmn-btn">
              Watch Battle
            </Link>
          </td>
        </tr>
        <tr>
          <td className="circle-active">
            <div className="cirlce-section">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class=""
                viewBox="0 1 448 515"
              >
                <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
              </svg>
              <span>3</span>
            </div>
            <p>Running</p>
          </td>
          <td>
            <div className="battle-slider-section">
              <div className="battle-slider">
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
              </div>
            </div>
          </td>
          <td>10.09 $</td>
          <td>
            <div className="players-section">
              <h6 className="no-of-players">1/4</h6>
              <div className="players-img">
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
              </div>
            </div>
          </td>
          <td>
            <Link to="/watch-battle" className="cmn-btn">
              Watch Battle
            </Link>
          </td>
        </tr>
        <tr>
          <td>
            <div className="cirlce-section">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class=""
                viewBox="0 1 448 515"
              >
                <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
              </svg>
              <span>1</span>
            </div>
          </td>
          <td>
            <div className="battle-img">
              <img src={battle} alt="battle" />
              <span>Milspec</span>
            </div>
          </td>
          <td>10.09 $</td>
          <td>
            <div className="players-section">
              <h6 className="no-of-players">1/4</h6>
              <div className="players-img">
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
              </div>
            </div>
          </td>
          <td>
            <Link to="/watch-battle" className="cmn-btn">
              Watch Battle
            </Link>
          </td>
        </tr>
        <tr>
          <td>
            <div className="cirlce-section">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class=""
                viewBox="0 1 448 515"
              >
                <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
              </svg>
              <span>2</span>
            </div>
          </td>
          <td>
            <div className="battle-slider">
              <div className="battle-img">
                <img src={battle} alt="battle" />
                <span>Milspec</span>
              </div>
              <div className="battle-img">
                <img src={battle} alt="battle" />
                <span>Milspec</span>
              </div>
            </div>
          </td>
          <td>10.09 $</td>
          <td>
            <div className="players-section">
              <h6 className="no-of-players">1/4</h6>
              <div className="players-img">
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
              </div>
            </div>
          </td>
          <td>
            <Link to="/watch-battle" className="cmn-btn">
              Watch Battle
            </Link>
          </td>
        </tr>
        <tr>
          <td className="circle-active">
            <div className="cirlce-section">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class=""
                viewBox="0 1 448 515"
              >
                <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
              </svg>
              <span>3</span>
            </div>
            <p>Running</p>
          </td>
          <td>
            <div className="battle-slider-section">
              <div className="battle-slider">
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
              </div>
            </div>
          </td>
          <td>10.09 $</td>
          <td>
            <div className="players-section">
              <h6 className="no-of-players">1/4</h6>
              <div className="players-img">
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
              </div>
            </div>
          </td>
          <td>
            <Link to="/watch-battle" className="cmn-btn">
              Watch Battle
            </Link>
          </td>
        </tr>
        <tr>
          <td>
            <div className="cirlce-section">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class=""
                viewBox="0 1 448 515"
              >
                <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
              </svg>
              <span>1</span>
            </div>
          </td>
          <td>
            <div className="battle-img">
              <img src={battle} alt="battle" />
              <span>Milspec</span>
            </div>
          </td>
          <td>10.09 $</td>
          <td>
            <div className="players-section">
              <h6 className="no-of-players">1/4</h6>
              <div className="players-img">
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
              </div>
            </div>
          </td>
          <td>
            <Link to="/watch-battle" className="cmn-btn">
              Watch Battle
            </Link>
          </td>
        </tr>
        <tr>
          <td>
            <div className="cirlce-section">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class=""
                viewBox="0 1 448 515"
              >
                <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
              </svg>
              <span>2</span>
            </div>
          </td>
          <td>
            <div className="battle-slider">
              <div className="battle-img">
                <img src={battle} alt="battle" />
                <span>Milspec</span>
              </div>
              <div className="battle-img">
                <img src={battle} alt="battle" />
                <span>Milspec</span>
              </div>
            </div>
          </td>
          <td>10.09 $</td>
          <td>
            <div className="players-section">
              <h6 className="no-of-players">1/4</h6>
              <div className="players-img">
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
              </div>
            </div>
          </td>
          <td>
            <Link to="/watch-battle" className="cmn-btn">
              Watch Battle
            </Link>
          </td>
        </tr>
        <tr>
          <td className="circle-active">
            <div className="cirlce-section">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class=""
                viewBox="0 1 448 515"
              >
                <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
              </svg>
              <span>3</span>
            </div>
            <p>Running</p>
          </td>
          <td>
            <div className="battle-slider-section">
              <div className="battle-slider">
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
              </div>
            </div>
          </td>
          <td>10.09 $</td>
          <td>
            <div className="players-section">
              <h6 className="no-of-players">1/4</h6>
              <div className="players-img">
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
              </div>
            </div>
          </td>
          <td>
            <Link to="/watch-battle" className="cmn-btn">
              Watch Battle
            </Link>
          </td>
        </tr> */}
        </tbody>
      </Table>
      <BattlePassword
        show={show}
        from="battlelist"
        handleBattlePassOpen={handleBattlePassOpen}
        onSubmitHandler={onSubmitHandler}
        handleChangePass={handleChangePass}
        errors={errors}
      />
    </>
  );
};

export default BattlesTable;
