import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import runpay from "../../assets/images/depositpopup/runpay.svg";
import flouci from "../../assets/images/depositpopup/flouci.png";
import gift from "../../assets/images/depositpopup/gift-voucher.png";
import sobflous from "../../assets/images/depositpopup/sobflous.png";
import swared from "../../assets/images/depositpopup/swared.png";
import visa from "../../assets/images/depositpopup/bancaire.png";
import badges from "../../assets/images/depositpopup/badges.png";
import edinar from "../../assets/images/depositpopup/postale.png";

// import PaymentPopup from "../PopUp/paymentPopup";
import "./popup.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createPayment,
  incrementWalletAmount,
  getLoginUser,
} from "../../reducers/userReducer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { getReffreeBonus, chekUserDepositeStatus } from "../../utils/useFunc";
import { toast } from "react-toastify";

const DepositPopup = ({ show, handleClose, requiredAmount }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const result = useSelector((state) => state.user);
  const { userDetails, adminSetting, boxDetails } = result || {};
  const { name } = boxDetails || {};
  const [balance, setBalance] = useState("");
  const [payment, setPayment] = useState(false);
  const [errors, setErrors] = useState("");

  const [paymentValue, setpaymentValue] = useState();
  const AffiliateLevel = userDetails?.affiliateLevel || "";
  const refereeCommissionType = AffiliateLevel?.refereeCommissionType || "";
  const bonus = AffiliateLevel?.refereeBonus || "";
  if (balance) {
    var value = getReffreeBonus(refereeCommissionType, bonus, balance);
    var totalValue = balance + value;
  }

  const xDepositBonusCount=AffiliateLevel?.xDepositBonusCount?AffiliateLevel?.xDepositBonusCount:"";
  const bounseType = AffiliateLevel?.refereeCommissionType === 'percentage'?'%' :'DT';
  let bonusLevelMessage= AffiliateLevel?.bonusRule==="firstDeposit"?`Vous avez un bonus de ${bonus} ${bounseType}  sur votre premier dépôt!`:AffiliateLevel?.bonusRule==="allDeposits"? `Vous avez un bonus de ${bonus} ${bounseType} sur tous vos dépôt!`:AffiliateLevel?.bonusRule==="xDeposit"?`Vous avez un bonus de ${bonus} ${bounseType} sur vos ${xDepositBonusCount} premiers dépôts!`:" ";

  if (userDetails) {
    var checkUserDepositStatus = chekUserDepositeStatus(
      AffiliateLevel?.bonusRule,
      userDetails,
      Number(xDepositBonusCount)
    );
  }

  const handleEnterBlance = (val) => {
    setBalance(Number(val));
  };
  const handleBlance = (val) => {
    setBalance(Number(val));
  };
  const submitPayment = async (amount) => {
    console.log("parseInt(amount)", parseInt(amount));
    try {
      if (!amount || parseInt(amount) <= 0) {
        setErrors(t("errors.enterAmount"));
        return;
      }
      setErrors("");
      const payload = {
        amount: parseFloat(amount),
        userId: userDetails?._id,
        type: "deposit",
        debitCreditType: "credit",
        paymentType: paymentValue,
        description: "deposit",
        descriptionMessage: "Add Funds in the Wallet",
      };
      const res = await dispatch(incrementWalletAmount(payload));
      const { code } = res?.payload?.resultData?.data;
      if (code === 200) {
        toast.success(t("toast.headersuccess"), {
          toastId: "battles",
        });
        dispatch(getLoginUser());
        setPayment(false);
        handleClose();
      }
    } catch (err) {
      console.log("errerrerrerr===>", err);
    }
  };
  const handlePayment = (payValue) => {
    setpaymentValue(payValue);

    setPayment(!payment);
    // handleClose()
  };

  const handlePaymentClose = () => {
    handleClose();
    setPayment(false);
  };

  const handlePaymentBack = () => {
    setPayment(false);
  };
  //console.log('userDetails===>', userDetails)
  const handlePayWithOther = (amount) => {
    if (!amount || parseInt(amount) <= 0) {
      setErrors(t("errors.enterAmount"));
      return;
    }
    setErrors("");
    const payload = {
      totalAmount: balance,
      userData: {
        firstname: userDetails?.username,
        lastname: userDetails?.lastname,
        email: userDetails?.email,
        phoneNumber: userDetails?.mobile,
      },
      type: "deposit",
      notOrder: true,
      url: window.location.href,
    };
    dispatch(createPayment(payload));
  };
  return (
    <>
      <Modal
        show={show}
        size="xl"
        centered
        onHide={handleClose}
        className={`modal fade login-popup deposite-popup`}
        id="loginModal"
        tabindex="1"
        role="dialog"
        aria-hidden="true"
      >
        {!payment ? (
          <div className="modal-content modal_contents boxadd-model-page deposit-model">
            <div className="modal-body">
              <div className="modal_header">
                <div className="case_add">
                  <h4>{t("form.depositTxt")}</h4>
                </div>
                {/* <div className="search">
                  <i class="las la-search"></i>
                  <input placeholder="Lookup cases" type="search" />
                </div> */}
                <div className="close_pup_btn">
                  <button
                    onClick={handleClose}
                    type="button"
                    className="close-btn"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="las la-times"></i>
                  </button>
                </div>
              </div>
              <div className="account-form-area">
                <div className="deposit-popup-page">
                  <div className="deposit-popup-left">
                    <div className="deposit-popup-heading">
                      <h3>{t("form.refBalTxt")}</h3>
                    </div>
                    <div className="deposit-select-method">
                      <div className="deposit-select-heading">
                        <span>1.</span>
                        <p>{t("form.selectTxt")}</p>
                      </div>
                      <div className="deposit-box">
                        {adminSetting?.giftVoucher && (
                          <div
                            className="deposit-select-box"
                            onClick={() => handlePayment("Gift Voucher")}
                          >
                            <img src={gift} alt="payicon" />
                            <span>{t("form.giftTxt")}</span>
                          </div>
                        )}
                        {/* {console.log("adminSetting?.visaMasterCard===>",adminSetting)} */}
                        {adminSetting?.visaMasterCard && (
                          <div
                            className="deposit-select-box"
                            onClick={() => handlePayment("Visa MasterCard")}
                          >
                            <img src={visa} alt="payicon" />
                            <span>Carte bancaire</span>
                          </div>
                        )}

                        {adminSetting?.runPay && (
                          <div
                            className="deposit-select-box"
                            onClick={() => handlePayment("Run Pay")}
                          >
                            <img src={runpay} alt="payicon" />
                            <span>{t("form.runPayTxt")}</span>
                          </div>
                        )}

                        {adminSetting?.flauci && (
                          <div
                            className="deposit-select-box"
                            onClick={() => handlePayment("Flouci")}
                          >
                            <img src={flouci} alt="payicon" />
                            <span>flouci</span>
                          </div>
                        )}

                        {adminSetting?.sabflous && (
                          <div
                            className="deposit-select-box"
                            onClick={() => handlePayment("SobFlous")}
                          >
                            <img src={sobflous} alt="payicon" />
                            <span>sobflous</span>
                          </div>
                        )}
                        {adminSetting?.swared && (
                          <div
                            className="deposit-select-box"
                            onClick={() => handlePayment("Swared")}
                          >
                            <img src={swared} alt="payicon" />
                            <span>swared</span>
                          </div>
                        )}

                        {adminSetting?.edinar && (
                          <div
                            className="deposit-select-box"
                            onClick={() => handlePayment("Carte e-dinar")}
                          >
                            <img src={edinar} alt="payicon" />
                            <span>Carte e-dinar</span>
                          </div>
                        )}
                      </div>
                      <div className="deposit-payment-history">
                        <span>
                          BOXI.TN est exploité par Sté ATCOM DISTRIBUTION SARL,
                          une société de droit tunisien inscrite au registre de
                          commerce et de matricule fiscal N° 1742899P.
                        </span>
                        <Link
                          to="/profile?tab=transactions"
                          className="deposit-payment"
                        >
                          <i class="las la-money-check"></i>
                          <p>{t("form.payHistryTxt")}</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="deposit-popup-right">
                    <div className="deposit-popup-right-heading">
                      <i class="las la-check-circle"></i>
                      <h4>Paiement sécurisé</h4>
                    </div>
                    <div className="deposit-popup-right-content">
                      <div className="deposit-popup-right-text">
                        <ul>
                          <li>
                            BOXI prend votre sécurité au sérieux : Vos achats
                            sur BOXI sont totalement sécurisés grâce à la mise
                            en place de systèmes de contrôle et de sécurisation
                            des paiements. De nombreux partenaires de confiance
                            participent avec nous à la mise en oeuvre d'une
                            protection optimale de vos données et de votre
                            identité sur notre site.
                          </li>
                        </ul>
                        <div className="badges-img">
                          <img src={badges} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="modal-content modal_contents boxadd-model-page deposit-model">
            <div className="modal-body">
              <div className="modal_header">
                <div className="case_add">
                  {/* <h4>Select Another Method</h4> */}
                  <div className="back-method" onClick={handlePaymentBack}>
                    <i class="las la-angle-left"></i>
                    <h3>{t("form.depositMethod")}</h3>
                  </div>
                </div>

                {/* <div className="search">
                 <i class="las la-search"></i>
                 <input placeholder="Lookup cases" type="search" />
               </div> */}
                <div className="close_pup_btn">
                  <button
                    onClick={handlePaymentClose}
                    type="button"
                    className="close-btn"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="las la-times"></i>
                  </button>
                </div>
              </div>
              <div className="account-form-area">
                <div className="deposit-popup-page">
                  <div className="deposit-popup-left">
                    <div className="deposit-popup-heading bonus-text">
                      <h3>
                        {t("form.paywithTxt")}{" "}
                        {paymentValue === "Visa MasterCard"
                          ? "Carte bancaire"
                          : paymentValue}
                      </h3>
                      {checkUserDepositStatus && (
                        <div className="bonus-level-heading">
                          <h3>
                            { bonusLevelMessage }
                            <span>
                              {/* {refereeCommissionType === "percentage"
                                ? `${bonus}%`
                                : bonus} */}
                            </span>
                          </h3>
                        </div>
                      )}
                    </div>
                    <div className="deposit-select-method">
                      {requiredAmount && (
                        <div className="deposit-amount-show">
                          <p>
                            {t("openBoxes.topUp")} {"  "}{" "}
                            {`${
                              requiredAmount
                                ? ` ${requiredAmount.toFixed(2)}  DT`
                                : "Fill Up"
                            }`}{" "}
                            {t("openBoxes.toOpen")} {name}
                          </p>
                        </div>
                      )}

                      <div className="payment-select-heading">
                        <div className="select-method">
                          <span>2.</span>
                          <p>{t("form.selectAmtTxt")}</p>
                        </div>

                        {/* <div className='add-promo'>
                     <p>add promocode</p>
                   </div> */}
                      </div>
                      <div className="deposit-box">
                        <div
                          className="payment-select-box"
                          onClick={() => handleBlance(5)}
                        >
                          {checkUserDepositStatus && (
                            <span className="bonus-money">
                              <i class="fa fa-plus" aria-hidden="true"></i>
                              {getReffreeBonus(
                                refereeCommissionType,
                                bonus,
                                5
                              )}{" "}
                              DT
                            </span>
                          )}
                          <span className="deposite-money">5</span> DT
                        </div> 
                        <div
                          className="payment-select-box"
                          onClick={() => handleBlance(25)}
                        >
                          {checkUserDepositStatus && (
                            <span className="bonus-money">
                              <i class="fa fa-plus" aria-hidden="true"></i>
                              {getReffreeBonus(
                                refereeCommissionType,
                                bonus,
                                25
                              )}{" "}
                              DT
                            </span>
                          )}
                          <span className="deposite-money">25</span> DT
                        </div>
                        <div
                          className="payment-select-box"
                          onClick={() => handleBlance(50)}
                        >
                          {checkUserDepositStatus && (
                            <span className="bonus-money">
                              <i class="fa fa-plus" aria-hidden="true"></i>
                              {getReffreeBonus(
                                refereeCommissionType,
                                bonus,
                                50
                              )}{" "}
                              DT
                            </span>
                          )}
                          <span className="deposite-money">50</span> DT
                        </div>
                        <div
                          className="payment-select-box"
                          onClick={() => handleBlance(100)}
                        >
                          {checkUserDepositStatus && (
                            <span className="bonus-money">
                              <i class="fa fa-plus" aria-hidden="true"></i>
                              {getReffreeBonus(
                                refereeCommissionType,
                                bonus,
                                100
                              )}{" "}
                              DT
                            </span>
                          )}
                          <span className="deposite-money">100</span> DT
                        </div>
                        <div
                          className="payment-select-box"
                          onClick={() => handleBlance(250)}
                        >
                          {checkUserDepositStatus && (
                            <span className="bonus-money">
                              <i class="fa fa-plus" aria-hidden="true"></i>
                              {getReffreeBonus(
                                refereeCommissionType,
                                bonus,
                                250
                              )}{" "}
                              DT
                            </span>
                          )}
                          <span className="deposite-money">250</span> DT
                        </div>
                        <div
                          className="payment-select-box"
                          onClick={() => handleBlance(500)}
                        >
                          {checkUserDepositStatus && (
                            <span className="bonus-money">
                              <i class="fa fa-plus" aria-hidden="true"></i>
                              {getReffreeBonus(
                                refereeCommissionType,
                                bonus,
                                500
                              )}{" "}
                              DT
                            </span>
                          )}
                          <span className="deposite-money">500</span> DT
                        </div>
                        <div
                          className="payment-select-box"
                          onClick={() => handleBlance(750)}
                        >
                          {checkUserDepositStatus && (
                            <span className="bonus-money">
                              <i class="fa fa-plus" aria-hidden="true"></i>
                              {getReffreeBonus(
                                refereeCommissionType,
                                bonus,
                                750
                              )}{" "}
                              DT
                            </span>
                          )}
                          <span className="deposite-money">750</span> DT
                        </div>
                        <div
                          className="payment-select-box"
                          onClick={() => handleBlance(1000)}
                        >
                          {checkUserDepositStatus && (
                            <span className="bonus-money">
                              <i class="fa fa-plus" aria-hidden="true"></i>
                              {getReffreeBonus(
                                refereeCommissionType,
                                bonus,
                                1000
                              )}{" "}
                              DT
                            </span>
                          )}
                          <span className="deposite-money">1000</span> DT
                        </div>
                      </div>
                      <div className="payment-option">
                        <div className="payment-input">
                          <input
                            type="text"
                            value={balance}
                            placeholder={t("form.entrAmtTxt")}
                            onChange={(e) => handleEnterBlance(e.target.value)}
                          />
                          {errors && <p className="text-danger">{errors}</p>}
                          {/* <span>Enter amount from 1 DT to 1000 DT</span> */}
                          <p>DT</p>

                          {checkUserDepositStatus && balance && (
                            <span className="recieve-bonus-money">{`${t(
                              "form.recieveMoney"
                            )} ${totalValue} DT /+ ${
                              refereeCommissionType === "percentage"
                                ? bonus + "%"
                                : bonus
                            }`}</span>
                          )}
                        </div>

                        <div className="payment-btn-option">
                          {paymentValue === "Visa MasterCard" ||
                          paymentValue === "Flouci" ||
                          paymentValue === "Carte e-dinar" ? (
                            <div
                              className="btn-style"
                              onClick={() => handlePayWithOther(balance)}
                            >
                              <span>
                                {t("form.payTxt")} {balance} DT
                              </span>
                            </div>
                          ) : (
                            <div
                              className="btn-style"
                              onClick={() => submitPayment(balance)}
                            >
                              <span>
                                {t("form.payTxt")} {balance} DT
                              </span>
                            </div>
                          )}

                          {/* <div className='payment-btn-text'>
                       <p>
                         Attention! Payments could be delayed for 5-10 minutes!
                       </p>
                     </div> */}
                        </div>
                      </div>
                      <div className="deposit-payment-history">
                        <span>
                          BOXI.TN est exploité par Sté ATCOM DISTRIBUTION SARL,
                          une société de droit tunisien inscrite au registre de
                          commerce et de matricule fiscal N° 1742899P.
                        </span>
                        <Link
                          to="/profile?tab=transactions"
                          className="deposit-payment"
                        >
                          <i class="las la-money-check"></i>
                          <p>{t("form.payHistryTxt")}</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="deposit-popup-right">
                    <div className="deposit-popup-right-heading">
                      <i class="las la-check-circle"></i>
                      <h4>Paiement sécurisé</h4>
                    </div>
                    <div className="deposit-popup-right-content">
                      <div className="deposit-popup-right-text">
                        <ul>
                          <li>
                            BOXI prend votre sécurité au sérieux : Vos achats
                            sur BOXI sont totalement sécurisés grâce à la mise
                            en place de systèmes de contrôle et de sécurisation
                            des paiements. De nombreux partenaires de confiance
                            participent avec nous à la mise en oeuvre d'une
                            protection optimale de vos données et de votre
                            identité sur notre site.
                          </li>
                        </ul>
                        <div className="badges-img">
                          <img src={badges} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
      {/* <PaymentPopup show={payment} requiredAmount={requiredAmount} handleClose={handlePayment} handleBlance={handleBlance} handleEnterBlance={handleEnterBlance} balance={balance} submitPayment={submitPayment} errors={errors}/> */}
    </>
  );
};

export default DepositPopup;
