import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "./boxdetailspopup.css";
import { useTranslation } from "react-i18next";
import { handleBoxPrice } from "../../utils/useFunc";

const ProductDetailsPopup = ({ handleClose, item, productBoxes, state }) => {
  const { t } = useTranslation();
  const { pid } = item;
  const { brandId, categoryId } = pid || {};
  const settings = {
    dots: false,
    infinite: state,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <>
      <div className="box-popup-sections">
        <div className="item-detail-page">
          <div className="item-contents">
            <div className="title-btn">
              <div className="title">
                <p>{t("boxDetails.boxPopupTitle")}</p>
              </div>
              <div>
                <i
                  class="fa fa-times"
                  aria-hidden="true"
                  onClick={handleClose}
                ></i>
              </div>
            </div>
            <div className="img-content">
              <div className="box-img">
                <img src={pid?.bannerImage} alt="img" />
              </div>
              <div className="prodcut-content">
                <h4 className="name">{pid?.name}</h4>
                <h4 className="price">{pid?.price} DT</h4>
              </div>
            </div>
            <div className="product-desp">
              <div className="brand">
                <h6>{t("boxDetails.boxPopupBrand")} :</h6>{" "}
                <span> {brandId?.name || ""} </span>
              </div>
              <div className="category">
                <h6>{t("boxDetails.boxPopupCat")} :</h6>
                <span>
                  {categoryId &&
                    categoryId.length > 0 &&
                    categoryId.map((el2) => <>{el2?.name || ""} </>)}
                </span>
              </div>

              <p>
                <h6>{t("boxDetails.boxPopupDesc")} :</h6>
                {pid?.description}
              </p>
              {/* <p>
                Limited Availability - This item is sourced by our supplier.
                Ships in.Ships from the EU, taxes and duties are not included
              </p> */}
            </div>
            {/* <div className="outcome"> 
              <h6>Outcome</h6>
              <h4>3101 - 12500</h4>
            </div> */}

            <div className="box-popup-btn">
              {pid?.visibility !== false && (
                <Link
                  className="cmn-btn details-btn style--three btn--sm mt-20 btn-style"
                  to={`/product-details?id=${pid?._id}`}
                >
                  <span>{t("boxDetails.boxPopupBtn")}</span>
                </Link>
              )}
            </div>

            <div className="slide-box">
              <h6>{t("boxDetails.boxPopupContained")}</h6>
              <Slider {...settings}>
                {productBoxes.length > 0 &&
                  productBoxes.map((el) => (
                    <div>
                      <div
                        className="recent-item wow animate__animated animate__slideInLeft"
                        style={{ background: "#083248" }}
                      >
                        <Link
                          to={`/boxdetails?id=${el?._id}`}
                          onClick={handleClose}
                        >
                          <div className="recent-item-content">
                            <p>
                              {handleBoxPrice(el?.boxItems, el?.profitMargin)}{" "}
                              DT
                            </p>
                          </div>
                          <div className="icon-item-thumb">
                            <img src={el.image} alt="box img" />
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetailsPopup;
