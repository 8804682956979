import React from "react";
import "./inventoryPopup.css";
import { useTranslation } from "react-i18next";
// import InventoryImg from "../../assets/images/home/download.png";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetInventory } from "../../reducers/userReducer";

const InventoryPopup = ({ setShow }) => {
  const dispatch = useDispatch();
  const result = useSelector((state) => state.user);
  const { distinctInventProd } = result || {};
  const { t } = useTranslation();
  let total = 0;
  // const totalCartPrice = (data) => {
  //   if(data?.length>0){
  //   const result =  data.reduce((prev,next)=> {
  //     // console.log('next===',next)
  //     return prev + (parseFloat(next?.productQuantity||0) * parseFloat(next?.productId[0]?.price || 0))},
  //     0)
  //   // console.log('result',result)
  //   return result;
  //   }
  // };

  const handleView = () => {
    // console.log('okkk')
    dispatch(resetInventory());
    setShow(false);
  };
  return (
    <>
      <div className="addtocart_page">
        <ul className="show-div shopping_cart">
          {distinctInventProd?.length > 0 &&
            distinctInventProd.map((el) => {
              const prod = el?.product;
              total += Number(el.total);
              return (
                <li>
                  <div className="media">
                    <div className="inventory-img">
                      <img
                        alt=""
                        src={prod.bannerImage}
                        className="mr-3 media"
                      />
                    </div>
                    <div className="media_body">
                      <h4>
                        <span className="pro_name">{prod.name}</span>
                        <span className="quantity">
                          {el.quantity} x {prod.price} DT
                        </span>
                      </h4>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
        <div className="subtotal-section">
          <ul>
            <li>
              <div className="total">
                <h5>{t("cartPopup.totalItems")}:</h5>
                <span className="total_price">{total?.toFixed(2) || 0} DT</span>
              </div>
            </li>
            <li className="view_btn">
              <div className="buttons view-cart" onClick={() => handleView()}>
                <Link to="/profile?tab=inventory" className=" btn-style">
                  <span>{t("cartPopup.myInventory")}</span>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default InventoryPopup;
